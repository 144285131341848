import React from 'react'
import { auth } from '../../firebase'
import useRouter from 'use-react-router'
import gql from 'graphql-tag'
import { useMutation } from 'react-apollo-hooks'
const mut = gql`
  mutation SetLoggedIn($state: Boolean) {
    setLoggedIn(state: $state) @client
  }
`
function useFBAuth() {
  const { history } = useRouter()
  const mutation = useMutation(mut)
  const [state, setState] = React.useState<
    'initial' | 'logged' | 'not_logged' | 'loading'
  >('initial')

  React.useEffect(() => {
    const unsub = auth.onAuthStateChanged(async user => {
      if (!user) {
        ///history.push('/login')
        setState('not_logged')
      } else {
        let idToken = await user.getIdToken()
        localStorage.setItem('idToken', idToken)
        localStorage.setItem('login', 'firebase')
        //debugger
        setState('logged')
        mutation({ variables: { state: true } })
      }
    })

    return function() {
      unsub()
    }
  }, [mutation])
  return state
}

export default useFBAuth
