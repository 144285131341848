import React, { useState, useContext } from 'react'
import {
  Grid,
  Typography,
  Select,
  InputBase,
  MenuItem,
  InputAdornment,
  Collapse,
  Button,
} from '@material-ui/core'
import InputField from '../InputField/InputField'
import { _i } from '../../../../Utils/lang/i18n'
import { useStyles, useStylesSubComponent } from './styles'
import { SearchUsers } from '../../../Search'
import CachedIcon from '@material-ui/icons/Cached'
import { DOWQuestionsContext } from './context'
import SearchUserList from '../../../User/SearchUserList'
import DatePicker from '../../../DatePicker'
import { UserlistContext } from '../../../../views/NewChallenge/components/JudgeSelect/UserlistContext'
import { User } from '../../../../generated/graphql'
interface Props {}
// const periodicidadLista = [
//   'cada hora',
//   'cada 12 horas',
//   'cada 18 horas',
//   'diaria',
//   'semanal',
//   'cada 2 semanas',
//   'mensual',
//   'cada 6 meses',
//   'anual',
//   'puntual',
//   'one-off',
// ]
const DOWQuestions: React.FC<Props> = ({}) => {
  const {
    dowFields: {
      LabTechnician,
      InitialDate,
      FinalDate,
      MonthAndYear,
      ActivityType,
      ProjectId,
      ProjectActivity,
      DedicatedTime,
      TotalWorkedHours,
      DedicateTime,
      ProjectTitle,
      BusinessSegment,
      BusinessPriority,
      TechComplex,
      Status,
      ClosureDate,
      TypeProject,
      LabProjectLeader,
      TSYD,
      CRM,
      NPI,
      DelayReasons,
      GeneralComments,
      Code,
      RegistrationDate,
      EstimatedProjectDuration,
      TotalProjectDuration,
    },
    dispatch: dowDispatch,
  } = useContext(DOWQuestionsContext)
  //fecha máxma check hook
  // const [fechaMaxFlag, setFechaMaxFlag] = useState(false)
  // //setting file wrapper for context
  // const handlePeriodicidad = e => {
  //   e.preventDefault()
  //   dowDispatch({
  //     type: 'set',
  //     newFields: { periodicidad: e.target.value },
  //   })
  // }
  // const { dispatch: userListDispatch, userlist } = useContext(UserlistContext)
  // const handleDelete = (user: User) => {
  //   userListDispatch({ type: 'remove', user })
  // }
  const classes = useStyles()
  return (
    <Grid container>
      <Typography variant="subtitle1" className={classes.text}>
        {_i('Cuestionario')}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          {/* Pregunta 2 */}
          <DOWFormComponent
            value={LabTechnician}
            onChange={e =>
              dowDispatch({
                type: 'set',
                newFields: { LabTechnician: e.target.value },
              })
            }
            label={_i('Lab Technician')}
            placeholder={_i('Lab Technician')}
            type="text"
          />
          <Typography variant="subtitle1" className={classes.text}>
            {_i('Initial date')}
          </Typography>
          <DatePicker
            value={InitialDate}
            onChange={date => {
              console.log(date)
              dowDispatch({
                type: 'set',
                newFields: { InitialDate: date },
              })
            }}
            style={{ width: '100%', maxWidth: 408 }}
          />
          <Typography variant="subtitle1" className={classes.text}>
            {_i('Final date')}
          </Typography>
          <DatePicker
            value={FinalDate}
            onChange={date =>
              dowDispatch({
                type: 'set',
                newFields: { FinalDate: date },
              })
            }
            style={{ width: '100%', maxWidth: 408 }}
          />{' '}
          <DOWFormComponent
            label={_i('Month and year')}
            placeholder={_i('Month and year')}
            type="number"
            value={MonthAndYear}
            onChange={e => {
              dowDispatch({
                type: 'set',
                newFields: { MonthAndYear: e.target.value },
              })
            }}
          />
          <DOWFormComponent
            value={ActivityType}
            onChange={e =>
              dowDispatch({
                type: 'set',
                newFields: { ActivityType: e.target.value },
              })
            }
            label={_i('Activity type')}
            placeholder={_i('Activity type')}
            type="text"
          />
          <div>
            <DOWFormComponent
              value={ProjectId}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { ProjectId: e.target.value },
                })
              }
              label={_i('Project id')}
              placeholder={_i('Project id')}
              type="number"
            />
            <DOWFormComponent
              value={ProjectActivity}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { ProjectActivity: e.target.value },
                })
              }
              label={_i('Project activity')}
              placeholder={_i('Project activity')}
              type="text"
            />
            <DOWFormComponent
              value={DedicatedTime}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { DedicatedTime: e.target.value },
                })
              }
              label={_i('Dedicated time(h)')}
              placeholder={_i('hours')}
              type="number"
              endAdornment={
                <InputAdornment position="end">
                  <b>h.</b>
                </InputAdornment>
              }
            />
            <DOWFormComponent
              value={TotalWorkedHours}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: {
                    TotalWorkedHours: e.target.value,
                  },
                })
              }
              label={_i('Total worked hours (h)')}
              placeholder={_i('hours')}
              type="number"
              endAdornment={
                <InputAdornment position="end">
                  <b>h.</b>
                </InputAdornment>
              }
            />
            <DOWFormComponent
              value={DedicateTime}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { DedicateTime: e.target.value },
                })
              }
              label={_i('Dedicate time (%)')}
              placeholder="%"
              type="number"
              endAdornment={
                <InputAdornment position="end">
                  <b>%</b>
                </InputAdornment>
              }
            />
            <DOWFormComponent
              value={ProjectTitle}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { ProjectTitle: e.target.value },
                })
              }
              label={_i('Project Title')}
              placeholder={_i('Project Title')}
              type="text"
            />
            <DOWFormComponent
              value={BusinessSegment}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { BusinessSegment: e.target.value },
                })
              }
              label={_i('Business segment')}
              placeholder={_i('Business segment')}
              type="text"
            />
            <DOWFormComponent
              value={BusinessPriority}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { BusinessPriority: e.target.value },
                })
              }
              label={_i('Business priority ')}
              placeholder={_i('Business priority ')}
              type="text"
            />
            <DOWFormComponent
              value={TechComplex}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { TechComplex: e.target.value },
                })
              }
              label={_i('Tech complex')}
              placeholder={_i('Tech complex')}
              type="text"
            />
            <DOWFormComponent
              value={Status}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { Status: e.target.value },
                })
              }
              label={_i('Status')}
              placeholder={_i('Status')}
              type="text"
            />
            <Typography variant="subtitle1" className={classes.text}>
              {_i('Closure date')}
            </Typography>
            <DatePicker
              value={ClosureDate}
              onChange={date => {
                console.log(date)
                dowDispatch({
                  type: 'set',
                  newFields: { ClosureDate: date },
                })
              }}
              style={{ width: '100%', maxWidth: 408 }}
            />
            <DOWFormComponent
              value={TypeProject}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { TypeProject: e.target.value },
                })
              }
              label={_i('Type of project')}
              placeholder={_i('Type of project')}
              type="text"
            />
            <DOWFormComponent
              value={LabProjectLeader}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { LabProjectLeader: e.target.value },
                })
              }
              label={_i('Lab project leader')}
              placeholder={_i('Lab project leader')}
              type="text"
            />
            <DOWFormComponent
              value={TSYD}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { TSYD: e.target.value },
                })
              }
              label={_i('TS&D')}
              placeholder={_i('TS&D')}
              type="text"
            />
            <DOWFormComponent
              value={CRM}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { CRM: e.target.value },
                })
              }
              label={_i('CRM Opportunity ID')}
              placeholder={_i('CRM Opportunity ID')}
              type="number"
            />
            <DOWFormComponent
              value={NPI}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { NPI: e.target.value },
                })
              }
              label={_i('NPI Project ID')}
              placeholder={_i('NPI Project ID')}
              type="number"
            />{' '}
            <DOWFormComponent
              value={DelayReasons}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { DelayReasons: e.target.value },
                })
              }
              label={_i('Delay reasons')}
              placeholder={_i('Delay reasons')}
              type="text"
            />
            <DOWFormComponent
              value={GeneralComments}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { GeneralComments: e.target.value },
                })
              }
              label={_i('General comments ')}
              placeholder={_i('General comments ')}
              type="text"
            />
            <DOWFormComponent
              value={Code}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { Code: e.target.value },
                })
              }
              label={_i('Code')}
              placeholder={_i('Code')}
              type="number"
            />
            <Typography variant="subtitle1" className={classes.text}>
              {_i('Registration date')}
            </Typography>
            <DatePicker
              value={RegistrationDate}
              onChange={date => {
                dowDispatch({
                  type: 'set',
                  newFields: { RegistrationDate: date },
                })
              }}
              style={{ width: '100%', maxWidth: 408 }}
            />
            <DOWFormComponent
              value={EstimatedProjectDuration}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { EstimatedProjectDuration: e.target.value },
                })
              }
              label={_i('Estimated project duration')}
              placeholder={_i('Estimated project duration (days)')}
              type="number"
              endAdornment={
                <InputAdornment position="end">
                  <b>days</b>
                </InputAdornment>
              }
            />
            <DOWFormComponent
              value={TotalProjectDuration}
              onChange={e =>
                dowDispatch({
                  type: 'set',
                  newFields: { TotalProjectDuration: e.target.value },
                })
              }
              label={_i('Total project duration')}
              placeholder={_i('Total project duration (days)')}
              type="number"
              endAdornment={
                <InputAdornment position="end">
                  <b>days</b>
                </InputAdornment>
              }
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * Component to change input or switches depending on the incoming variable
 * the type string changes between text and switch
 */
interface DOWFormComponentProps {
  value: any
  onChange: (e: any) => any
  label: string
  type: 'number' | 'switch' | 'text'
  placeholder: string
  helperText: string
}
const DOWFormComponent: React.FC<DOWFormComponentProps & any> = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  ...props
}) => {
  const classes = useStylesSubComponent()
  if (type === 'number') {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <InputField
          value={value}
          onChange={e => {
            // Only Answer with numbers
            if (/^\d*$/.test(e.target.value)) {
              onChange(e)
            }
          }}
          type="text"
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            maxWidth: 408,
          }}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else if (type === 'text') {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <InputField
          value={value}
          onChange={e => {
            onChange(e)
          }}
          //type="text"
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            maxWidth: 408,
          }}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <div style={{ paddingLeft: 10 }}>
          <Grid container alignItems="center">
            <Button
              onClick={onChange}
              variant={!value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.firstOptButton}>
              {placeholder[1]}
            </Button>
            <Button
              onClick={onChange}
              variant={value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.secondOptButton}>
              {placeholder[0]}
            </Button>
          </Grid>
        </div>
      </>
    )
  }
}

export default DOWQuestions
