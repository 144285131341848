import { makeStyles } from '@material-ui/core/styles'
const drawerWidth = '100%'
export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },

  fullHeight: {
    height: '100%',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px ',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  icon: {
    padding: '8px 8px',
    margin: 0,
  },
}))
