import React, { useState } from 'react'
import { Divider, Collapse, IconButton, Box } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded'
import ExpandLessRounded from '@material-ui/icons/ExpandLessRounded'
import { ModalIdea } from './ModelIdea'
import { _i } from '../../../../Utils/lang/i18n'
interface IdeasProps {
  initState: any[]
}
export const IdeaSimilar: React.FC<IdeasProps> = ({ initState }) => {
  const [moreIdeas, setMoreIdeas] = useState(false)
  const [view, setView] = useState(false)
  const [idIdea, setId] = useState('')

  return (
    <>
      <p
        style={{
          fontFamily: 'Roboto',
          fontSize: '12px',
          color: '#2972e8',
          marginLeft: '10px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setMoreIdeas(!moreIdeas)
        }}>
        {_i('Ideas Similares')}
        {moreIdeas ? (
          <ExpandLessRounded
            style={{
              fontSize: '20px',
              verticalAlign: 'middle',
            }}
          />
        ) : (
          <ExpandMoreRounded
            style={{
              fontSize: '20px',
              verticalAlign: 'middle',
            }}
          />
        )}
      </p>
      <Collapse in={moreIdeas}>
        <div
          style={{
            padding: '12px',
            maxHeight: '200px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}>
          {moreIdeas
            ? initState &&
              initState.map((item, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '2px',
                      alignItems: 'center',
                      width: '400px',
                      height: '58px',
                    }}>
                    <Box fontFamily="Roboto" fontSize="12px" m={1}>
                      {item.name.slice(0)}
                      {item.name.length >= 50 ? '...' : null}
                    </Box>
                    <IconButton
                      onClick={() => {
                        setView(true)
                        setId(item.id)
                      }}>
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                  <Divider />
                </React.Fragment>
              ))
            : null}
        </div>
      </Collapse>
      {view && (
        <ModalIdea
          open={view}
          setOpen={setView}
          fullScreen={false}
          ideaId={idIdea}
        />
      )}
    </>
  )
}
