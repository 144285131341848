//Cover List Context Declaration
import React, { useReducer } from 'react'
import { SofttekAutoFields } from '../../../../generated/graphql'

const initState = {
  softtekAutoFields: {
    market: '',
    industry: '',
    account: '',
    service: '',
    platform: '',
    replicable: false,
    repositoryCode: '',
    responsible: '',
    licenseRequired: false,
    propietary: '',
    automationType: '',
    complexity: '',
  } as SofttekAutoFields,
  dispatch: null,
}

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        // merge both objects using rest operator
        softtekAutoFields: {
          ...state.softtekAutoFields,
          ...action.newFields,
        },
      }
    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const SofttekAutoQuestionsContext = React.createContext(initState)
//Provider Init
function SofttekAutoQuestionsProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <SofttekAutoQuestionsContext.Provider
      value={{
        softtekAutoFields: state.softtekAutoFields,
        dispatch,
      }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </SofttekAutoQuestionsContext.Provider>
  )
}
//Export conext and provider
export { SofttekAutoQuestionsContext, SofttekAutoQuestionsProvider }
