import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
// Custom
import { InnoButton } from '../../../../components/Buttons'
import Logo from '../Logo'
import Notifications from '../Notifications'
import UserIcon from '../UserIcon/UserIcon'
import NewIdeaDialog from '../../../../components/NewIdea/NewIdeaDialog'
import {
  ActiveDialogContext,
  ActiveDialogProvider,
} from '../../../../components/NewIdea/ActiveDialogContext'
import { SearchTopBar } from '../../../../components/Search'
import { IconButton, Link } from '@material-ui/core'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import { Link as BrowserLink } from 'react-router-dom'
import { useStyles } from './styles'
interface NavBarProps {}

const DesktopTopBar: React.FC<NavBarProps> = () => {
  const classes = useStyles()
  return (
    <ActiveDialogProvider>
      <Grid style={{ height: '100%' }} container alignContent="center">
        <div
          style={{
            maxWidth: '1450px',
            display: 'flex',
            margin: '0 auto',
            width: '100%',
            justifyContent: 'space-between',
          }}>
          <Grid className={classes.fullHeight} item xs={8}>
            <LogoNSearch classes={classes} />
          </Grid>
          <Grid item xs={3}>
            <Grid
              container
              justify="flex-end"
              direction="row"
              alignContent="center"
              style={{ height: '100%' }}>
              <Grid item>
                <Icons classes={classes} />
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* {window.location.pathname==='/' &&
        <ScrollToTop 
        showUnder={350} 
        duration={1000}
        style={{
          top:'80px',
          right:'50%',
        }}
        >
        <Button
      variant="contained"
      size="medium"
      style={{
        fontFamily: 'InnoArial',
        boxShadow: '0px 0px 7px -3px rgba(112,112,112,0.88)',
      }}>
      Regresar Arriba
    </Button>
        </ScrollToTop>
        } */}
      </Grid>
    </ActiveDialogProvider>
  )
}

const LogoNSearch = ({ classes }) => {
  const { activeDialog, dispatch: dialogDispatch } = useContext(
    ActiveDialogContext
  )
  return (
    <Grid
      container
      justify="space-between"
      alignContent="center"
      className={classes.fullHeight}>
      <Grid item xs={4}>
        <Grid
          className={classes.fullHeight}
          container
          alignContent="center"
          alignItems="center"
          justify="flex-start">
          <Logo />
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid
          container
          justify="flex-end"
          direction="row"
          className={classes.fullHeight}
          alignContent="center"
          alignItems="center"
          style={{ padding: '0 8px' }}>
          <div className={classes.searchBar}>
            <SearchTopBar />
          </div>
          <InnoButton
            onClick={() => {
              dialogDispatch({ type: 'update', status: true })
            }}
          />
          {/* New idea dialog triggered by local button */}
          <NewIdeaDialog />
        </Grid>
      </Grid>
    </Grid>
  )
}

const Icons = ({ classes }) => (
  <Grid
    container
    justify="flex-end"
    alignContent="center"
    className={classes.fullHeight}>
    {/* {process.env.REACT_APP_SERVICE === 'heb' && (
      <img src={MiTiendaImg} alt="HEB-tienda" className={classes.tienda} />
    )} */}
{process.env.REACT_APP_SERVICE != 'abanca' &&
    <Link variant="inherit" component={BrowserLink} to={`/evolution`}>
      <IconButton color="inherit" className={classes.evolution}>
        <CallSplitIcon />
      </IconButton>
    </Link>
}
    <Notifications />
    <ErrorBoundary>
      <UserIcon />
    </ErrorBoundary>
  </Grid>
)

export default DesktopTopBar

// Create error boundary to prevent userIcon Material UI Transition bug from propagating

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    // @ts-ignore
    if (this.state.errorInfo) {
      // Error path, render a new user icon component, its like a clone from star ward
      return <UserIcon />
    }
    // Normally, just render children
    return this.props.children
  }
}
