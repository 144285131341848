import React, { useState, useContext } from 'react'
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  InputBase,
  MenuItem,
  InputAdornment,
  Collapse,
  Divider,
  Button,
} from '@material-ui/core'
import InputField from '../InputField/InputField'
import { _i } from '../../../../Utils/lang/i18n'
import { useStyles, useStylesSubComponent } from './styles'
import { SearchUsers } from '../../../Search'
import CachedIcon from '@material-ui/icons/Cached'
import { ABANCAQuestionsContext } from './context'
import LocalCafeIcon from '@material-ui/icons/LocalCafe'
import SearchUserList from '../../../User/SearchUserList'
import DatePicker from '../../../DatePicker'
import { UserlistContext } from '../../../../views/NewChallenge/components/JudgeSelect/UserlistContext'
import { User } from '../../../../generated/graphql'
interface Props {}
const periodicidadLista = [
  'cada hora',
  'cada 12 horas',
  'cada 18 horas',
  'diaria',
  'semanal',
  'cada 2 semanas',
  'mensual',
  'cada 6 meses',
  'anual',
  'puntual',
  'one-off',
]
const ABANCAQuestions: React.FC<Props> = ({}) => {
  const {
    abancaFields: {
      periodicidad,
      tiempoDedicado,
      volumenEstimado,
      tiempoTarea,
      tiempoTareaVolumen,
      riesgoOperacion,
      ahorroCostes,
      fechaMax,
      peticionCER,
    },
    dispatch: abancaDispatch,
  } = useContext(ABANCAQuestionsContext)
  //fecha máxma check hook
  const [fechaMaxFlag, setFechaMaxFlag] = useState(false)
  //setting file wrapper for context
  const handlePeriodicidad = e => {
    e.preventDefault()
    abancaDispatch({
      type: 'set',
      newFields: { periodicidad: e.target.value },
    })
  }
  const { dispatch: userListDispatch, userlist } = useContext(UserlistContext)
  const handleDelete = (user: User) => {
    userListDispatch({ type: 'remove', user })
  }
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12} sm={6} style={{ padding: '3px', paddingRight: 9 }}>
        <Typography variant="subtitle1" className={classes.text}>
          {/* Periodicidad Pregunta 1 */}
          {_i('La tarea es realizada :')}
        </Typography>
        <Select
          value={periodicidad}
          onChange={handlePeriodicidad}
          placeholder={_i('Selecciona la Periodicidad')}
          input={
            <InputBase
              placeholder={_i('Selecciona la Periodicidad')}
              className={classes.selectInput}
            />
          }
          style={{ marginBottom: 10 }}
          startAdornment={
            <InputAdornment position="start">
              <CachedIcon className={classes.icon} />
            </InputAdornment>
          }>
          {periodicidadLista.map((option, key) => (
            <MenuItem key={key} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12} sm={6} style={{ padding: '3px', paddingLeft: 8 }}>
        {/* Colaboradores o Coautores */}
        <Typography variant="subtitle1" className={classes.text}>
          {_i('Colaboradores')}
        </Typography>
        <SearchUsers />
        <SearchUserList
          handleDelete={handleDelete}
          userList={userlist}
          placeholder={_i(
            'Introduce usuarios buscando los nombres en la barra de búsqueda'
          )}
        />
      </Grid>
      <Typography variant="subtitle1" className={classes.text}>
        {_i('Cuestionario')}
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          {/* Pregunta 2 */}
          <ABANCAFormComponent
            value={tiempoDedicado}
            onChange={e =>
              abancaDispatch({
                type: 'set',
                newFields: { tiempoDedicado: !tiempoDedicado },
              })
            }
            label={_i(
              '¿El tiempo que dedicas a la tarea es variable dependiendo del volumen archivos/correos/expedientes/documents, etc que procesas en cada ejecución ?'
            )}
            placeholder={['Sí', 'No']}
            type="switch"
          />

          {/* Pregunta 4 */}
          <Collapse in={!tiempoDedicado}>
            <div>
              <ABANCAFormComponent
                value={tiempoTarea}
                onChange={e => {
                  if (e.target.value.charAt(0) != '0') {
                    abancaDispatch({
                      type: 'set',
                      newFields: { tiempoTarea: e.target.value },
                    })
                  } else {
                    abancaDispatch({
                      type: 'set',
                      newFields: { tiempoTarea: 1 },
                    })
                  }
                }}
                label={_i('¿Cuánto tiempo te lleva realizar la tarea?')}
                placeholder="Minutos"
                type="number"
                endAdornment={
                  <InputAdornment position="end">
                    <b>min.</b>
                  </InputAdornment>
                }
              />
            </div>
          </Collapse>

          {/* Pregunta 3 y 5 */}
          <Collapse in={tiempoDedicado}>
            <div>
              <ABANCAFormComponent
                value={volumenEstimado}
                onChange={e => {
                  if (e.target.value != '0') {
                    abancaDispatch({
                      type: 'set',
                      newFields: { volumenEstimado: e.target.value },
                    })
                  } else {
                    abancaDispatch({
                      type: 'set',
                      newFields: { volumenEstimado: 1 },
                    })
                  }
                }}
                label={_i(
                  '¿Cuál es el volumen estimado de archivos/expedientes/documentos/etc cada vez que realizas la tarea?'
                )}
                placeholder="Unidades"
                type="number"
              />
              <ABANCAFormComponent
                value={tiempoTareaVolumen}
                onChange={e => {
                  if (e.target.value.charAt(0) != '0') {
                    abancaDispatch({
                      type: 'set',
                      newFields: { tiempoTareaVolumen: e.target.value },
                    })
                  } else {
                    abancaDispatch({
                      type: 'set',
                      newFields: { tiempoTareaVolumen: 1 },
                    })
                  }
                }}
                label={_i(
                  '¿Cuanto tiempo te lleva realizar la tarea por cada archivo/correo/expediente/documento/etc?'
                )}
                placeholder="Minutos"
                type="number"
                endAdornment={
                  <InputAdornment position="end">
                    <b>min.</b>
                  </InputAdornment>
                }
              />
            </div>
          </Collapse>

          {/* Pregunta 6 */}
          <ABANCAFormComponent
            value={riesgoOperacion}
            onChange={e =>
              abancaDispatch({
                type: 'set',
                newFields: { riesgoOperacion: !riesgoOperacion },
              })
            }
            label={_i(
              '¿Un error en la ejecución de la tarea o en la no ejecución de la misma puede llevar a un posible Riesgo Operacional?'
            )}
            placeholder={['Sí', 'No']}
            type="switch"
          />

          {/* Pregunta 7 */}
          <ABANCAFormComponent
            value={ahorroCostes}
            onChange={e =>
              abancaDispatch({
                type: 'set',
                newFields: { ahorroCostes: !ahorroCostes },
              })
            }
            label={_i(
              '¿La tarea robotizada la realizas tú o está soportada en una empresa externa?'
            )}
            placeholder={['Empresa Externa', 'Ejecución Interna']}
            type="switch"
          />

          {/*  Fecha Máxima */}
          <ABANCAFormComponent
            value={fechaMaxFlag}
            onChange={e => setFechaMaxFlag(f => !f)}
            label={_i('Fecha Máxima')}
            placeholder={['Sí', 'No']}
            type="switch"
          />
          <Collapse in={fechaMaxFlag}>
            <DatePicker
              value={fechaMax}
              onChange={date =>
                abancaDispatch({
                  type: 'set',
                  newFields: { fechaMax: date },
                })
              }
              style={{ width: '100%', maxWidth: 408 }}
            />
          </Collapse>

          {/* Petición CER */}
          <ABANCAFormComponent
            value={peticionCER}
            onChange={e =>
              abancaDispatch({
                type: 'set',
                newFields: { peticionCER: !peticionCER },
              })
            }
            label={_i('Petición al Equipo de Robotización')}
            placeholder={['Sí', 'No']}
            type="switch"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * Component to change input or switches depending on the incoming variable
 * the type string changes between text and switch
 */
interface ABANCAFormComponentProps {
  value: any
  onChange: (e: any) => any
  label: string
  type: 'number' | 'switch'
  placeholder: string
  helperText: string
}
const ABANCAFormComponent: React.FC<ABANCAFormComponentProps & any> = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  ...props
}) => {
  const classes = useStylesSubComponent()
  if (type === 'number') {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <InputField
          value={value}
          onChange={e => {
            // Only Answer with numbers
            if (/^\d*$/.test(e.target.value)) {
              onChange(e)
            }
          }}
          type="text"
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            maxWidth: 408,
          }}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <div style={{ paddingLeft: 10 }}>
          <Grid container alignItems="center">
            <Button
              onClick={onChange}
              variant={!value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.firstOptButton}>
              {placeholder[1]}
            </Button>
            <Button
              onClick={onChange}
              variant={value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.secondOptButton}>
              {placeholder[0]}
            </Button>
          </Grid>
        </div>
      </>
    )
  }
}

export default ABANCAQuestions
