import React, { useContext } from 'react'

// Material ui
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { Fab } from '@material-ui/core'
import { ActiveDialogContext } from '../../../components/NewIdea/ActiveDialogContext'
const useStyles = makeStyles(() => ({
  fab: {},
}))

const FabButton: React.FC<any> = () => {
  // @ts-ignore
  const classes = useStyles()
  const { dispatch: dialogDispatch } = useContext(ActiveDialogContext)
  return (
    <Fab
      className={classes.fab}
      color={'primary'}
      onClick={() => {
        dialogDispatch({ type: 'update', status: true })
      }}>
      <AddIcon />
    </Fab>
  )
}

export default FabButton
