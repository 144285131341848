//Cover List Context Declaration
import React, { useReducer } from 'react'

const initState = { coverImg: null, dispatch: null }

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    //if reducer action addition then sum up the counter and add
    case 'add':
      //check if init state is already activated..
      //in order to avoid submitting a file two times
      // eslint-disable-next-line
      let file_temp: any
      if (action.init) {
        file_temp = {
          file: action.file,
          preview: action.file.url,
          init: true,
        }
      } else {
        file_temp = {
          file: action.file,
          preview: URL.createObjectURL(action.file.file),
          init: false,
        }
      }

      return { ...state, coverImg: file_temp }

    // if action is removal, then find the id and remove it
    case 'remove':
      return {
        coverImg: null,
      }

    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const CoverContext = React.createContext(initState)
//Provider Init
function CoverProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <CoverContext.Provider value={{ coverImg: state.coverImg, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </CoverContext.Provider>
  )
}
//Export conext and provider
export { CoverContext, CoverProvider }
