import React, { useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  Grid,
  Box,
  Fab,
  DialogContent,
} from '@material-ui/core'
import { useStyles } from './styles'
import NewIdea from './index'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import CloseRounded from '@material-ui/icons/CloseRounded'
import { ActiveDialogContext } from './ActiveDialogContext'
import { _i } from '../../Utils/lang/i18n'

interface Props {
  fullScreen: boolean
}
// New idea dialog component
const NewIdeaDialog: React.FC<Props> = props => {
  const { activeDialog, dispatch: dialogDispatch } = useContext(
    ActiveDialogContext
  )
  const { fullScreen } = props

  // @ts-ignore
  const classes = useStyles()
  return (
    <Dialog
      open={activeDialog}
      onClose={() => {
        dialogDispatch({ type: 'update', status: false })
      }}
      fullScreen={fullScreen}
      fullWidth={false}
      maxWidth={false}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title">
      <DialogTitle id="scroll-dialog-title">
        <Grid container>
          <Grid item xs={6}>
            <Box fontFamily="InnoArial" fontSize={16}>
              {process.env.REACT_APP_SERVICE === 'softtekautomation'
                ? _i('Nueva Automatización')
                : _i('Nueva Idea')}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Fab
              color="default"
              className={classes.fab}
              onClick={() => {
                dialogDispatch({ type: 'update', status: false })
              }}>
              <CloseRounded className={classes.icon} />
            </Fab>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.mainContainer}>
        <NewIdea />
      </DialogContent>
    </Dialog>
  )
}
// withMobile Dialog to activate mobile integration
export default withMobileDialog({ breakpoint: 'sm' })(NewIdeaDialog)
