import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Typography, ListItemIcon } from '@material-ui/core'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useMeQuery } from '../../../../generated/graphql'
import useRouter from 'use-react-router'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PersonIcon from '@material-ui/icons/Person'
import { concatenateSASOrNot } from '../../../../Utils'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import fakeUserIMg from '../../../../static/usericon.png'
import { _i } from '../../../../Utils/lang/i18n'
import { useStyles } from './styles'
interface Props {}
//User Icon retrieves current user information to displaya depicted photo as an icon

const UserIcon: React.FC<Props> = React.memo(() => {
  const classes = useStyles()
  const { data, loading } = useMeQuery()
  const [cover, setCover] = useState('')
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { history } = useRouter()
  useMemo(() => {
    if (data.me && !loading) {
      const coverTemp = concatenateSASOrNot(
        data.me.sas.token,
        data.me.img,
        'small'
      )
      if (coverTemp) {
        setCover(coverTemp)
      }
    }
  }, [data, loading])

  useEffect(() => {
    return () => {
      setAnchorEl(null)
    }
  }, [])

  if (loading) {
    return <React.Fragment />
  }

  function handleToggle(e) {
    setAnchorEl(e.currentTarget)
  }

  function handleClose(event: React.MouseEvent<EventTarget>) {
    setAnchorEl(null)
  }

  function handleClick(
    e: React.MouseEvent<EventTarget>,
    where: 'logout' | 'account' | 'settings' | 'admin' | 'dashboard'
  ) {
    if (where === 'logout') {
      history.push('/logout')
    } else if (where === 'account') {
      history.push(`/user/${data.me.id}`)
      handleClose(e)
    } else if (where === 'settings') {
      history.push('/settings')
      handleClose(e)
    } else if (where === 'admin') {
      history.push('/adminsettings')
      handleClose(e)
    } else if (where === 'dashboard') {
      history.push('/dashboard')
      handleClose(e)
    }
  }

  return (
    <div>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        buttonRef={anchorRef}
        onClick={handleToggle}
        color="inherit"
        style={{ padding: 0, marginRight: '16px', height: '44px' }}>
        <img
          src={cover || fakeUserIMg}
          className={classes.userIcon}
          onError={() => {
            if (data.me.img) {
              // Same trick, if it is not rendered, change to preview mode
              setCover(
                concatenateSASOrNot(data.me.sas.token, data.me.img, 'small')
              )
            } else {
              // set fake img, we dont want to change our styleguide for a new icon
              setCover(fakeUserIMg)
            }
          }}
        />
      </IconButton>
      <Menu
        style={{ outline: 0 }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <MenuItem
          onClick={e => handleClick(e, 'account')}
          className={classes.menuItem}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          {_i('Perfil')}
        </MenuItem>
        {data.me.isAdmin && (
          <MenuItem
            onClick={e => handleClick(e, 'admin')}
            className={classes.menuItem}>
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}
        {process.env.REACT_APP_SERVICE === 'caixabank' && (
          <a
            href="https://vimeo.com/user53847348/videomanualcaixamagic?share=copy"
            style={{ color: '#000000', textDecoration: 'none' }}>
            <MenuItem className={classes.menuItem}>
              <ListItemIcon>
                <OndemandVideoIcon />
              </ListItemIcon>
              Videomanual
            </MenuItem>
          </a>
        )}
        <MenuItem
          onClick={e => handleClick(e, 'logout')}
          className={classes.menuItem}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          {_i('Cerrar Sesión')}
        </MenuItem>
        {process.env.REACT_APP_SERVICE === 'heb' && (
          <MenuItem className={classes.menuItem}>
            <Typography variant="body1" style={{ marginTop: '1em' }}>
              <a
                href="mailto:inspiraheb@hebmex.com"
                style={{ color: '#757575', textDecoration: 'none' }}>
                inspiraheb@hebmex.com
              </a>
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
})

export default UserIcon
