import ideaPlaceholder from '../static/placeholders/idea_placeholder.png'
import ideaPlaceholderAbanca from '../static/placeholders/robot_placeholder.jpg'
import ideaPlaceholderHEB from '../static/placeholders/inspira_placeholderver2.png'
import ideaPlaceholderCaixaBank from '../static/placeholders/caixabank_placeholder.png'
import { getInstanceLayout } from './getInstanceLayout'
// obtain placeholder depending on the instance
export const getPlaceholder = (): string => {
  return getInstanceLayout({
    heb: ideaPlaceholderHEB,
    softtek: ideaPlaceholder,
    sandbox: ideaPlaceholder,
    abanca: ideaPlaceholderAbanca,
    caixabank: ideaPlaceholderCaixaBank,
  })
}
