import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  typowarning: {
    fontStyle: 'italic',
    color: '#adadad',
    textAlign: 'center' as 'center'
  },
  linkOff: {
    display: 'block',
    margin: '0 auto',
    fontSize: '5rem',
    color: '#adadad',
  },
  cover: {
    height: '50px',
  },
  iconChallenge: {
    fontSize: '50px',
  },
  challengeBox: {
    display: 'flex',
    jsutifyContent: 'right',
    alignItems: 'center',
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
  coverInfo: {
    height: '50px',
    width: '50px',
    minWidth: '50px',
    overflow: 'hidden',
    borderRadius: '12px',
  },
  challengeInfo: {
    marginLeft: '20px',
  },
  loadingPadding: {
    padding: '24px',
  },
  input: {
    backgroundColor: '#ECEDF1',
    border: 'none',
    borderRadius: '12px',
    padding: '5px 7px',
    fontSize: '14px',
    color: '#727272',
    width: '100%',
    marginLeft: '10px',
  },
  progress: {
    padding: '30px',
    display: 'block',
    margin: '0 auto'
  },
  menuItem: {
    fontSize: '16px',
    padding: '10px 16px'
  }
}))
