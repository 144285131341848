import React from 'react'
import { CustomBadge } from '../CustomBadge/CustomBadge'
import FilePreviewIcon from '../../../FilePreviewIcon'

interface FilePreviewProps {
  fileName: string
  i: number
}
export const FilePreview: React.FC<FilePreviewProps> = ({ fileName, i }) => {
  const filetype_add = /\.(addfile)+$/i.exec(fileName)

  // Only if it's the first icon (add new file)
  if (filetype_add != null) {
    return <FilePreviewIcon fileName={fileName} />
  }
  return (
    <CustomBadge i={i} method={'DropBox'}>
      <FilePreviewIcon fileName={fileName} />
    </CustomBadge>
  )
}
