import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme: any) => ({
  title: {},
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  subtitleCaixaBank: {
    marginBottom: theme.spacing(1),
    color: '#AFA9A0',
  },
}))
