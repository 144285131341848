import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import SortIcon from '@material-ui/icons/Sort'
import { _i } from '../../../Utils/lang/i18n'
import { styles } from './styles'
import theme from '../../../theme'
import { mergeClassNames } from '../../../Utils/textUtils'

interface Props {
  onClick: any
  classes: any
}

const SortButton: React.FC<Props> = ({ classes, onClick }) => {
  return (
    <Button
      onClick={onClick}
      color="primary"
      variant="contained"
      size="medium"
      style={{
        fontFamily: 'InnoArial',
        backgroundColor: theme.palette.primary.main,
      }}
      className={classes.leftIcon}>
      <SortIcon
        className={mergeClassNames(classes.leftIcon, classes.iconSmall)}
      />
      {_i('Ordenar')}
    </Button>
  )
}

export default withStyles(styles)(SortButton)
