import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import React from 'react'
import { User } from '../../generated/graphql'
import UserPlaceholder from '../UserPlaceholder'
import DeleteIcon from '@material-ui/icons/Delete'
import { _i } from '../../Utils/lang/i18n'
export interface SearchUserListProps {
  userList: User[]
  placeholder?: string
  handleDelete?: (user: User) => any
}

const SearchUserList: React.FC<SearchUserListProps> = ({
  userList,
  placeholder,
  handleDelete,
}) => {
  if (userList.length === 0 && placeholder) {
    return (
      <Typography
        variant="body1"
        style={{
          width: '100%',
          textAlign: 'center',
          marginTop: '20px',
          color: '#727272',
          marginBottom: '16px',
        }}>
        {placeholder}
      </Typography>
    )
  }
  return (
    <List style={{ width: '100%' }}>
      {userList.map((user: User, i: number) => (
        <ListItem key={i}>
          <ListItemAvatar>
            <UserPlaceholder username={user.name} />
          </ListItemAvatar>
          <ListItemText primary={user.name} style={{ fontSize: '14px' }} />
          {handleDelete && (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Delete"
                onClick={() => handleDelete(user)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default SearchUserList
