import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Formik } from 'formik'
import {
  CollaboratorInput,
  NewIdeaComponent,
  Idea,
} from '../../generated/graphql'
import moment from 'moment'
import Congratulations from '../MessageSnackbar/Newidea'
import InputField from './components/InputField/InputField'
import TagInput from './components/TagInput/TagInput'
import { DropBox } from './components/DropBox/DropBox'
import { FileContext, FileProvider } from './components/DropBox/DropBoxContext'
import createIdeaId from '../../Utils/createIdeaId'
import CKEditorComponent from './components/CKEditor/CKEditor'
import { CoverDrop } from './components/CoverDrop/CoverDrop'
import SwipeableViews from 'react-swipeable-views'
import {
  CoverContext,
  CoverProvider,
} from './components/CoverDrop/CoverDropContext'
import {
  DescriptionContext,
  DescriptionProvider,
} from './components/CKEditor/CKEditorContext'
import { uploadFile } from '../../Utils/uploadFile'
import { ShortDescriptionField } from './components/ShortDescriptionField/ShortDescriptionField'
import {
  ShortDescriptionContext,
  ShortDescriptionProvider,
} from './components/ShortDescriptionField/ShortDescriptionContext'
import { TagContext, TagProvider } from './components/TagInput/TagInputContext'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import Loading from '../Loading'
import {
  ActiveDialogContext,
  ActiveDialogProvider,
} from './ActiveDialogContext'
import SelectField from './components/SelectField'
import { getInstanceLayout } from '../../Utils/getInstanceLayout'
import { _i } from '../../Utils/lang/i18n'
import { useStyles } from './styles'
import theme from '../../theme'
import ABANCAQuestions from './components/ABANCAQuestions'
import VectorQuestions from './components/VectorQuestions'
import Info from './components/VectorQuestions/vectorAnswer'
import DOWQuestions from './components/DOWQuestions'
import SofttekAutoQuestions from './components/SofttekAutoQuestions'
import {
  UserlistProvider,
  UserlistContext,
} from '../../views/NewChallenge/components/JudgeSelect/UserlistContext'
import {
  ABANCAQuestionsProvider,
  ABANCAQuestionsContext,
} from './components/ABANCAQuestions/context'
import {
  VectorQuestionsProvider,
  VectorQuestionsContext,
} from './components/VectorQuestions/context'
import {
  DOWQuestionsProvider,
  DOWQuestionsContext,
} from './components/DOWQuestions/context'
import {
  SofttekAutoQuestionsContext,
  SofttekAutoQuestionsProvider,
} from './components/SofttekAutoQuestions/context'
import LinkIdea from '../../views/Idea/components/LinkIdea'
import { IdeaSimilar } from './components/IdeaSimilar'
const SideGrid = styled.div`
  position: relative;
  flex: 50%;
  margin-right: 20px;
`
function validateName(value) {
  let error
  if (!value.trim()) {
    error = 'Add the name of your idea'
  }
  if (value.length > 100) {
    error = 'Idea Name should be less than 100 characters'
  }
  return error
}

//new idea component
const NewIdea = () => {
  //setting file wrapper for context
  return (
    <ActiveDialogProvider>
      <ABANCAQuestionsProvider>
        <DOWQuestionsProvider>
          <VectorQuestionsProvider>
            <SofttekAutoQuestionsProvider>
              <UserlistProvider>
                <TagProvider>
                  <DescriptionProvider>
                    <FileProvider>
                      <ShortDescriptionProvider>
                        <CoverProvider>
                          <IdeaForm />
                        </CoverProvider>
                      </ShortDescriptionProvider>
                    </FileProvider>
                  </DescriptionProvider>
                </TagProvider>
              </UserlistProvider>
            </SofttekAutoQuestionsProvider>
          </VectorQuestionsProvider>
        </DOWQuestionsProvider>
      </ABANCAQuestionsProvider>
    </ActiveDialogProvider>
  )
}

function IdeaForm() {
  const { tagList } = useContext(TagContext)
  const { fileList } = useContext(FileContext)
  const { description } = useContext(DescriptionContext)
  const { shortDescription } = useContext(ShortDescriptionContext)
  const { coverImg } = useContext(CoverContext)
  // Collaborators and questios from abanca
  const { abancaFields } = useContext(ABANCAQuestionsContext)
  const { vectorFields } = useContext(VectorQuestionsContext)
  const { userlist } = useContext(UserlistContext)
  // Questios from dow
  const { dowFields } = useContext(DOWQuestionsContext)
  // Questios from softtek automation
  const { softtekAutoFields } = useContext(SofttekAutoQuestionsContext)
  // set index for swipeable view
  const [indexView, setIndexView] = React.useState(0)
  const [open, setOpen] = useState(false)
  const [error, setError] = useState('')
  const [touche, setTouched] = useState(false)
  const [msg, setMsg] = useState('')
  const [validation, setValidation] = useState(false)
  const [text, setText] = useState('')
  const [typeIdea, setTypeIdea] = useState('')
  const [response, setResponse] = useState([])
  const [valAutomation, setValAutomation] = useState(false)
  const [sendAnwserAuto, setSendAnwserAuto] = useState(false)
  const [loadingFiles, handleLoadingFiles] = useState(false)
  const [idea, setIdea] = useState({} as Idea)
  const handleChangeIndex = index => {
    setIndexView(index)
  }
  // @ts-ignore
  const classes = useStyles()
  const { dispatch: dialogDispatch } = useContext(ActiveDialogContext)

  useEffect(() => {
    if (coverImg === null && process.env.REACT_APP_SERVICE === 'softtek') {
      setError('Add the picture of your idea')
      setTouched(false)
    } else {
      setError('')
      setTouched(true)
    }
  })

  const validateIdeaType = value => {
    if (value === '') {
      return _i('Por favor, selecciona un tipo de idea.')
    }
    setTypeIdea(value)

    return null
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const validateDescription = () => {
    let error
    if (!shortDescription.trim()) {
      error = 'Add the description of your idea'
    }
    if (shortDescription.length > 140) {
      error = 'Idea Description should be less than 140 characters'
    }
    return error
  }

  const api = async () => {
    var client = 'Softtek'
    if (process.env.REACT_APP_SERVICE === 'heb') client = 'HEB'
    if (validation) {
      try {
        await fetch(
          'https://ssimilarity.azurewebsites.net/api/sentence_similarity',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'Application/JSON',
              'Access-Control-Request-Method': 'POST',
            },
            body: JSON.stringify({
              name: client,
              idea: text.replace(/<[^>]*>/g, ''), //'esta es una pruebaaaa',
              topx: 4,
            }),
          }
        ).then(async response => {
          setResponse([])
          const responseJson = await response.json()
          Object.values(responseJson).map(async resp => {
            if (resp[1] > 0) {
              setResponse(prevState => [
                ...prevState,
                {
                  shortDescription: resp[0],
                  porcentaje: resp[1],
                  description: resp[2],
                  id: resp[3],
                  name: resp[4],
                },
              ])
            }
          })
        })
        return 'Success'
      } catch (error) {
        return 'Error:' + error
      }
    }
  }

  const setAnswer = () => {
    const {
      manual,
      frecuencia,
      volumetrias,
      volumetriasUM,
      duracion,
      duracionUM,
      consistencia,
      reglas,
      datosEstandarizados,
      datosLegibles,
      procedimientoHabitual,
      cambiosProceso,
      cambioSistema,
      NoAplicaciones,
    } = vectorFields

    var sumaRPA = 0
    var sumaBI = 0
    //suma para obtener RPA Potential segun las respuestas
    switch (frecuencia) {
      case 'Continuo':
        sumaRPA = sumaRPA + 5
        break
      case 'Multidiario':
        sumaRPA = sumaRPA + 4
        break
      case 'Diario':
        sumaRPA = sumaRPA + 3
        break
      case 'Semanal':
        sumaRPA = sumaRPA + 2
        break
      case 'Mensual':
        sumaRPA = sumaRPA + 1
        break
      default:
        sumaRPA = sumaRPA + 0
        break
    }
    if (consistencia.includes('Si')) sumaRPA = sumaRPA + 4
    if (reglas.includes('Si')) sumaRPA = sumaRPA + 4
    else if (reglas.includes('Basadas')) sumaRPA = sumaRPA - 3
    if (datosEstandarizados.includes('simple')) sumaRPA = sumaRPA + 2
    else if (datosEstandarizados.includes('Si')) sumaRPA = sumaRPA + 4
    else if (datosEstandarizados.includes('No')) sumaRPA = sumaRPA - 2
    if (datosLegibles.includes('Fácil')) sumaRPA = sumaRPA + 4
    else if (datosLegibles.includes('Media')) sumaRPA = sumaRPA + 2
    else if (datosLegibles.includes('Crítico')) sumaRPA = sumaRPA - 2
    if (cambiosProceso.includes('No')) sumaRPA = sumaRPA + 4
    else if (cambiosProceso.includes('anualmente')) sumaRPA = sumaRPA + 2
    switch (NoAplicaciones) {
      case 1:
        sumaRPA = sumaRPA + 5
        break
      case 2:
        sumaRPA = sumaRPA + 5
        break
      case 3:
        sumaRPA = sumaRPA + 3
        break
      case 4:
        sumaRPA = sumaRPA + 1
        break
      default:
        sumaRPA = sumaRPA + 0
        break
    }

    //suma para obtener Business Impact' segun las respuestas
    if (manual.includes('completo')) sumaBI = sumaBI + 5
    else if (manual.includes('Bastante')) sumaBI = sumaBI + 3
    else if (manual.includes('Nada')) sumaBI = sumaBI - 3
    if (procedimientoHabitual.includes('>80%')) sumaBI = sumaBI + 4
    else if (procedimientoHabitual.includes('60-80%')) sumaBI = sumaBI + 3
    else if (procedimientoHabitual.includes('<60%')) sumaBI = sumaBI + 2
    if (cambioSistema.includes('No')) sumaBI = sumaBI + 4
    else if (cambioSistema.includes('anualmente')) sumaBI = sumaBI + 2
    var CapacidadLib = volumetrias * duracion
    switch (duracionUM) {
      case 'Segundos':
        CapacidadLib = CapacidadLib / 3600
        break
      case 'Minutos':
        CapacidadLib = CapacidadLib / 60
        break
    }
    switch (volumetriasUM) {
      case 'Día':
        CapacidadLib = CapacidadLib * 360
        break
      case 'Mes':
        CapacidadLib = CapacidadLib * 12
        break
    }
    if (CapacidadLib >= 1500) sumaBI = sumaBI + 5
    else if (CapacidadLib >= 750) sumaBI = sumaBI + 3
    else if (CapacidadLib >= 375) sumaBI = sumaBI + 1
    else if (CapacidadLib < 150) sumaBI = sumaBI - 2

    /** porcentaje de RPA con la regla de tres simples:
     * mayor puntuacion que se puede obtener en la suma es 30 */

    const percentRPA = (sumaRPA * 100) / 30

    /** porcentaje de BI con la regla de tres simples:
     * mayor puntuacion que se puede obtener en la suma es 18 */
    const percentBI = (sumaBI * 100) / 18

    return {
      RPA: sumaRPA,
      BI: sumaBI,
      RPApercentage: parseInt(percentRPA.toString()),
      BIpercentage: parseInt(percentBI.toString()),
    }
  }

  //upload file function
  return (
    <NewIdeaComponent>
      {(newIdea, { loading }) => (
        <Formik
          //when form is submitted, realize the mutation
          onSubmit={async data => {
            handleLoadingFiles(true)
            //clean the tag list, from json to list
            const cleanTagList = []
            for (let i = 0; i < tagList.length; i++) {
              cleanTagList.push(tagList[i].value)
            }
            const ideaId = createIdeaId(data.name)

            //wait for upload_file response

            const attachmentsResponse = await uploadFile(fileList, ideaId)
            const fileJsonResponse = await attachmentsResponse
            const URLList = await fileJsonResponse['attachments']

            //create a new
            const cleanFiles = []
            //iterate over response and attach the obtained url from each file

            for (let i = 0; i < fileList.length; i++) {
              let cleanTempFile = {}
              cleanTempFile['url'] = URLList[i]['URL']
              cleanTempFile['type'] = fileList[i].file.type
              cleanTempFile['name'] = fileList[i].file.name
              //push json values to clean files array
              cleanFiles.push(cleanTempFile)
            }

            //upload cover
            let coverImgResponse = null
            if (coverImg) {
              coverImgResponse = await uploadFile([coverImg], ideaId)
            }

            //Clean ABANCA Fields parse string to int and add collaborators
            const cleanABANCAFields = {
              ...abancaFields,
              volumenEstimado:
                // @ts-ignore
                parseInt(abancaFields.volumenEstimado) || 0,
              // @ts-ignore
              tiempoTarea: parseFloat(abancaFields.tiempoTarea) || 0,
              tiempoTareaVolumen:
                // @ts-ignore
                parseFloat(abancaFields.tiempoTareaVolumen) || 0,
              collaborators: userlist.map(
                user => ({ ...user } as CollaboratorInput)
              ),
            }

            //Clean ABANCA Fields parse string to int and add collaborators
            const cleanDowFields = {
              ...dowFields,
              // @ts-ignore
              MonthAndYear: parseInt(dowFields.MonthAndYear) || 0,
              // @ts-ignore
              ProjectId: parseInt(dowFields.ProjectId) || 0,
              // @ts-ignore
              DedicatedTime: parseFloat(dowFields.DedicatedTime) || 0,
              // @ts-ignore
              TotalWorkedHours: parseInt(dowFields.TotalWorkedHours) || 0,
              // @ts-ignore
              DedicateTime: parseInt(dowFields.DedicateTime) || 0,
              // @ts-ignore
              CRM: parseInt(dowFields.CRM) || 0,
              // @ts-ignore
              NPI: parseInt(dowFields.NPI) || 0,
              // @ts-ignore
              Code: parseInt(dowFields.Code) || 0,
              EstimatedProjectDuration:
                // @ts-ignore
                parseInt(dowFields.EstimatedProjectDuration) || 0,
              TotalProjectDuration:
                // @ts-ignore
                parseInt(dowFields.TotalProjectDuration) || 0,
            }

            const cleanSofttekAutoFields = {
              ...softtekAutoFields,
            }

            const { RPA, BI, RPApercentage, BIpercentage } = setAnswer()
            const cleanVectorFields = {
              ...vectorFields,
              // @ts-ignore
              volumetrias: parseInt(vectorFields.volumetrias) || 0,
              // @ts-ignore
              duracion: parseInt(vectorFields.duracion) || 0,
              // @ts-ignore
              NoAplicaciones: parseInt(vectorFields.NoAplicaciones) || 0,
              // @ts-ignore
              scores: {
                RPA: RPA,
                BI: BI,
                RPApercentage: RPApercentage,
                BIpercentage: BIpercentage,
              },
            }
            //upload to server
            handleLoadingFiles(false)
            await newIdea({
              variables: {
                idea: {
                  id: ideaId,
                  shortDescription: shortDescription,
                  name: data.name,

                  description: description,
                  abancaFields: cleanABANCAFields,
                  dowFields: cleanDowFields,
                  vectorFields: cleanVectorFields,
                  softtekAutoFields: cleanSofttekAutoFields,
                  type: data.type,
                  tags: cleanTagList,
                  attachments: cleanFiles,
                  cover: coverImgResponse
                    ? coverImgResponse.attachments[0]['URL']
                    : null,
                },
              },
            })
            setIdea({
              id: ideaId,
              shortDescription: shortDescription,
              name: data.name,
              description: description,
              abancaFields: cleanABANCAFields,
              dowFields: cleanDowFields,
              softtekAutoFields: cleanSofttekAutoFields,
              type: data.type,
              tags: cleanTagList,
              attachments: cleanFiles,
              cover: coverImgResponse
                ? coverImgResponse.attachments[0]['URL']
                : null,
            } as Idea)
            dialogDispatch({ type: 'update', status: false })
            if (!open) window.location.replace(`/idea/${ideaId}`)
          }}
          //initial variable values
          initialValues={{
            name: '',
            description: '',
            shortDescription: '',
            type:
              process.env.REACT_APP_SERVICE === 'abanca' ? 'innovación' : '',
          }}>
          {({ errors, touched, values, handleSubmit }) => (
            <form
              id="NewIdea"
              onSubmit={e => {
                //triggering from the submit button not on the form submit
                e.preventDefault()
              }}>
              {error !== '' && process.env.REACT_APP_SERVICE === 'softtek'
                ? (errors['coverImg'] = error)
                : null}
              {process.env.REACT_APP_SERVICE === 'softtek'
                ? (touched['coverImg'] = touche)
                : null}
              <Congratulations message={msg} />
              <div className={classes.formBox}>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={indexView}
                  style={{ overflow: 'scroll' }}
                  onChangeIndex={handleChangeIndex}>
                  <div>
                    <div
                      className={classes.formBox}
                      style={{ overflow: 'scroll' }}>
                      <SideGrid className={classes.mobileSideGrid}>
                        <div style={{ display: 'flex', width: '100%' }}>
                          <div style={{ width: '100%' }}>
                            <Field
                              name="name"
                              placeholder={
                                process.env.REACT_APP_SERVICE ===
                                'softtekautomation'
                                  ? _i('Use Case Name ...')
                                  : _i('Título de la idea...')
                              }
                              component={InputField}
                              validate={validateName}
                            />
                            {errors.name && touched.name ? (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  fontFamily: 'Roboto',
                                }}>
                                {errors.name}
                              </p>
                            ) : null}
                            <Field
                              name="shortDescription"
                              placeholder={_i('Descripción breve...')}
                              component={ShortDescriptionField}
                              validate={
                                (process.env.REACT_APP_SERVICE === 'softtek' ||
                                  process.env.REACT_APP_SERVICE ===
                                    'softtekautomation') &&
                                validateDescription
                              }
                            />
                            {errors.shortDescription &&
                            touched.shortDescription ? (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  fontFamily: 'Roboto',
                                }}>
                                {errors.shortDescription}
                              </p>
                            ) : null}
                          </div>
                          <div style={{ flex: '1', paddingLeft: '10px' }}>
                            <CoverDrop initState={null} />

                            {error && !touche ? (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '10px',
                                  fontFamily: 'Roboto',
                                }}>
                                {error}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <CKEditorComponent
                          initState={''}
                          validation={(validacion, texto) => {
                            setValidation(validacion)
                            setText(texto)
                          }}
                          api={api}
                        />
                        {validation && response.length > 0 ? (
                          <IdeaSimilar initState={response} />
                        ) : (
                          <br />
                        )}
                        {/* some instance could have no type  */}
                        {process.env.REACT_APP_SERVICE !== 'abanca' && (
                          <Field
                            name="type"
                            placeholder={
                              process.env.REACT_APP_SERVICE ===
                              'softtekautomation'
                                ? _i('Source')
                                : _i('Tipo de idea')
                            }
                            component={SelectField}
                            validate={validateIdeaType}
                          />
                        )}
                        {errors.type && touched.type ? (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '10px',
                              fontFamily: 'Roboto',
                            }}>
                            {errors.type}
                          </p>
                        ) : null}
                      </SideGrid>
                      <SideGrid style={{ marginRight: '-2px' }}>
                        <TagInput initState={[]} />
                        <DropBox initState={[]} />
                      </SideGrid>
                    </div>
                    {process.env.REACT_APP_SERVICE === 'abanca' && (
                      <div className={classes.formBox}>
                        <ABANCAQuestions />
                      </div>
                    )}
                    {process.env.REACT_APP_SERVICE === 'dow' && (
                      <div className={classes.formBox}>
                        <DOWQuestions />
                      </div>
                    )}

                    {process.env.REACT_APP_SERVICE === 'vector' &&
                      typeIdea === 'Automatización' && (
                        <div className={classes.formBox}>
                          <VectorQuestions />
                        </div>
                      )}

                    {process.env.REACT_APP_SERVICE === 'softtekautomation' && (
                      <div className={classes.formBox}>
                        <SofttekAutoQuestions
                          validation={sendAnwserAuto}
                          setValidation={value => setValAutomation(value)}
                        />
                      </div>
                    )}
                  </div>
                </SwipeableViews>
              </div>
              {/* {(loading || loadingFiles) && <Loading />} */}
              <Grid container justify="flex-end">
                {(loading || loadingFiles) && (
                  <>
                    <CircularProgress size={30} style={{ margin: '10px' }} />
                    {process.env.REACT_APP_SERVICE === 'heb' &&
                      setMsg(
                        _i(
                          '¡Tu idea fue registrada! Gracias por ayudarnos a fomentar la cultura de la Innovación en H-E-B.'
                        )
                      )}
                  </>
                )}
                <p
                  style={{
                    fontFamily: 'Roboto',
                    padding: '4px 25px',
                    fontSize: '14px',
                  }}>
                  {loading && !loadingFiles ? _i('Subiendo Idea...') : null}
                  {loadingFiles ? _i('Subiendo Contenido...') : null}
                </p>

                {process.env.REACT_APP_SERVICE === 'heb' && (
                  <>
                    {open && (
                      <LinkIdea
                        idea={idea}
                        openIdea={true}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                      />
                    )}

                    <Button
                      onClick={e => {
                        if (touched.name && touched.type) {
                          setOpen(true)
                          e.preventDefault()
                          handleSubmit()
                        }
                      }}
                      variant={'contained'}
                      size="medium"
                      style={{ fontFamily: 'InnoArial' }}
                      className={getInstanceLayout({
                        heb: classes.HEB_custom_button,
                        softtek: classes.button,
                        sandbox: classes.button,
                        abanca: classes.ABANCA_custom_button,
                      })}>
                      {_i('Enlazar a un reto')}
                    </Button>
                  </>
                )}
                {process.env.REACT_APP_SERVICE === 'softtekautomation' ? (
                  <Button
                    onClick={e => {
                      setSendAnwserAuto(true)
                      if (values.shortDescription === '') {
                        errors['shortDescription'] =
                          'Add the short description '
                        touched['shortDescription'] = true
                      }
                      if (values.name === '') {
                        errors['name'] = 'Add the name '
                        touched['name'] = true
                      }

                      if (values.type === '') {
                        errors['type'] = 'Select the source '
                        touched['type'] = true
                      }

                      if (values.description === '') {
                        errors['description'] = 'Add the  description '
                        touched['description'] = true
                      }
                      if (valAutomation) {
                        setOpen(false)
                        e.preventDefault()
                        if (loading || loadingFiles) return
                        handleSubmit()
                      }
                    }}
                    variant={'contained'}
                    size="medium"
                    className={getInstanceLayout({
                      heb: classes.HEB_custom_button,
                      softtek: classes.button,
                      sandbox: classes.button,
                      abanca: classes.ABANCA_custom_button,
                      caixabank: classes.caixaBank_custom_button,
                      softtekautomation: classes.button,
                    })}
                    style={{ fontFamily: 'InnoArial' }}>
                    {_i('Enviar')}
                  </Button>
                ) : (
                  <Button
                    onClick={e => {
                      setOpen(false)
                      e.preventDefault()
                      if (loading || loadingFiles) return
                      handleSubmit()
                    }}
                    variant={'contained'}
                    size="medium"
                    style={{ fontFamily: 'InnoArial' }}
                    className={getInstanceLayout({
                      heb: classes.HEB_custom_button,
                      softtek: classes.button,
                      sandbox: classes.button,
                      abanca: classes.ABANCA_custom_button,
                      caixabank: classes.caixaBank_custom_button,
                    })}>
                    {_i('Enviar')}
                  </Button>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </NewIdeaComponent>
  )
}
export default NewIdea
