import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CoverContext } from './CoverDropContext'
import { CustomBadge } from '../CustomBadge/CustomBadge'
import classes from '*.module.css'
import { _i } from '../../../../Utils/lang/i18n'
const FileTag = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 10px;
`
const DropBoxOutline = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ecedf1;
  color: #727272;
  width: 89px;
  height: 89px;
  border-radius: 12px;
  padding: 6px;
  font-size: 14px;
  transition: all 0.5s;
  transition: all 0.5s;
`
const ImagePreview = styled.img`
  width: auto;
`
interface CoverDropProps {
  initState: any
}
export const CoverDrop: React.FC<CoverDropProps> = ({ initState }) => {
  const { coverImg, dispatch } = useContext(CoverContext)
  const [width, handleWidth] = useState(0)
  const [height, handleHeight] = useState(0)
  const imgRegex = /.*\.(jpg|jpeg|gif|png|tiff|tif)$/i
  useEffect(() => {
    if (initState && initState.url) {
      dispatch({
        type: 'add',
        file: {
          name: initState.name,
          type: initState.type,
          url: initState.url,
        },
        init: true,
      })
    }
    // console.log(initState)
  }, [])
  const validate_dimensions = async file => {
    let fr = new FileReader()
    let validation: boolean

    fr.onload = async function() {
      // file is loaded
      let img = new Image()
      let str = String(fr.result)
      img.src = str // is the data URL because called with readAsDataURL
      img.onload = async function() {
        if (img.width < 400 || img.height < 400) {
          validation = false
        } else {
          validation = true
          handleHeight(img.height)
          handleWidth(img.width)
        }
      }
    }

    await fr.readAsDataURL(file)
    return await validation
  }
  const handleDrop = e => {
    //just add the first file detected,
    //Prevent adding multiple instances
    e.stopPropagation()
    e.preventDefault()
    let files = e.dataTransfer.files
    let file_temp = {
      name: files[0].name,
      size: files[0].size,
      type: files[0].type,
      file: files[0],
    }
    //Check if it is really an img
    let img_validation = imgRegex.exec(file_temp.name)
    if (img_validation != null) {
      let validate_dim = validate_dimensions(file_temp.file)
      if (validate_dim) {
        dispatch({ type: 'add', file: file_temp, init: false })
      }
    }
  }
  const handlePick = e => {
    e.stopPropagation()
    e.preventDefault()
    let files = e.target.files
    let file_temp = {
      name: files[0].name,
      size: files[0].size,
      type: files[0].type,
      file: files[0],
    }
    //Check if it is really an img
    let img_validation = imgRegex.exec(file_temp.name)
    if (img_validation != null) {
      let validate_dim = validate_dimensions(file_temp.file)
      if (validate_dim) {
        dispatch({ type: 'add', file: file_temp, init: false })
      }
    }
  }
  const onDragOver = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <div>
      {/*set up the file Provider*/}

      {coverImg ? (
        <CustomBadge i={0} method="CoverDrop">
          <div
            style={{
              height: '89px',
              width: '89px',
              overflow: 'hidden',
              borderRadius: '12px',
            }}>
            <ImagePreview
              style={{
                width: width < height ? '89px' : 'auto',
                height: width >= height ? '89px' : 'auto',
                transform:
                  width > height
                    ? `translateX(-${(89 / 2) * (width / height - 1)}px)`
                    : `translateY(-${(89 / 2) * (height / width - 1)}px)`,
              }}
              src={coverImg.preview}
            />
          </div>
        </CustomBadge>
      ) : (
        <div>
          <DropBoxOutline
            style={{ cursor: 'pointer' }}
            onDragOver={e => {
              onDragOver(e)
            }}
            onDrop={e => {
              handleDrop(e)
            }}
            onClick={() => {
              document.getElementById('custom-traditional-input').click()
            }}>
            <CloudUploadIcon style={{ fontSize: 31 }} />
            <FileTag>{_i('Carga tu Portada')}</FileTag>
          </DropBoxOutline>
          <p
            style={{
              color: '#727272',
              fontSize: '10px',
              fontFamily: 'Open Sans',
              margin: '0px',
              textAlign: 'center',
            }}>
            {_i('Tam. Min: 400x400')}
          </p>
        </div>
      )}
      <input
        id="custom-traditional-input"
        hidden
        type="file"
        onChange={e => {
          handlePick(e)
        }}
      />
    </div>
  )
}
