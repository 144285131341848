import React from 'react'

// React router
import { Link as BrowserLink } from 'react-router-dom'

// Material ui
import Grid from '@material-ui/core/Grid'
import { Title } from '../../../../components/Shared'

import {
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Divider,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  useFeaturedTagsQuery,
  useReplicableIdeasQuery,
} from '../../../../generated/graphql'
import Loading from '../../../../components/Loading'
import { camelize } from '../../../../Utils/textUtils'
import { _i } from '../../../../Utils/lang/i18n'

interface Props {}

const TrendingTags: React.FC<Props> = () => {
  const {
    data: { featuredTags },
    loading,
  } = useFeaturedTagsQuery()
  const {
    data: { replicableIdeas },
  } = useReplicableIdeasQuery()
  const theme = useTheme()
  return (
    <Grid container direction="column">
      <Title
        style={{
          margin: '8px 12px',
          fontFamily: 'InnoArial',
          fontSize: '22px',
        }}>
        {_i('Etiquetas Populares')}
      </Title>
      {loading && <Loading />}
      {!loading && (
        <List>
          {featuredTags &&
            featuredTags.slice(0, 5).map(tag => (
              <div style={{ height: '42px' }} key={`${tag.name}`}>
                <ListItem>
                  <ListItemText disableTypography>
                    <Link
                      variant="body1"
                      style={{ fontFamily: 'InnoArial' }}
                      component={BrowserLink}
                      to={`/tag/${tag.name}`}>
                      {camelize(tag.name)}
                    </Link>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Typography
                      variant="body2"
                      style={{ fontFamily: 'InnoArial' }}>
                      {process.env.REACT_APP_SERVICE === 'softtekautomation'
                        ? `${tag.count} ${tag.count === 1 ? 'asset' : 'assets'}`
                        : `${tag.count} ${tag.count === 1 ? 'idea' : 'ideas'}`}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider light />
              </div>
            ))}
        </List>
      )}
      {process.env.REACT_APP_SERVICE === 'softtekautomation' && (
        <>
          <Title
            style={{
              margin: '8px 12px',
              fontFamily: 'InnoArial',
              fontSize: '22px',
            }}>
            {_i('Replicable')}
          </Title>
          {replicableIdeas !== undefined && (
            <List>
              {replicableIdeas &&
                replicableIdeas.slice(0, 5).map(tag => (
                  <div style={{ height: '42px' }} key={`${tag.type}`}>
                    <ListItem>
                      <ListItemText disableTypography>
                        <Link
                          variant="body1"
                          style={{ fontFamily: 'InnoArial' }}
                          component={BrowserLink}
                          to={`/replicable/${tag.type}`}>
                          {tag.type === 'YES'
                            ? _i('Replicable')
                            : _i('No Replicable')}
                        </Link>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <Typography
                          variant="body2"
                          style={{ fontFamily: 'InnoArial' }}>
                          {`${tag.count} ${
                            tag.count === 1 ? 'asset' : 'assets'
                          }`}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider light />
                  </div>
                ))}
            </List>
          )}
        </>
      )}
    </Grid>
  )
}

export default TrendingTags
