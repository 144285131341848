import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import DesktopTopBar from './DesktopTopBar'
import { Hidden } from '@material-ui/core'
import MobileTopBar from './MobileTopBar'
import { useMeQuery } from '../../../generated/graphql'

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  navBar: {
    backgroundColor: theme.palette.background.paper,
    color: '#000',
    height: '70px',
    boxShadow: '0px 0px 7px -1px rgba(112,112,112,1)',
    [theme.breakpoints.down('sm')]: {
      height: '120px',
    },
  },
  fullHeight: {
    height: '100%',
  },
}))

interface NavBarProps {}

const TopBar: React.FC<NavBarProps> = () => {
  const classes = useStyles()
  // Read local authStatus. 200 means user is loggedIn and has permissions.
  const { loading } = useMeQuery()

  if (loading) {
    return <></>
  }
  // if (data.authState !== 200) {
  //   return <div style={{ marginTop: '100px' }}>{children}</div>
  // }
  return (
    <div className={classes.root}>
      <AppBar className={classes.navBar}>
        {/* Desktop */}
        <Hidden smDown>
          <DesktopTopBar />
        </Hidden>
        {/* Mobile */}
        <Hidden mdUp>
          <MobileTopBar />
        </Hidden>
      </AppBar>
    </div>
  )
}

export default TopBar
