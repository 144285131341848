import React from 'react'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { useStyles } from './styles'
interface Props {
  value: Date
  onChange: any
}
const DatePicker: React.FC<Props & any> = ({ value, onChange, ...props }) => {
  // @ts-ignore
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        className={classes.input}
        id="date-picker-inline"
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputLabelProps={{
          shrink: false,
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            marginTop: 0,
          },
        }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  )
}
export default DatePicker
