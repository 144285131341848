import { makeStyles } from '@material-ui/core/styles'
import palette from '../../../../theme/palette'

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  mainContainer: {
    overflowX: 'hidden' as 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
  fab: {
    backgroundColor: '#ECEDF1',
    padding: '2px',
    width: '25px',
    height: '25px',
    minHeight: '25px',
    float: 'right' as 'right',
    boxShadow: 'none',
  },
  icon: {
    color: '#6C6D6F',
    fontSize: '20px',
    padding: '2px',
  },
  name: {
    wordWrap: 'break-word' as 'break-word',
    fontFamily: 'InnoArial',
  },
  shortDescription: {
    color: '#4d4d4d',
    marginTop: '8px',
    wordWrap: 'break-word' as 'break-word',
  },
  img: {
    display: 'block',
    maxWidth: '40%',
    maxHeight: '40%',
  },
  tagBox: {
    padding: '1em 0em',
    display: 'flex',
    marginBottom: '0.5em',
    flexWrap: 'wrap' as 'wrap',
  },
  tag: {
    marginRight: '6px',
    marginBottom: '6px',
    paddingBottom: '1px',
    cursor: 'pointer',
    border: '1px solid #adadad',
    textDecoration: 'none',
    '&:hover': {
      border: '1px solid transparent',
      backgroundColor: '#ECEDF1',
    },
    fontFamily: 'InnoArial',
    lineHeight: '16px',
  },
  type: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  evolution: {
    marginRight: '6px',
    marginBottom: '6px',
    paddingBottom: '1px',
    cursor: 'pointer',
    border: '1px solid #adadad',
    color: '#FFFFFF',
    textDecoration: 'none',
    '&:hover': {
      border: '1px solid transparent',
      backgroundColor: '#ECEDF1',
    },
    fontFamily: 'InnoArial',
    lineHeight: '16px',
  },
}))
