import React from 'react'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { Attachment, useMeQuery } from '../../../../../generated/graphql'
import { concatenateSASOrNot } from '../../../../../Utils'
import PhotoLoader from './PhotoLoader'
import Backdrop from '@material-ui/core/Backdrop'
import { _i } from '../../../../../Utils/lang/i18n'
import { getPlaceholder } from '../../../../../Utils/placeholder'
import { useStyles } from './styles'

interface Props {
  attachments: Attachment[]
  ideaCover: any
}
const Carousel: React.FC<Props> = React.memo(({ attachments, ideaCover }) => {
  const {
    data: {
      me: {
        sas: { token },
      },
    },
    loading,
  } = useMeQuery()

  // @ts-ignore
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const [images, setImages] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [name, setName] = React.useState('')
  let maxSteps = images.length
  React.useEffect(() => {}, [])
  React.useEffect(() => {
    let onlyImages: any[] = []
    //First always add the cover, if there is any
    if (ideaCover) {
      onlyImages.push({ name: 'cover', type: 'image', url: ideaCover })
    }
    //Then, add the images
    let attachmentImages = attachments.filter(att => att.type.includes('image'))
    attachmentImages.map(attachment => {
      onlyImages.push(attachment)
    })
    setImages(onlyImages)
    // console.log(images)
  }, [attachments])
  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const handleClose = () => {
    setOpen(false)
  }
  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  function handleStepChange(step) {
    setActiveStep(step)
  }
  if (loading) {
    return <></>
  }
  if (images.length === 0) {
    return (
      <div>
        <img src={`${getPlaceholder()}`} className={classes.img} />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents>
        {images.map((attachment, key) => (
          <div
            key={key}
            onClick={() => {
              setOpen(!open)
              setUrl(attachment.url)
              setName(attachment.name)
            }}>
            <PhotoLoader
              onLoad={() => {}}
              imgClass={classes.img}
              setLoader={true}
              src={concatenateSASOrNot(token, attachment.url, 'attachment')}
              alt={attachment.name}
            />
            {/* <img
              className={classes.img}
              src={concatenateSASOrNot(token, attachment.url, 'attachment')}
              alt={attachment.name}
            /> */}
          </div>
        ))}
      </SwipeableViews>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <PhotoLoader
          onLoad={() => {}}
          imgClass={classes.imgen}
          setLoader={true}
          src={concatenateSASOrNot(token, url, 'attachment')}
          alt={name}
        />
      </Backdrop>
      <MobileStepper
        className={classes.stepper}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}>
            {_i('Adelante')}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {_i('Atrás')}
          </Button>
        }
      />
    </div>
  )
})

export default Carousel
