import React from 'react'
import Layout from './Layout'
import { Route } from 'react-router-dom'
import { ActiveDialogProvider } from '../../components/NewIdea/ActiveDialogContext'
const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <ActiveDialogProvider>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </ActiveDialogProvider>
      )}
    />
  )
}

export default MainLayout
