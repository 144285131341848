//local messages depending in the language configuration
interface localMessageList {
  [index: string]: localMessage
}
type localMessage = { [index in langTypes]: string }
export type langTypes = 'es' | 'en' | 'pt'

export const lcmsgs: localMessageList = {
  Registrarse: {
    es: 'Registrarse',
    en: 'Register',
    pt: 'Inscreva-se',
  },
  '¿Olvidaste tu contraseña?': {
    es: '¿Olvidaste tu contraseña?',
    en: 'Forgot your password?',
    pt: 'Esqueceu sua senha?',
  },
  'Usuario o correo inválido': {
    es: 'Usuario y/o correo inválido',
    en: 'Invalid username and/or password',
    pt: 'Nome de usuário e / ou senha inválidos',
  },
  'Usuario no verificado, reenviando verificación': {
    es: 'Usuario no verificado, reenviando verificación',
    en: 'Unverified user, verification sent.',
    pt: 'Verificação de usuário não confirmada enviada.',
  },
  'Correo o contraseña incorrectos': {
    es: 'Correo o contraseña incorrectos',
    en: 'The email or password you entered isn´t correct',
    pt: 'O email ou a senha que você digitou não estão corretos',
  },
  'Usuario o correo': {
    es: 'Email',
    en: 'Email',
    pt: 'E-mail',
  },
  Contraseña: {
    es: 'Contraseña',
    en: 'Password',
    pt: 'Senha',
  },
  Acceder: {
    es: 'Acceder',
    en: 'Access',
    pt: 'Acesso',
  },
  'Entrar Con Microsoft': {
    es: 'Entrar Con Microsoft',
    en: 'Enter with Microsoft',
    pt: 'Entre com a Microsoft',
  },
  'Crea tu cuenta': {
    es: 'Crea tu cuenta',
    en: 'Create account',
    pt: 'Criar conta',
  },
  'Recupera tu contraseña': {
    es: 'Recupera tu contraseña',
    en: 'Recover password',
    pt: 'Recuperar senha',
  },
  'El usuario ha sido creado exitosamente': {
    es: 'El usuario ha sido creado exitosamente',
    en: 'The user has been created successfully',
    pt: 'O usuário foi criado com sucesso',
  },
  'Correo Electrónico': {
    es: 'Correo Electrónico',
    en: 'Email',
    pt: 'E-mail',
  },
  Usuario: {
    es: 'Usuario',
    en: 'Username',
    pt: 'Usuário',
  },
  'Enviar Verificación': {
    es: 'Enviar Verificación',
    en: 'Send Verification',
    pt: 'Enviar verificação',
  },
  'El mail de verificación ha sido enviado!': {
    es: 'El mail de verificación ha sido enviado!',
    en: 'Verification email has been sent',
    pt: 'O email de verificação foi enviado',
  },
  'Esta cuenta ya existe': {
    es: 'Esta cuenta ya existe',
    en: 'This account already exists',
    pt: 'Esta conta não existe mais',
  },
  'Ingresa tu correo': {
    es: 'Ingresa tu correo',
    en: 'Enter your email',
    pt: 'Digite seu email',
  },
  'Mail inválido.': {
    es: 'Mail inválido',
    en: 'Invalid email',
    pt: 'E-mail inválido',
  },
  'Se produjo un error': {
    es: 'Se produjo un error',
    en: 'There was an error',
    pt: 'Ocorreu um erro',
  },
  'Crear Tu Cuenta': {
    es: 'Crear Tu Cuenta',
    en: 'Create your Account',
    pt: 'Crie sua conta',
  },
  Regresar: {
    es: 'Regresar',
    en: 'Back',
    pt: 'Voltar',
  },
  'Quitar idea destacada': {
    es: 'Quitar idea destacada',
    en: 'Remove featured idea',
    pt: 'Remover ideia em destaque',
  },
  'Quitar automatización destacada': {
    es: 'Quitar automatización destacada',
    en: 'Remove Featured Automation',
    pt: 'Remover automação em destaque',
  },
  'Agregar como idea destacada': {
    es: 'Agregar como idea destacada',
    en: 'Add as featured idea',
    pt: 'Adicionar como ideia em destaque',
  },
  'Agregar como automatización destacada': {
    es: 'Agregar como automatización destacada',
    en: 'Add as Featured Automation',
    pt: 'Adicionar como automação em destaque',
  },
  'Ideas Destacadas': {
    es: 'Ideas Destacadas',
    en: 'Featured ideas',
    pt: 'Ideias em destaque',
  },
  'Ideas destacadas de la semana': {
    es: 'Ideas destacadas de la semana',
    en: 'Featured ideas of the week',
    pt: 'Ideias da semana em destaque',
  },
  'Automatizaciones Destacadas': {
    es: 'Automatizaciones Destacadas',
    en: 'Featured Automations',
    pt: 'Automações em destaque',
  },
  'Automatizaciones destacadas de la semana': {
    es: 'Automatizaciones destacadas de la semana',
    en: 'Featured Automations of the Week',
    pt: 'Automações em destaque da semana',
  },
  'Ver más': {
    es: 'Ver más',
    en: 'Show more',
    pt: 'Mostrar mais',
  },
  Explorar: {
    es: 'Explorar',
    en: 'Explore',
    pt: 'Explorar',
  },
  'Ideas Populares': {
    es: 'Ideas Populares',
    en: 'Popular Ideas',
    pt: 'Ideias populares',
  },
  'Ideas Recientes': {
    es: 'Ideas Recientes',
    en: 'Recent Ideas',
    pt: 'Ideias recentes',
  },
  'Automatizaciones Recientes': {
    es: 'Automatizaciones Recientes',
    en: 'Recent Automations',
    pt: 'Automações recentes',
  },

  'Mostrar automatizaciones recientes': {
    es: 'Mostrar automatizaciones recientes',
    en: 'Show recent automations',
    pt: 'Mostrar automações recentes',
  },
  'Automatizaciones Populares': {
    es: 'Automatizaciones Populares',
    en: 'Popular Automations',
    pt: 'Automações populares',
  },
  'Automatizaciones Por Rango de Fecha': {
    es: 'Automatizaciones Por Rango de Fecha',
    en: 'Automations By Date Range',
    pt: 'Automações por intervalo de datas',
  },

  Ordenar: {
    es: 'Ordenar',
    en: 'Sort',
    pt: 'Classificar',
  },
  Recientes: {
    es: 'Recientes',
    en: 'Recents',
    pt: 'Recentes',
  },
  'Mostrar ideas recientes': {
    es: 'Mostrar ideas recientes',
    en: 'Show recent ideas',
    pt: 'Mostrar ideias recentes',
  },
  Popular: {
    es: 'Popular',
    en: 'Popular',
    pt: 'Populares',
  },
  'Mostrar tendencias': {
    es: 'Mostrar tendencias',
    en: 'Show tendencies',
    pt: 'Mostrar tendências',
  },
  'Etiquetas Populares': {
    es: 'Etiquetas Populares',
    en: 'Popular Tags',
    pt: 'Tags populares',
  },
  'Tipos de Ideas': {
    es: 'Tipos de Ideas',
    en: 'Types of ideas',
    pt: 'Tipos de idéias',
  },
  'No hay una descripción disponible': {
    es: 'No hay una descripción disponible',
    en: 'There is no available description',
    pt: 'Não há descrição disponível',
  },
  'Nueva Idea': {
    es: 'Nueva Idea',
    en: 'New Idea',
    pt: 'Nova ideia',
  },
  'Nueva Automatización': {
    es: 'Nueva Automatización',
    en: 'New Automation',
    pt: 'Nova Automação',
  },
  Buscar: {
    es: 'Buscar',
    en: 'Search',
    pt: 'Pesquisar',
  },
  'Título de la idea...': {
    es: 'Título de la idea...',
    en: 'Title of the idea...',
    pt: 'Título da ideia ...',
  },
  'Descripción breve...': {
    es: 'Descripción breve...',
    en: 'Brief description...',
    pt: 'Breve descrição ...',
  },
  'Tipo de idea': {
    es: 'Tipo de idea',
    en: 'Type of idea',
    pt: 'Tipo de ideia',
  },
  'Subiendo Idea...': {
    es: 'Subiendo Idea...',
    en: 'Uploading Idea...',
    pt: 'A carregar ideia ...',
  },
  'Subiendo Contenido...': {
    es: 'Subiendo Contenido...',
    en: 'Uploading Content...',
    pt: 'A carregar conteúdo ...',
  },
  'Subiendo Challenge...': {
    es: 'Subiendo Challenge...',
    en: 'Uploading Challenge...',
    pt: 'A carregar desafio...',
  },
  'Danos una descripción breve...': {
    es: 'Por favor danos una descripción breve...',
    en: 'Please give us a brief description...',
    pt: 'Por favor, dê-nos uma breve descrição ...',
  },
  'Carga tu Portada': {
    es: 'Carga tu Portada',
    en: 'Load your Cover',
    pt: 'Carregue sua capa',
  },
  'Tam. Min: 400x400': {
    es: 'Tam. Min: 400x400',
    en: 'Min Size: 400x400',
    pt: 'Tam. Mín: 400x400',
  },
  'Ingresa Etiquetas...': {
    es: 'Ingresa Etiquetas...',
    en: 'Enter Tags...',
    pt: 'Inserir tags ...',
  },
  'Mensaje privado': {
    es: 'Mensaje Privado',
    en: 'Private Message',
    pt: 'Mensagem privada',
  },
  'Mandar mail a': {
    es: 'Mandar mail a',
    en: 'Send mail to',
    pt: 'Enviar email para',
  },
  'No hay retos disponibles': {
    es: 'No hay retos disponibles',
    en: 'No challenges available',
    pt: 'Nenhum desafio disponível',
  },
  Ver: {
    es: 'Ver',
    en: 'See',
    pt: 'Vejo',
  },
  Ocultar: {
    es: 'Ocultar',
    en: 'Hide',
    pt: 'Ocultar',
  },
  Respuesta: {
    es: 'Respuesta',
    en: 'Reply',
    pt: 'Responda',
  },
  Respuestas: {
    es: 'Respuestas',
    en: 'Replies',
    pt: 'Respostas',
  },
  "Separa tus etiquetas usando coma o 'Enter'": {
    es: "Separa tus etiquetas usando coma o 'Enter'",
    en: "Separate your tags using comma or 'Enter'",
    pt: 'Separe suas tags usando vírgula ou Enter',
  },
  Archivos: {
    es: 'Archivos',
    en: 'Files',
    pt: 'Arquivos',
  },
  'No se han subido archivos': {
    es: 'No se han subido archivos',
    en: 'The files have not been uploaded',
    pt: 'Os arquivos não foram enviados',
  },
  'Suelta tus archivos aquí. Serán subidos de manera automática...': {
    es: 'Suelta tus archivos aquí. Serán subidos de manera automática...',
    en: 'Drop your files here. They will be uploaded automatically...',
    pt: 'Solte seus arquivos aqui. Eles serão enviados automaticamente ...',
  },
  Enviar: {
    es: 'Enviar',
    en: 'Send',
    pt: 'Enviar',
  },
  'Título del Reto...': {
    es: 'Título del Reto...',
    en: 'Challenge Title...',
    pt: 'Título do Desafio ...',
  },
  'Por favor, selecciona un tipo de idea.': {
    es: 'Por favor, selecciona un tipo de idea.',
    en: 'Please, select a type of idea',
    pt: 'Selecione um tipo de ideia',
  },
  'Detalles de Archivos': {
    es: 'Detalles de Archivos',
    en: 'File Details',
    pt: 'Detalhes do arquivo',
  },
  Comentarios: {
    es: 'Comentarios',
    en: 'Comments',
    pt: 'Comentários',
  },
  'Agregar Comentario': {
    es: 'Agregar Comentario',
    en: 'Add Comment',
    pt: 'Adicionar comentário',
  },
  'Borrar Comentario': {
    es: 'Borrar Comentario',
    en: 'Delete Comment',
    pt: 'Excluir comentário',
  },
  'Deja un comentario...': {
    es: 'Deja un comentario...',
    en: 'Leave a comment...',
    pt: 'Deixe um comentário ...',
  },
  'Editar Nota': {
    es: 'Editar Nota',
    en: 'Edit Note',
    pt: 'Editar Nota',
  },
  'Notas de Administrador': {
    es: 'Notas de Administrador',
    en: 'Administrator Note',
    pt: 'Nota do Administrador',
  },
  'Nota guardada exitosamente': {
    es: 'Nota guardada exitosamente',
    en: 'Note saved successfully',
    pt: 'Nota salva com sucesso',
  },
  'Ocurrió algo al guardar la nota': {
    es: 'Ocurrió algo al guardar la nota',
    en: 'Something happened while saving the note',
    pt: 'Algo aconteceu ao salvar a nota',
  },
  'Copiar link': {
    es: 'Copiar link',
    en: 'Copy link',
    pt: 'Copiar link',
  },
  Aceptar: {
    es: 'Aceptar',
    en: 'Accept',
    pt: 'Aceitar',
  },
  'Copiado al portapapeles!': {
    es: '¡Copiado al portapapeles!',
    en: 'Copied to clipboard!',
    pt: 'Copiado para a área de transferência!',
  },
  'Editar Idea': {
    es: 'Editar Idea',
    en: 'Edit Idea',
    pt: 'Editar ideia',
  },
  'Borrar Idea': {
    es: 'Borrar Idea',
    en: 'Delete Idea',
    pt: 'Excluir ideia',
  },
  '¿Estás seguro de querer borrar ': {
    es: '¿Estás seguro de querer borrar ',
    en: 'Are you sure you want to delete ',
    pt: 'Tem certeza de que deseja excluir',
  },
  Estadísticas: {
    es: 'Estadísticas',
    en: 'Statistics',
    pt: 'Estatisticas',
  },
  Atrás: {
    es: 'Atrás',
    en: 'Back',
    pt: 'Voltar',
  },
  Siguiente: {
    es: 'Siguiente',
    en: 'Next',
    pt: 'Seguinte',
  },
  'Comienza!': {
    es: '¡Comienza!',
    en: 'Start!',
    pt: 'Comece!',
  },
  'Revisa si tu idea obedece los siguientes requerimientos': {
    es: 'Revisa si tu idea obedece los siguientes requerimientos',
    en: 'Check if your idea complies with the following requirements',
    pt:
      'Verifique se sua ideia está em conformidade com os seguintes requisitos',
  },
  'Ninguna imagen ha sido seleccionada. Presiona Siguiente para continuar.': {
    es:
      'Ninguna imagen ha sido seleccionada. Presiona Siguiente para continuar.',
    en: 'No image has been selected. Press Next to continue.',
    pt: 'Nenhuma imagem foi selecionada. Pressione Avançar para continuar.',
  },
  'Ninguna imagen ha sido seleccionada.': {
    es: 'Ninguna imagen ha sido seleccionada.',
    en: 'No image has been selected. Press Next to continue.',
    pt: 'Nenhuma imagem foi selecionada. Pressione Avançar para continuar.',
  },
  'Tu usuario ya existe. Ponte en contacto con el administrador si estásexperimentando problemas con tu cuenta': {
    es:
      'Este usuario ya existe. Ponte en contacto con el administrador si estás experimentando problemas con tu cuenta.',
    en:
      'This username already exists. Contact the admin if you are experiencing issues with your account.',
    pt:
      'Este nome de usuário já existe. Entre em contato com o administrador se estiver tendo problemas com sua conta.',
  },
  'Checando tu usuario': {
    es: 'Checando tu usuario',
    en: 'Checking your user',
    pt: 'Verificando seu usuário',
  },
  '¡Bienvenido a ': {
    es: '¡Bienvenido a ',
    en: 'Welcome to ',
    pt: 'Bem vindo a',
  },
  '! Vamos a empezar a crear tu perfil.': {
    es: '¡Vamos a empezar a crear tu perfil!',
    en: '. Lets start creating your profile!',
    pt: '. Vamos começar a criar seu perfil!',
  },
  ' Se experimentó un error con tu cuenta. Lamentamos los inconvenientes.': {
    es:
      'Hubo un problema al iniciar sesión con tu cuenta. Lamentamos los inconvenientes.',
    en:
      'There was a problem signing in to your account. We´re sorry for the inconveniences.',
    pt:
      'Ocorreu um problema ao fazer login na sua conta. Lamentamos os inconvenientes.',
  },
  'Configurando Innotek...': {
    es: 'Configurando Innotek...',
    en: 'Setting up Innotek...',
    pt: 'Configurando Innotek ...',
  },
  'Checando el acceso del usuario...': {
    es: 'Checando el acceso del usuario...',
    en: 'Checking user access...',
    pt: 'Verificando o acesso do usuário ...',
  },
  'Subir Imagen': {
    es: 'Subir Imagen',
    en: 'Upload Image',
    pt: 'Carregar imagem',
  },
  'Tomar una Foto': {
    es: 'Tomar una Foto',
    en: 'Take a Picture',
    pt: 'Tire uma foto',
  },
  'El tamaño de la imagen no debe superar los 4MB.': {
    es: 'El tamaño de la imagen no debe superar los 4MB.',
    en: 'Image size cannot exceed 4MB.',
    pt: 'O tamanho da imagem não pode exceder 4 MB.',
  },
  'La imagen debe tener 400 pixeles de ancho.': {
    es: 'La imagen debe tener 400 pixeles de ancho.',
    en: 'The image must be 400 pixels wide.',
    pt: 'A imagem deve ter 400 pixels de largura.',
  },
  'Subiendo tu foto de perfil': {
    es: 'Subiendo tu foto de perfil',
    en: 'Uploading your profile picture',
    pt: 'Carregando sua foto do perfil',
  },
  '¡Listo!': {
    es: '¡Listo!',
    en: 'Ready!',
    pt: 'Pronto!',
  },
  'Creando tu perfil. Ya casi estamos listos...': {
    es: 'Creando tu perfil. Ya casi estamos listos...',
    en: 'Creating your profile. We´re almost ready...',
    pt: 'Criando seu perfil. Estamos quase prontos ...',
  },
  Cargar: {
    es: 'Cargar',
    en: 'Upload',
    pt: 'Carregar',
  },
  'No esta permitido el acceso. Por favor, cierra tu sesión': {
    es: 'No esta permitido el acceso. Por favor, cierra tu sesión ',
    en: 'Access has been denied. Please, sign out ',
    pt: 'O acesso foi negado. Saia',
  },
  '404: No se encontró la página. Da click ': {
    es: '404: No se encontró la página. Da click ',
    en: '404: Page not found. Click ',
    pt: '404: Página não encontrada. Clique em ',
  },
  'No se encontró el usuario. Da click ': {
    es: 'No se encontró el usuario. Da click ',
    en: 'User not found. Click ',
    pt: 'Usuario não encontrada. Clique em ',
  },
  'No se encontró el challenge. Da click ': {
    es: 'No se encontró el challenge. Da click ',
    en: 'Challenge not found. Click ',
    pt: 'Desafio não encontrada. Clique em ',
  },
  'No se encontró la idea. Da click ': {
    es: 'No se encontró la idea. Da click ',
    en: 'Idea not found. Click ',
    pt: 'Ideia não encontrada. Clique em ',
  },
  'aquí ': {
    es: 'aquí ',
    en: 'here ',
    pt: 'Aqui',
  },
  'para regresar': {
    es: 'para regresar',
    en: 'to go back',
    pt: 'voltar',
  },
  'Contraseña cambiada exitósamente': {
    es: '¡Tu contraseña ha sido cambiada exitósamente!',
    en: 'Your password has been changed!',
    pt: 'Sua senha foi alterada!',
  },
  'Cambiar Contraseña': {
    es: 'Cambiar Contraseña',
    en: 'Change Password',
    pt: 'Alterar senha',
  },
  'Regresar a Innotek': {
    es: 'Regresar a Innotek',
    en: 'Go back to Innotek',
    pt: 'Voltar para Innotek',
  },
  '500: Error en Servidor. Da click ': {
    es: '500: Error en Servidor. Da click ',
    en: '500: Server Error. Click ',
    pt: '500: Erro no servidor. Clique em',
  },
  'Seguir Etiqueta': {
    es: 'Seguir Etiqueta',
    en: 'Follow Tag',
    pt: 'Seguir tag',
  },
  'Dejar de Seguir': {
    es: 'Dejar de Seguir',
    en: 'Unfollow',
    pt: 'Deixar de seguir',
  },
  'Mostrando ideas con la etiqueta ': {
    es: 'Mostrando ideas con la etiqueta ',
    en: 'Showing ideas with the tag ',
    pt: 'Mostrando idéias com a tag ',
  },
  'Mostrando ideas de tipo ': {
    es: 'Mostrando ideas de tipo ',
    en: 'Showing ',
    pt: 'Mostrando ',
  },
  Puntos: {
    es: 'Puntos',
    en: 'Points',
    pt: 'Pontos',
  },
  'Club de los 100 Puntos': {
    es: 'Club de los 100 Puntos',
    en: '100 Points Club',
    pt: 'Clube de 100 Pontos',
  },
  'Club de los 500 Puntos': {
    es: 'Club de los 500 Puntos',
    en: '500 Points Club',
    pt: 'Clube de 500 Pontos',
  },
  'Club de los 1000 Puntos': {
    es: 'Club de los 1000 Puntos',
    en: '1000 Points Club',
    pt: '1000 Points Club',
  },
  'Subiendo imagen...': {
    es: 'Subiendo imagen...',
    en: 'Uploading image...',
    pt: 'A carregar imagem ...',
  },
  'Subir Nueva Foto de Perfil': {
    es: 'Subir Nueva Foto de Perfil',
    en: 'Upload New Profile Picture',
    pt: 'Carregar nova foto do perfil',
  },
  'Cerrando Sesión': {
    es: 'Cerrando Sesión',
    en: 'Signing Out',
    pt: 'Sair',
  },
  'No hubo resultados de esta búsqueda': {
    es: 'No hubo resultados de esta búsqueda',
    en: 'No results were found',
    pt: 'Não foram encontrados resultados',
  },
  'Usuario no registrado': {
    es: 'Usuario no registrado',
    en: 'Unregistered user',
    pt: 'Usuário não registrado',
  },
  'Cerrar Sesión': {
    es: 'Cerrar Sesión',
    en: 'Sign out',
    pt: 'Sair',
  },
  'No tienes notificaciones': {
    es: 'No tienes notificaciones',
    en: 'You have no notifications',
    pt: 'Você não tem notificações',
  },
  Evolucionar: {
    es: 'Evolucionar',
    en: 'Evolve',
    pt: 'Evoluir',
  },
  'Quitar Evolución': {
    es: 'Quitar Evolución',
    en: 'Remove Evolution',
    pt: 'Remover evolução',
  },
  DESCARGAR: {
    es: 'DESCARGAR',
    en: 'DOWNLOAD',
    pt: 'Baixar',
  },
  Descargar: {
    es: 'Descargar',
    en: 'Download',
    pt: 'Baixar',
  },
  Perfil: {
    es: 'Perfil',
    en: 'Profile',
    pt: 'Perfil',
  },
  'Enlazar a un reto': {
    es: 'Enlazar a un reto',
    en: 'Link to a Challenge',
    pt: 'Link para um desafio',
  },
  'Selecciona un Reto': {
    es: 'Selecciona un Reto',
    en: 'Select a Challenge',
    pt: 'Selecione um desafio',
  },
  Cancelar: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar',
  },
  Adelante: {
    es: 'Adelante',
    en: 'Next',
    pt: 'Próximo',
  },
  'Evolución de Ideas': {
    es: 'Evolución de Ideas',
    en: 'Idea Evolution',
    pt: 'Evolução da idéia',
  },
  Etapa: {
    es: 'Etapa',
    en: 'Stage',
    pt: 'Estágio',
  },
  'No se han registrados ideas': {
    es: 'No se han registrados ideas',
    en: 'No ideas have been registered',
    pt: 'Nenhuma ideia foi registrada',
  },
  'No se ha encontrado este elemento. Da click ': {
    es: 'No se ha encontrado este elemento. Da click ',
    en: 'This item was not found. Click',
    pt: 'Este item não foi encontrado. Clique',
  },
  Recolección: {
    es: 'Recolección',
    en: 'Pickup',
    pt: 'Coleta',
  },
  Aceleración: {
    es: 'Aceleración',
    en: 'Acceleration',
    pt: 'Aceleração',
  },
  Adopción: {
    es: 'Adopción',
    en: 'Adoption',
    pt: 'Adoção',
  },
  'Requerimientos de las ideas': {
    es: 'Requerimientos de las ideas',
    en: 'Idea Requirements',
    pt: 'Requisitos da ideia',
  },
  'Ratings de ideas': {
    es: 'Ratings de ideas',
    en: 'Idea Ratings',
    pt: 'Classificações de ideias',
  },
  'Preguntas de ideas': {
    es: 'Preguntas de ideas',
    en: 'Idea Questions',
    pt: 'Perguntas da idéia',
  },
  'Agregar requerimiento...': {
    es: 'Agregar requerimiento...',
    en: 'Add requirement',
    pt: 'Adicionar requisito',
  },
  'Este navegador no está soportado.': {
    es: 'Este navegador no está soportado.',
    en: 'This browser is not supported.',
    pt: 'Este navegador não é suportado.',
  },
  'Por favor ingresa desde Google Chrome.': {
    es: 'Por favor ingresa desde Google Chrome o Firefox',
    en: 'Please, use Google Chrome or Firefox.',
    pt: 'Por favor, use o Google Chrome ou Firefox.',
  },
  Bronce: {
    es: 'Bronce',
    en: 'Bronze',
    pt: 'Bronze',
  },
  Plata: {
    es: 'Plata',
    en: 'Silver',
    pt: 'Prata',
  },
  Oro: {
    es: 'Oro',
    en: 'Gold',
    pt: 'Ouro',
  },
  Campaña: {
    es: 'Campaña',
    en: 'Campaign',
    pt: 'Campainha',
  },
  Revisión: {
    es: 'Revisión',
    en: 'Revision',
    pt: 'Revisão',
  },
  Selección: {
    es: 'Selección',
    en: 'Selection',
    pt: 'Seleção',
  },
  Comunicación: {
    es: 'Comunicación',
    en: 'Communication',
    pt: 'Comunicación',
  },
  Innovación: {
    es: 'Innovación',
    en: 'innovation',
    pt: 'inovação',
  },
  'Comparte tus ideas. Descubre Tendencias. Colabora con otros.': {
    es: 'Comparte tus ideas. Descubre Tendencias. Colabora con otros.',
    en: 'Share your ideas. Discover trends. Collaborate with others.',
    pt:
      'Compartilhe suas idéias. Descubra tendências. Colabore com outras pessoas.',
  },
  'Tipo de Colaboración': {
    es: 'Tipo de Colaboración',
    en: 'Collaboration Settings',
    pt: 'Configurações de colaboração',
  },
  'Reto Abierto': {
    es: 'Reto Abierto',
    en: 'Crowdsourced',
    pt: 'Crowdsourced',
  },
  'Selección de Jueces': {
    es: 'Selección de Jueces',
    en: 'Open to Judges',
    pt: 'Aberto a juízes',
  },
  'Agregar Tipo': {
    es: 'Agregar Tipo...',
    en: 'Add Type...',
    pt: 'Adicionar tipo ...',
  },
  '¡Sin Autorización!': {
    es: '¡Sin Autorización!',
    en: 'Unnauthorized!',
    pt: 'Não autorizado!',
  },
  'Desenlazar idea': {
    es: 'Desenlazar idea',
    en: 'Unlink idea',
    pt: 'Desvincular ideia',
  },
  '¿Estás seguro de querer desenlazar ': {
    es: '¿Estás seguro de querer desenlazar ',
    en: 'Are you sure you want to unlink ',
    pt: 'Tem certeza de que deseja desvincular ',
  },
  'Esta idea esta relacionada a ': {
    es: 'Esta idea esta relacionada a ',
    en: 'This idea is related to ',
    pt: 'Essa ideia está relacionada a ',
  },
  'Borrar Challenge': {
    es: 'Borrar Challenge',
    en: 'Delete Challenge',
    pt: 'Excluir desafio',
  },
  Borrar: {
    es: 'Borrar',
    en: 'Delete',
    pt: 'Excluir',
  },
  Discreto: {
    es: 'Discreto',
    en: 'Discreet',
    pt: 'Discreto',
  },
  Rango: {
    es: 'Rango',
    en: 'Rank',
    pt: 'Intervalo',
  },
  Continuo: {
    es: 'Continuo',
    en: 'Continuous',
    pt: 'Contínuo',
  },
  Jueces: {
    es: 'Jueces',
    en: 'Judges',
    pt: 'Juízes',
  },
  'Propuestas Enviadas a este Challenge': {
    es: 'Propuestas Enviadas a este Challenge',
    en: 'Proposals Sent to this Challenge',
    pt: 'Propostas enviadas para este desafio',
  },
  'No se han subido ideas a este Challenge': {
    es: 'No se han subido ideas a este Challenge',
    en: 'No ideas have been uploaded to this Challenge',
    pt: 'Nenhuma idéia foi enviada para este desafio',
  },
  Valoraciones: {
    es: 'Valoraciones',
    en: 'Ratings',
    pt: 'Avaliações',
  },
  'Esta idea está en el reto ': {
    es: 'Esta idea está en el reto ',
    en: 'This idea is in the challenge',
    pt: 'Essa ideia está no desafio',
  },
  'Gracias por tu puntuación!': {
    es: 'Gracias por tu valoración!',
    en: 'Thanks for your rating!',
    pt: 'Obrigado pela sua classificação!',
  },
  'Ver Puntuación': {
    es: 'Ver Valoración',
    en: 'See Rating',
    pt: 'Ver pontuação',
  },
  'Fecha de Inicio': {
    es: 'Fecha de Inicio',
    en: 'Start date',
    pt: 'Data de início',
  },
  'Fecha de Finalización': {
    es: 'Fecha de Finalización',
    en: 'Finish date',
    pt: 'Data de finalização',
  },
  'Permitir que otros usuarios suban sus propuestas a este Challenge': {
    es: 'Permitir que otros usuarios suban sus propuestas a este Challenge',
    en: 'Allow other users to upload their proposals to this Challenge',
    pt: 'Permitir que outros usuários enviem suas propostas para este desafio',
  },
  'Factores de Calidad (Rating)': {
    es: 'Factores de Calidad (Rating)',
    en: 'Quality Factors (Rating)',
    pt: 'Fatores de Qualidade (Classificação)',
  },
  'Agregar factor de calidad...': {
    es: 'Agregar factor de calidad...',
    en: 'Add quality factor ...',
    pt: 'Adicionar fator de qualidade ...',
  },
  'Agregar Descripción (opcional)...': {
    es: 'Agregar Descripción (opcional)...',
    en: 'Add Description (optional) ...',
    pt: 'Adicionar descrição (opcional) ...',
  },
  'Todos los usuarios de Innotek podrán puntuar las ideas que se encuentren en este reto. El reto también será visible en el panel principal.': {
    es:
      'Todos los usuarios de Innotek podrán puntuar las ideas que se encuentren en este reto. El reto también será visible en el panel principal.',
    en:
      'All Innotek users will be able to rate the ideas found in this challenge. The challenge will also be visible on the main panel.',
    pt:
      'Todos os usuários do Innotek poderão avaliar as ideias encontradas neste desafio. O desafio também será visível no painel principal.',
  },
  'Solo usuarios designados podrán puntuar el reto. También será visible el reto para los usuarios seleccionados.': {
    es:
      'Solo usuarios designados podrán puntuar el reto. También será visible el reto para los usuarios seleccionados.',
    en:
      'Only designated users will be able to rate the challenge. The challenge will also be visible to selected users.',
    pt:
      'Somente usuários designados poderão avaliar o desafio. O desafio também será visível para usuários selecionados.',
  },
  'Introduce usuarios buscando los nombres en la barra de búsqueda': {
    es: 'Introduce usuarios buscando los nombres en la barra de búsqueda',
    en: 'Enter users by searching for names in the search bar',
    pt: 'Digite os usuários pesquisando nomes na barra de pesquisa',
  },
  'Buscar Usuarios': {
    es: 'Buscar Usuarios',
    en: 'Search Users',
    pt: 'Pesquisar usuários',
  },
  'Exportar Ideas': {
    es: 'Exportar Ideas',
    en: 'Export Ideas',
    pt: 'Exportar Ideias',
  },
  'Exportar Usuarios': {
    es: 'Exportar Usuarios',
    en: 'Export Users',
    pt: 'Exportar usuários',
  },
  'No hay rúbricas en este apartado': {
    es: 'No hay rúbricas en este apartado',
    en: 'There are no headings in this section',
    pt: 'Não há avaliações nesta seção',
  },
  'Configuración de Rúbrica': {
    es: 'Configuración de Rúbrica',
    en: 'Rubric Configuration',
    pt: 'Configuración de Avaliação',
  },
  Guardar: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar',
  },
  Agregar: {
    es: 'Agregar',
    en: 'Add',
    pt: 'Adicionar',
  },
  '¡Tipos de Ideas Salvados!': {
    es: '¡Tipos de Ideas Salvados!',
    en: 'Idea Types Saved!',
    pt: '¡Tipos de ideias salvos!',
  },
  'Agregar Título de Rúbrica...': {
    es: 'Agregar Título de Rúbrica...',
    en: 'Add Rubric Title ...',
    pt: 'Adicionar título ...',
  },
  'Rango de Rúbrica': {
    es: 'Rango de Rúbrica',
    en: 'Rubric Range',
    pt: 'Intervalo de Avaliação',
  },
  Ideas: {
    es: 'Ideas',
    en: 'Ideas',
    pt: 'Ideias',
  },
  'Ideas Generadas': {
    es: 'Ideas Generadas',
    en: 'Total Ideas',
    pt: 'Total de Ideias',
  },
  'Usuarios Registrados': {
    es: 'Usuarios Registrados',
    en: 'Registered Users',
    pt: 'Registered Users',
  },
  'Distribución de Tipos de Ideas': {
    es: 'Distribución de Tipos de Ideas',
    en: 'Distribution of Idea Types',
    pt: 'Distribução de tipos de idéias',
  },
  'Preguntas adicionales': {
    es: 'Preguntas adicionales',
    en: 'Additional questions',
    pt: 'Preguntas adicionais',
  },
  'Configuración de Evolución': {
    es: 'Configuración de Evolución',
    en: 'Evolution Configuration',
    pt: 'Configurações do Evolution',
  },
  Texto: {
    es: 'Texto',
    en: 'Text',
    pt: 'Texto',
  },
  Numérico: {
    es: 'Numérico',
    en: 'Numeric',
    pt: 'Numérico',
  },
  'Selección Múltiple': {
    es: 'Selección Múltiple',
    en: 'Multiple Selection',
    pt: 'Seleção Múltiple',
  },
  'Agregar Selección': {
    es: 'Agregar Selección',
    en: 'Add Selection',
    pt: 'adicionar seleção',
  },
  Enlazar: {
    es: 'Enlazar',
    en: 'Link',
    pt: 'Link',
  },
  'Preguntas Adicionales': {
    es: 'Preguntas Adicionales',
    en: 'Additional questions',
    pt: 'Perguntas adicionais',
  },
  'Introduce tu respuesta en el siguiente campo': {
    es: 'Introduce tu respuesta en el siguiente campo',
    en: 'Enter your answer in the following field',
    pt: 'Digite sua resposta no campo a seguir',
  },
  'Elige una opción': {
    es: 'Elige una opción',
    en: 'Choose an option',
    pt: 'Escolha uma opção',
  },
  'Factores de Calidad': {
    es: 'Factores de Calidad',
    en: 'Quality Factors',
    pt: 'Fatores de Qualidade',
  },
  Requerimientos: {
    es: 'Requerimientos',
    en: 'Requirements',
    pt: 'Exigências',
  },
  'Fecha de Creación': {
    es: 'Fecha de Creación',
    en: 'Creation Date',
    pt: 'Data de criação',
  },
  'Ideas enlazadas': {
    es: 'Ideas enlazadas',
    en: 'Linked ideas',
    pt: 'Ideias vinculadas',
  },
  'Ver Evolución Del Challenge': {
    es: 'Ver Evolución Del Challenge',
    en: 'Challenge Evolution',
    pt: 'Evolução do Desafio',
  },
  Etapas: {
    es: 'Etapas',
    en: 'Stages',
    pt: 'Etapas',
  },
  'No hay ideas enlazadas a este reto': {
    es: 'No hay ideas enlazadas a este reto',
    en: 'There are no ideas linked to this challenge',
    pt: 'Não há idéias vinculadas a esse desafio',
  },
  'Selecciona una Respuesta': {
    es: 'Selecciona una Respuesta',
    en: 'Select an Answer',
    pt: 'Selecione uma resposta',
  },
  'Sin respuesta': {
    es: 'Sin respuesta',
    en: 'No answer',
    pt: 'Sem resposta',
  },
  'Información Adicional': {
    es: 'Información Adicional',
    en: 'Additional Information',
    pt: 'Informação adicional',
  },
  '¿Cómo podríamos hacer un piloto de esta Innovación?': {
    es: '¿Cómo podríamos hacer un piloto de esta Innovación?',
    en: 'How could we pilot this Innovation?',
    pt: 'Como poderíamos pilotar essa inovação?',
  },
  '¿Cuál es el estimado de inversión?': {
    es: '¿Cuál es el estimado de inversión?',
    en: 'What is the investment estimate?',
    pt: 'Qual é a estimativa de investimento?',
  },
  '¿Cual será el beneficio para Banco Carrefour con esta innovación?': {
    es: '¿Cual será el beneficio para Banco Carrefour con esta innovación?',
    en: 'What will be the benefit for Banco Carrefour with this innovation?',
    pt: 'Qual será o benefício para o Banco Carrefour com essa inovação?',
  },
  '¿Cómo mediremos los resultados de esta innovación?': {
    es: '¿Cómo mediremos los resultados de esta innovación?',
    en: 'How will we measure the results of this innovation?',
    pt: 'Como vamos medir os resultados dessa inovação?',
  },
  Coautores: {
    es: 'Coautores',
    en: 'Co-authors',
    pt: 'Co-autores',
  },
  'Reconocimiento Social': {
    es: 'Reconocimiento Social',
    en: 'Social Recognition',
    pt: 'Reconhecimento social',
  },
  'Evaluación Colaborativa': {
    es: 'Evaluación Colaborativa',
    en: 'Collaborative Evaluation',
    pt: 'Avaliação Colaborativa',
  },
  Desarrollo: {
    es: 'Desarrollo',
    en: 'Development',
    pt: 'Desenvolvimento',
  },
  'Evaluación Final': {
    es: 'Evaluación Final',
    en: 'Final evaluation',
    pt: 'Avaliação final',
  },
  'Pitch e Implementación': {
    es: 'Pitch e Implementación',
    en: 'Pitch and Implementation',
    pt: 'Passo e Implementação',
  },
  'Panel de Evolución Principal': {
    es: 'Panel de Evolución Principal',
    en: 'Main Evolution Panel',
    pt: 'Painel Principal de Evolução',
  },
  'Editar Challenge': {
    es: 'Editar Challenge',
    en: 'Update Challenge',
    pt: 'Atualização de desafio',
  },
  'Podría ser necesario recargar la página para ver los cambios': {
    es: 'Podría ser necesario recargar la página para ver los cambios',
    en: 'It may be necessary to reload the page to see the changes',
    pt: 'Pode ser necessário recarregar a página para ver as alterações',
  },
  'comentó en tu idea': {
    es: 'comentó en tu idea',
    en: 'commented on your idea',
    pt: 'comentou sua ideia',
  },
  'te etiquetó en un comentario:': {
    es: 'te etiquetó en un comentario:',
    en: 'tagged you in a comment:',
    pt: 'marcou você em um comentário:',
  },
  'comenzó a seguirte': {
    es: 'comenzó a seguirte',
    en: 'began to follow you',
    pt: 'começou a seguir você',
  },

  'reaccionó a tu idea': {
    es: 'reaccionó a tu idea',
    en: 'reacted to your idea',
    pt: 'reagiu à sua ideia',
  },

  'ha evolucionado': {
    es: 'ha evolucionado',
    en: 'has evolved',
    pt: 'é evoluído',
  },

  'Tu idea': {
    es: 'Tu idea',
    en: 'Your Idea',
    pt: 'Sua ideia',
  },

  'ha sido enlazada al reto': {
    es: 'ha sido enlazada al reto',
    en: 'has been linked to the challenge',
    pt: 'foi associado ao desafio',
  },

  'ha evolucionado en el reto': {
    es: 'ha evolucionado en el reto',
    en: 'has evolved into the challenge',
    pt: 'evoluiu para o desafio',
  },

  'respondió tu mensaje  en la idea': {
    es: 'respondió tu mensaje  en la idea',
    en: 'answered your message on the idea',
    pt: 'respondeu sua mensagem sobre a ideia',
  },

  'La idea': {
    es: 'La idea',
    en: 'Idea',
    pt: 'A ideia',
  },
  Responder: {
    es: 'Responder',
    en: 'Reply',
    pt: 'Resposta',
  },

  'ha sido reportada como contenido inapropiado': {
    es: 'ha sido reportada como contenido inapropiado',
    en: 'has been reported as inappropriate content',
    pt: 'foi denunciado como conteúdo inapropriado',
  },

  'creó una nueva idea!': {
    es: 'creó una nueva idea!',
    en: 'created a new idea!',
    pt: 'criou uma nova ideia',
  },

  'Has sido designado como juez en el Challenge': {
    es: 'Has sido designado como juez en el Challenge',
    en: 'You have been appointed as a judge in the Challenge',
    pt: 'Você foi nomeado juiz no Desafio',
  },

  'ha sido destacada!': {
    es: 'ha sido destacada!',
    en: 'has been featured!',
    pt: 'foi destaque!',
  },

  '¡Nueva idea personalizada para ti!': {
    es: '¡Nueva idea personalizada para ti!',
    en: 'New personalized idea for you!',
    pt: 'Nova ideia personalizada para você!',
  },

  'Deja tu respuesta...': {
    es: 'Deja tu respuesta...',
    en: 'Leave your answer ...',
    pt: 'Deixe sua resposta ...',
  },

  'Agregar Respuesta': {
    es: 'Agregar Respuesta',
    en: 'Add Reply',
    pt: 'Adicionar resposta',
  },

  privado: {
    es: 'privado',
    en: 'private',
    pt: 'particular',
  },
  público: {
    es: 'público',
    en: 'public',
    pt: 'público',
  },
  'Subiendo Archivos...': {
    es: 'Subiendo Archivos...',
    en: 'Uploading files...',
    pt: 'Fazendo upload de arquivos ...',
  },
  Sugerencias: {
    es: 'Sugerencias',
    en: 'Suggestions',
    pt: 'Sugestões',
  },
  'Deja un comentario o arrastra tus archivos...': {
    es: 'Deja un comentario o arrastra tus archivos...',
    en: 'Leave a comment or drag your files ...',
    pt: 'Deixe um comentário ou arraste seus arquivos ...',
  },
  'Cargar Foto': {
    es: 'Cargar Foto',
    en: 'Upload Photo',
    pt: 'Carregar foto',
  },
  Otro: {
    es: 'Otro',
    en: 'Other',
    pt: 'Outros',
  },
  Reportar: {
    es: 'Reportar',
    en: 'Report',
    pt: 'Relatório',
  },
  'Marcar como contenido inapropiado': {
    es: 'Marcar como contenido inapropiado',
    en: 'Flag inappropriate content',
    pt: 'Sinalizar conteúdo impróprio',
  },
  'Se ha subido tu respuesta. Los administradores analizarán este caso y determinarán acciones para mejorar tu experiencia.': {
    es:
      'Se ha subido tu respuesta. Los administradores analizarán este caso y determinarán acciones para mejorar tu experiencia.',
    en:
      'Your answer has been uploaded. Administrators will analyze this case and determine actions to improve your experience.',
    pt:
      'Sua resposta foi enviada. Os administradores analisarão esse caso e determinarão ações para melhorar sua experiência.',
  },
  'Elige la razón para marcar este contenido como inapropiado.': {
    es: 'Elige la razón para marcar este contenido como inapropiado.',
    en: 'Choose the reason to mark this content as inappropriate.',
    pt: 'Escolha o motivo para marcar este conteúdo como impróprio.',
  },
  Violencia: {
    es: 'Violencia',
    en: 'Violence',
    pt: 'Violência',
  },
  Discriminación: {
    es: 'Discriminación',
    en: 'Discrimination',
    pt: 'Discriminação',
  },
  'Pone en Riesgo los Valores de la Empresa': {
    es: 'Pone en Riesgo los Valores de la Empresa',
    en: 'Puts Business Values at Risk',
    pt: 'Coloca em risco os valores da empresa',
  },
  'Uso Inadecuado de la Plataforma': {
    es: 'Uso Inadecuado de la Plataforma',
    en: 'Improper Uitlization of the Platform',
    pt: 'Uso impróprio da plataforma',
  },
  'Explica la razón': {
    es: 'Explica la razón',
    en: 'Explain the reason',
    pt: 'Explique o motivo',
  },
  Cerrar: {
    es: 'Cerrar',
    en: 'Close',
    pt: 'Fechar',
  },
  'Marcar contenido': {
    es: 'Marcar contenido',
    en: 'Flag Content',
    pt: 'reportar conteúdo',
  },
  'Este contenido ha sido marcado como inapropiado por la siguiente razón': {
    es:
      'Este contenido ha sido marcado como inapropiado por la siguiente razón',
    en:
      'This content has been marked as inappropriate for the following reason',
    pt: 'Este conteúdo foi marcado como impróprio pelo seguinte motivo',
  },
  'No es inapropiado': {
    es: 'No es inapropiado',
    en: 'It is not inappropriate',
    pt: 'Não é inapropriado',
  },
  'Tomar acción': {
    es: 'Tomar acción',
    en: 'Take action',
    pt: 'Tome uma atitude',
  },
  '¡Puntos salvados!': {
    es: '¡Puntos salvados!',
    en: 'Points Saved!',
    pt: 'Pontos economizados!',
  },
  Comentario: {
    es: 'Comentario',
    en: 'Comment',
    pt: 'Comente',
  },
  'Creación de Idea': {
    es: 'Creación de Idea',
    en: 'Idea Creation',
    pt: 'Criação de Idéias',
  },
  'Evolución de Idea': {
    es: 'Evolución de Idea',
    en: 'Idea Evolution',
    pt: 'Evolução da idéia',
  },
  Reacción: {
    es: 'Reacción',
    en: 'Reaction',
    pt: 'Reação',
  },
  'Responder a un Challenge': {
    es: 'Responder a un Challenge',
    en: 'Respond To Challenge',
    pt: 'Responder ao desafio',
  },
  'Destacar Idea': {
    es: 'Destacar Idea',
    en: 'Featured Idea',
    pt: 'Ideia em destaque',
  },
  'Podría existir una superposición de valores en esta rúbrica': {
    es: 'Podría existir una superposición de valores en esta rúbrica',
    en: 'There could be an overlap of values in this rubric',
    pt: 'Pode haver uma sobreposição de valores nessa avaliação',
  },
  'Editar Rúbrica': {
    es: 'Editar Rúbrica',
    en: 'Edit Rubric',
    pt: 'Editar avaliação',
  },
  'Para obtener una puntuación es necesario responder preguntas que vinculen nuestra idea con el reto. El reto puede lograr establecer mediciones importantes cuando se formulan las preguntas apropiadas.': {
    es: `Para obtener una puntuación es necesario responder preguntas que vinculen nuestra idea con el reto. El reto puede lograr establecer mediciones importantes cuando se formulan las preguntas apropiadas.`,
    en:
      'To obtain a score, it is necessary to answer questions that link our idea with the challenge. The challenge can be to establish important measurements when the appropriate questions are asked.',
    pt:
      'Para obter uma pontuação, é necessário responder perguntas que vinculam nossa ideia ao desafio. O desafio pode ser estabelecer medidas importantes quando as perguntas apropriadas forem feitas.',
  },
  'Las ideas pueden ser puntuadas dependiendo de las preguntas que se vayan a realizar. Es importante asignar una puntuación correcta para obtener los beneficios medibles. Las puntuaciones tiene como mínimo 1 y máximo 10.': {
    es:
      'Las ideas pueden ser puntuadas dependiendo de las preguntas que se vayan a realizar. Es importante asignar una puntuación correcta para obtener los beneficios medibles. Las puntuaciones tiene como mínimo 1 y máximo 10.',
    en:
      'Ideas can be scored depending on the questions to be asked. Assigning a correct score is important to obtain measurable benefits. The scores have a minimum of 1 and a maximum of 10.',
    pt:
      'As ideias podem ser pontuadas dependendo das perguntas a serem feitas. Atribuir uma pontuação correta é importante para obter benefícios mensuráveis. As pontuações têm no mínimo 1 e no máximo 10.',
  },
  'Para que los retos se puedan enlazar, es necesario contar con una serie de requisitos que puedan describir los objetivos principales. Cuando creamos un reto, es importante reconocer y catalogar estas ideas para saber si entran dentro de la dinámica del reto que se vaya a fabricar.': {
    es:
      'Para que los retos se puedan enlazar, es necesario contar con una serie de requisitos que puedan describir los objetivos principales. Cuando creamos un reto, es importante reconocer y catalogar estas ideas para saber si entran dentro de la dinámica del reto que se vaya a fabricar.',
    en:
      'In order for the challenges to be linked, it is necessary to have a series of requirements that can describe the main objectives. When we create a challenge, it is important to recognize and catalog these ideas to find out if they fall within the dynamics of the challenge to be manufactured.',
    pt:
      'Para que os desafios sejam vinculados, é necessário ter uma série de requisitos que possam descrever os principais objetivos. Quando criamos um desafio, é importante reconhecer e catalogar essas idéias para descobrir se elas se enquadram na dinâmica do desafio a ser fabricado.',
  },
  'Al agregar una idea al reto, el usuario tendrá que contestar algunas preguntas que centran las características específicas que hacen viable la utilización de esta idea en el challenge.': {
    es:
      'Al agregar una idea al reto, el usuario tendrá que contestar algunas preguntas que centran las características específicas que hacen viable la utilización de esta idea en el challenge.',
    en:
      'When adding an idea to the challenge, the user will have to answer some questions that focus on the specific characteristics that make the use of this idea viable in the challenge.',
    pt:
      'Ao adicionar uma ideia ao desafio, o usuário terá que responder a algumas perguntas que enfocam as características específicas que tornam viável o uso dessa ideia no desafio.',
  },
  Bajar: {
    es: 'Bajar',
    en: 'Move Down',
    pt: 'Descer',
  },
  Subir: {
    es: 'Subir',
    en: 'Move Up',
    pt: 'Subir',
  },
  'Ver Rúbrica': {
    es: 'Ver Rúbrica',
    en: 'See Rubric',
    pt: 'Ver avaliação',
  },
  '¡Ayúdanos a puntuarla!': {
    es: '¡Ayúdanos a puntuarla!',
    en: 'Help us rating it!',
    pt: 'Ajude-nos a avaliar!',
  },
  'Rúbrica de Evaluación': {
    es: 'Rúbrica de Evaluación',
    en: 'Evaluation Rubric',
    pt: 'Avaliação',
  },
  Título: {
    es: 'Título',
    en: 'Title',
    pt: 'Título',
  },
  Descripción: {
    es: 'Descripción',
    en: 'Description',
    pt: 'Descrição',
  },
  '¡Completa tu perfil!': {
    es: 'Completa tu perfil',
    en: 'Complete your profile',
    pt: 'termine seu perfil',
  },
  'Agregar Etiqueta': {
    es: 'Agregar Etiqueta',
    en: 'Add Admin Tags',
    pt: 'Adicionar etiqueta',
  },
  'Ocultar Editor de Etiquetas': {
    es: 'Ocultar Editor de Etiquetas',
    en: 'Hide Tag Editor',
    pt: 'Ocultar editor de tags',
  },
  'Subir etiquetas de administrador': {
    es: 'Subir etiquetas de administrador',
    en: 'Upload Admin Tags',
    pt: 'Subir etiquetas de administrador',
  },
  'Retos Activos en': {
    es: 'Retos Activos en',
    en: 'Active Challenges in ',
    pt: 'Desafios ativos em',
  },
  'Actualizar Perfil': {
    es: 'Actualizar Perfil',
    en: 'Update Profile',
    pt: 'Atualizar perfil',
  },
  'Foto de perfil': {
    es: 'Foto de perfil',
    en: 'Profile Photo',
    pt: 'Imagem de perfil',
  },
  'Introduce tu Sede...': {
    es: 'Introduce tu Sede...',
    en: 'Work Location...',
    pt: 'Entre na sua sede ...',
  },
  'Introduce tu Departamento...': {
    es: 'Introduce tu Departamento...',
    en: 'Department...',
    pt: 'Departamento ...',
  },
  'Introduce tu Cargo u Ocupación...': {
    es: 'Introduce tu Cargo u Ocupación...',
    en: 'Role or Occupation...',
    pt: 'Digite seu título ou profissão ...',
  },
  'Ver Reacciones': {
    es: 'Ver Reacciones',
    en: 'Open Reactions',
    pt: 'Ver reações',
  },
  'Ideas en productivo': {
    es: 'Ideas en productivo',
    en: 'Ideas in production',
    pt: 'Idéias em produção',
  },
  'Ideas Productivas': {
    es: 'Ideas Productivas',
    en: 'Productive Ideas',
    pt: 'Idéias Produtivas',
  },
  'Potencial de RPA': {
    es: 'Potencial de RPA',
    en: 'RPA Potential',
    pt: 'Potencial RPA',
  },
  'Impacto de negocios': {
    es: 'Impacto de negocios',
    en: 'Business Impact',
    pt: 'Impacto nos negócios',
  },
  'Ejecución del proceso': {
    es: 'Ejecución del proceso',
    en: 'Execution of the process',
    pt: 'Execução do processo',
  },
  Responsables: {
    es: 'Responsables',
    en: 'Responsible',
    pt: 'Responsável',
  },
  'Proyecto total': {
    es: 'Proyecto total',
    en: 'Total Project',
    pt: 'Projeto Total',
  },
  'Proyecto de técnico': {
    es: 'Proyecto de técnico',
    en: 'Project by Technician',
    pt: 'Projeto de Técnico',
  },
  'Estado del proyecto': {
    es: 'Estado del proyecto',
    en: 'Project Status',
    pt: 'Status do projeto',
  },
  'Proyectos de alta prioridad': {
    es: 'Proyectos de alta prioridad',
    en: 'High Priority Projects',
    pt: 'Projetos de alta prioridade',
  },
  'Complejidad de alta tecnología': {
    es: 'Complejidad de alta tecnología',
    en: 'High Tech Complexity',
    pt: 'Complexidade de alta tecnologia',
  },
  Departamento: {
    es: 'Departamento...',
    en: 'Department...',
    pt: 'Departamento ...',
  },
  'Tipo de Tienda': {
    es: 'Tipo de Tienda',
    en: 'Shop',
    pt: 'Loja',
  },
  'Numero de Tienda': {
    es: 'Numero de Tienda',
    en: 'Nº Shop',
    pt: 'Número da loja',
  },
  Tienda: {
    es: 'Tienda',
    en: 'Shop',
    pt: 'Loja',
  },
  NºTienda: {
    es: 'NºTienda',
    en: 'NºShop',
    pt: 'Nºloja',
  },
  Cargo: {
    es: 'Cargo u Ocupación...',
    en: 'Role or Occupation...',
    pt: 'Título ou profissão ...',
  },
  'Idea en colaboración': {
    es: 'Idea en colaboración',
    en: 'Idea in collaboration',
    pt: 'Ideia em colaboração',
  },
  'Registro de idea': {
    es: 'Registro de idea',
    en: 'Idea registration',
    pt: 'Registro de ideias',
  },
  'Registro de idea en challenge': {
    es: 'Registro de idea en challenge',
    en: 'Idea registration in challenge',
    pt: 'Registro de ideia no desafio',
  },
  'Ideas Similares': {
    es: 'Ideas Similares',
    en: 'Similar Ideas',
    pt: 'Ideias semelhantes',
  },
  'Comentarios semanales': {
    es: 'Comentarios semanales',
    en: 'Weekly comments',
    pt: 'Comentários semanais',
  },
  'Usuarios Nuevos': {
    es: 'Usuarios Nuevos',
    en: 'New Users',
    pt: 'Novos usuários',
  },
  'Usuarios con mayor número de ideas': {
    es: 'Usuarios con mayor número de ideas',
    en: 'Users with the highest number of ideas',
    pt: 'Usuários com o maior número de ideias',
  },
  'Distribución de Ideas por Usuario': {
    es: 'Distribución de Ideas por Usuario',
    en: 'Distribution of Ideas by User',
    pt: 'Distribuição de ideias por usuário',
  },
  'Reacciones semanales': {
    es: 'Reacciones semanales',
    en: 'Weekly reactions',
    pt: 'reações semanais',
  },
  'Reacciones:': {
    es: 'Reacciones:',
    en: 'Reactions:',
    pt: 'Reações:',
  },
  'Me encanta': {
    es: 'Me encanta',
    en: 'Loves',
    pt: 'Me encanta',
  },
  'Me gusta': {
    es: 'Me gusta',
    en: 'Likes',
    pt: 'Eu gosto',
  },
  'Usuarios que han publicado sus reacciones': {
    es: 'Usuarios que han publicado sus reacciones',
    en: 'Users who have posted their reactions',
    pt: 'Usuários que postaram suas reações',
  },
  'Usuarios que han publicado sus ideas en Innotek': {
    es: 'Usuarios que han publicado sus ideas en Innotek',
    en: 'Users who have published their ideas in Innotek',
    pt: 'Usuários que publicaram suas ideias no Innotek',
  },
  Replicable: {
    es: 'Replicable',
    en: 'Replicable',
    pt: 'Replicable',
  },
  'No Replicable': {
    es: 'No Replicable',
    en: 'No Replicable',
    pt: 'No Replicable',
  },

  YES: {
    es: 'SI',
    en: 'YES',
    pt: 'SIM',
  },
  NO: {
    es: 'NO',
    en: 'NO',
    pt: 'NO',
  },
  'License Required?': {
    es: '¿Se requiere licencia?',
    en: 'License Required?',
    pt: 'Licença necessária?',
  },
  'Add the Repository Code (URL)': {
    es: 'Agregue el código del repositorio (URL)',
    en: 'Add the Repository Code (URL)',
    pt: 'Adicione o código do repositório (URL)',
  },
  'Repository Code (URL)': {
    es: 'Código de repositorio (URL)',
    en: 'Repository Code (URL)',
    pt: 'Código do repositório (URL)',
  },
  'Platform Used': {
    es: 'Plataforma utilizada',
    en: 'Platform Used',
    pt: 'Plataforma usada',
  },
  'Add the platform used': {
    es: 'Añade la plataforma utilizada',
    en: 'Add the platform used',
    pt: 'Adicione a plataforma usada',
  },
  Service: {
    es: 'Servicio',
    en: 'Service',
    pt: 'Serviço',
  },
  'Add the service': {
    es: 'Agregar el servicio',
    en: 'Add the service',
    pt: 'Adicione o serviço',
  },
  Industry: {
    es: 'Industria',
    en: 'Industry',
    pt: 'Indústria',
  },
  'Add the Industry': {
    es: 'Agregar la industria',
    en: 'Add the Industry',
    pt: 'Adicione a indústria',
  },
  Account: {
    es: 'Cuenta',
    en: 'Account',
    pt: 'Conta',
  },
  'Add the account': {
    es: 'Agregar la cuenta',
    en: 'Add the account',
    pt: 'Adicione a conta',
  },
  Responsible: {
    es: 'Responsable',
    en: 'Responsible',
    pt: 'Responsável',
  },
  'Add the Responsible': {
    es: 'Agregar el Responsable',
    en: 'Add the Responsible',
    pt: 'Adicionar o Responsável',
  },
  Proprietary: {
    es: 'Propietario',
    en: 'Proprietary',
    pt: 'Proprietário',
  },
  'Add the Proprietary': {
    es: 'Agregar propietario',
    en: 'Add the Proprietary',
    pt: 'Adicione o proprietário',
  },
  'Replicable?': {
    es: '¿Replicable?',
    en: 'Replicable?',
    pt: 'Replicável?',
  },
  Market: {
    es: 'Mercado',
    en: 'Market',
    pt: 'Mercado',
  },
  'Select the Market': {
    es: 'Seleccione el mercador',
    en: 'Select the Market',
    pt: 'Selecione o mercador',
  },
  Complexity: {
    es: 'Complejidad',
    en: 'Complexity',
    pt: 'Complexidade',
  },
  High: {
    es: 'Alto',
    en: 'High',
    pt: 'Alto',
  },
  Medium: {
    es: 'Medio',
    en: 'Medium',
    pt: 'Médio',
  },
  Low: {
    es: 'Bajo',
    en: 'Low',
    pt: 'Baixo',
  },
  'Select the complexity': {
    es: 'Selecciona la complejidad',
    en: 'Select the complexity',
    pt: 'Selecione a complexidade',
  },
  'Automation Type': {
    es: 'Tipo de automatización',
    en: 'Automation Type',
    pt: 'Tipo de automação',
  },
  'Select the Automation Type': {
    es: 'Seleccione el tipo de automatización',
    en: 'Select the Automation Type',
    pt: 'Selecione o tipo de automação',
  },
  'Repository Code': {
    es: 'Código del repositorio',
    en: 'Repository Code',
    pt: 'Código do Repositório',
  },
  'License Required': {
    es: 'Licencia requerida',
    en: 'License Required',
    pt: 'Licença necessária',
  },
  'Actualizar GitHub': {
    es: 'Actualizar GitHub',
    en: 'Update GitHub',
    pt: 'Atualizar GitHub',
  },
  'Comment message': {
    es: 'Mensaje del comentario',
    en: 'Comment message',
    pt: 'Mensagem de comentário',
  },
  'GitHub message': {
    es: 'Mensaje de GitHub',
    en: 'GitHub message',
    pt: 'Mensagem do GitHub',
  },
  'Descargar repositorio': {
    es: 'Descargar repositorio',
    en: 'Download repository',
    pt: 'Repositório de descarregamento',
  },
  'Clonar repositorio': {
    es: 'Clonar repositorio',
    en: 'Clone repository',
    pt: 'Clonar o repositório',
  },

  //Softtek Automation
  'Comparte tus assets.': {
    es: 'Comparte tus assets. Descubre Tendencias. Colabora con otros.',
    en: 'Share your assets. Discover trends. Collaborate with others.',
    pt:
      'Compartilhe suas assets. Descubra tendências. Colabore com outras pessoas.',
  },
  'Tipos de Assets': {
    es: 'Tipos de Assets',
    en: 'Types of Assets',
    pt: 'Tipos de Assets',
  },
  '¡Tipos de Assets Salvados!': {
    es: '¡Tipos de Assets Salvados!',
    en: 'Assets Types Saved!',
    pt: '¡Tipos de assets salvos!',
  },
  'Distribución de Tipos de Assets': {
    es: 'Distribución de Tipos de Assets',
    en: 'Distribution of Assets Types',
    pt: 'Distribução de tipos de assets',
  },
  Assets: {
    es: 'Assets',
    en: 'Assets',
    pt: 'Assets',
  },
  'Editar Assets': {
    es: 'Editar Assets',
    en: 'Edit Assets',
    pt: 'Editar assets',
  },
  'Borrar Assets': {
    es: 'Borrar Assets',
    en: 'Delete Assets',
    pt: 'Excluir assets',
  },
  'Evolución de Assets': {
    es: 'Evolución de Assets',
    en: 'Assets Evolution',
    pt: 'Evolução da assets',
  },
  'No se han registrados assets': {
    es: 'No se han registrados assets',
    en: 'No assets have been registered',
    pt: 'Nenhuma assets foi registrada',
  },
  'Requerimientos de assets': {
    es: 'Requerimientos de assets',
    en: 'Assets Requirements',
    pt: 'Requisitos da assets',
  },
  'Ratings de assets': {
    es: 'Ratings de assets',
    en: 'Assets Ratings',
    pt: 'Classificações de assets',
  },
  'Para obtener una puntuación es necesario responder preguntas que vinculen nuestra assets con el reto...': {
    es: `Para obtener una puntuación es necesario responder preguntas que vinculen nuestra assets con el reto. El reto puede lograr establecer mediciones importantes cuando se formulan las preguntas apropiadas.`,
    en:
      'To obtain a score, it is necessary to answer questions that link our assets with the challenge. The challenge can be to establish important measurements when the appropriate questions are asked.',
    pt:
      'Para obter uma pontuação, é necessário responder perguntas que vinculam nossa assets ao desafio. O desafio pode ser estabelecer medidas importantes quando as perguntas apropriadas forem feitas.',
  },
  'Las assets pueden ser puntuadas dependiendo de...': {
    es:
      'Las assets pueden ser puntuadas dependiendo de las preguntas que se vayan a realizar. Es importante asignar una puntuación correcta para obtener los beneficios medibles. Las puntuaciones tiene como mínimo 1 y máximo 10.',
    en:
      'Assets can be scored depending on the questions to be asked. Assigning a correct score is important to obtain measurable benefits. The scores have a minimum of 1 and a maximum of 10.',
    pt:
      'As assets podem ser pontuadas dependendo das perguntas a serem feitas. Atribuir uma pontuação correta é importante para obter benefícios mensuráveis. As pontuações têm no mínimo 1 e no máximo 10.',
  },
  'Para que los retos se puedan enlazar assets...': {
    es:
      'Para que los retos se puedan enlazar, es necesario contar con una serie de requisitos que puedan describir los objetivos principales. Cuando creamos un reto, es importante reconocer y catalogar estas assets para saber si entran dentro de la dinámica del reto que se vaya a fabricar.',
    en:
      'In order for the challenges to be linked, it is necessary to have a series of requirements that can describe the main objectives. When we create a challenge, it is important to recognize and catalog these assets to find out if they fall within the dynamics of the challenge to be manufactured.',
    pt:
      'Para que os desafios sejam vinculados, é necessário ter uma série de requisitos que possam descrever os principais objetivos. Quando criamos um desafio, é importante reconhecer e catalogar essas assets para descobrir se elas se enquadram na dinâmica do desafio a ser fabricado.',
  },
  'Al agregar un assets al reto, el usuario tendrá que...': {
    es:
      'Al agregar un assets al reto, el usuario tendrá que contestar algunas preguntas que centran las características específicas que hacen viable la utilización de este assets en el challenge.',
    en:
      'When adding an assets to the challenge, the user will have to answer some questions that focus on the specific characteristics that make the use of this assets viable in the challenge.',
    pt:
      'Ao adicionar um assets ao desafio, o usuário terá que responder a algumas perguntas que enfocam as características específicas que tornam viável o uso dessa assets no desafio.',
  },
  'Creación de Assets': {
    es: 'Creación de Assets',
    en: 'Assets Creation',
    pt: 'Criação de Assets',
  },
  'Destacar Asstes': {
    es: 'Destacar Asstes',
    en: 'Featured Asstes',
    pt: 'Asstes em destaque',
  },
  'Assets Generadas': {
    es: 'Assets Generadas',
    en: 'Total Assets',
    pt: 'Total de Assets',
  },
  'Exportar Assets': {
    es: 'Exportar Assets',
    en: 'Export Assets',
    pt: 'Exportar Assets',
  },
}
