import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { useStyles } from './styles'

interface Props {
  middle: React.FC
  sidebar: React.FC
}

const ThreeColumLayout: React.FC<Props> = ({
  middle: Middle,
  sidebar: Side,
}) => {
  const classes = useStyles()
  return (
    <div style={{ overflow: 'hidden' }} className={classes.root_grid}>
      {/* Middle Desktop */}
      <Grid container spacing={4} style={{ minHeight: '100vh' }}>
        <Grid className={classes.grid} item xs={12} sm={12} md={9} lg={9}>
          <Middle />
        </Grid>
        {/* SideBar */}
        <Hidden smDown>
          <Grid
            className={classes.grid}
            item
            sm={3}
            style={{ backgroundColor: '#ECEDF1', padding: '8px' }}>
            <Side />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

export default ThreeColumLayout
