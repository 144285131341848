import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Drawer, Divider } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import UserIcon from '../UserIcon/UserIcon'
import NewIdeaDialog from '../../../../components/NewIdea/NewIdeaDialog'
import Logo from '../Logo'
import Sidebar from '../../Sidebar'
import Notifications from '../Notifications'
import { SearchTopBar } from '../../../../components/Search'
import { useStyles } from './styles'

interface NavBarProps {}

// Mobile Top bar
const MobileTopBar: React.FC<NavBarProps> = () => {
  const classes = useStyles()

  return (
    <Grid style={{ height: '100%' }} container alignContent="center">
      <Grid item xs={12}>
        <Top classes={classes} />
      </Grid>
      <Grid item xs={12} style={{ padding: '0px 16px' }}>
        <Bottom />
      </Grid>
    </Grid>
  )
}

// Mobile Top
const Top = ({ classes }) => (
  <Grid
    container
    justify="space-between"
    alignContent="center"
    className={classes.fullHeight}>
    <Grid item>
      <Grid
        className={classes.fullHeight}
        container
        alignContent="center"
        alignItems="center"
        justify="flex-start">
        <Logo />
      </Grid>
    </Grid>
    <Grid item>
      <Icons classes={classes} />
    </Grid>
  </Grid>
)

// Bottom Mobile Bar Icons
const Bottom = () => {
  const [open, setOpen] = React.useState(false)
  const popstateListener = href => {
    setOpen(false)
  }
  const classes = useStyles()
  useEffect(() => {
    window.addEventListener('hashchange', popstateListener, false)
    return () => {
      window.removeEventListener('hashchange', popstateListener, false)
    }
  }, [])
  return (
    <Grid
      container
      direction="row"
      style={{ width: '100%' }}
      justify="space-between">
      <SearchTopBar />
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        edge="end"
        className={classes.icon}
        onClick={() => setOpen(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="top"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div
          style={{ margin: 0, padding: 0 }}
          onClick={() => {
            setOpen(false)
          }}>
          <Sidebar />
        </div>
      </Drawer>
      <NewIdeaDialog />
    </Grid>
  )
}

// Navbar Icons
const Icons = ({ classes }) => (
  <Grid
    container
    justify="flex-end"
    alignContent="center"
    className={classes.fullHeight}>
    <Notifications />
    <UserIcon />
  </Grid>
)

export default MobileTopBar
