import React, { useState } from 'react'
import { Idea, LinkIdeaComponent, DeleteChallengeComponent, UnlinkIdeaComponent } from '../../../../generated/graphql'
import { CircularProgress, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, MenuItem, ListItemIcon, withMobileDialog } from '@material-ui/core'
import { _i } from '../../../../Utils/lang/i18n'
import LinkOffIcon from '@material-ui/icons/LinkOff';

interface Props {
  idea: Idea
  fullScreen: boolean
}
const UnlinkIdea: React.FC<Props> = ({ idea, fullScreen }) => {
  const [open, setOpen] = useState(false)
  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
  }
  return (
    <UnlinkIdeaComponent onCompleted={() => location.reload()}>
      {(mutation, { loading }) => {
        const unlinkIdea = () => {
          mutation({
            variables: {
              challengeId: idea.challenge.id,
              ideaId: idea.id,
            }
          })
        }
        if (loading) {
          return (
            <CircularProgress size={24} style={{ marginTop: '10px', marginLeft: '16px' }} />
          )
        }

        return (
          <>
            {true ?
              <MenuItem aria-label="unlink idea "
                onClick={() => {
                  setOpen(true)
                }} style={{
                  fontSize: '16px',
                  padding: '10px 16px'
                }}>
                <ListItemIcon>
                  <LinkOffIcon />
                </ListItemIcon>
                {_i("Desenlazar idea")}
              </MenuItem>
              :
              <Tooltip title={_i("Desenlazar idea")}>
                <IconButton
                  aria-label="unlink idea "
                  onClick={() => {
                    setOpen(true)
                  }}>
                  <LinkOffIcon />
                </IconButton>
              </Tooltip>
            }

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title">
              <DialogTitle style={{ fontFamily: 'InnoArial' }} id="draggable-dialog-title">
                {_i('Desenlazar Idea')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {_i('¿Estás seguro de querer desenlazar ')}{`"${idea.name}"`} ?
          </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {_i('Cancelar')}
                </Button>
                <DeleteChallengeComponent>
                  {deleteChallenge => (
                    <Button
                      onClick={unlinkIdea}
                      color="primary">
                      {_i('Desenlazar')}
                    </Button>
                  )}
                </DeleteChallengeComponent>
              </DialogActions>
            </Dialog>
          </>
        )
      }}
    </UnlinkIdeaComponent>
  )
}
export default withMobileDialog({ breakpoint: 'sm' })(UnlinkIdea) 