// import palette from '../palette'

export default {
  root: {
    backgroundColor: '#ECEDF1',
    border: 'none',
    borderRadius: '10px',
    padding: '5px 7px',
    fontSize: '14px',
    color: '#727272',
  },
}
