import React from 'react'
import { useStyles } from './styles'
import PeopleIcon from '@material-ui/icons/People'
import WorkIcon from '@material-ui/icons/Work'
import StyleIcon from '@material-ui/icons/Style'
import WidgetsIcon from '@material-ui/icons/Widgets'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import BugReportIcon from '@material-ui/icons/BugReport'
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
interface Props {
  title: string
  color?: string
}

//depending the type text, show a different icon
// only used with the "seatch by idea type" box

// these are some common titles
const technology_list = ['technology', 'tool']
const associate_list = ['experiencia del socio']
const productivity_list = ['productividad y ahorros']
const sell_list = ['ventas']
const strategy_list = ['prioridades estratégicas', 'application']
const sandbox_list = ['sandbox']
const innovation_list = ['innovation', 'automation']
const TypeIcon: React.FC<Props> = ({ title, color }) => {
  // @ts-ignore
  const classes = useStyles()
  if (associate_list.indexOf(title) >= 0) {
    return <PeopleIcon />
  } else if (productivity_list.indexOf(title) >= 0) {
    return <WorkIcon />
  } else if (sell_list.indexOf(title) >= 0) {
    return <LoyaltyIcon />
  } else if (strategy_list.indexOf(title) >= 0) {
    return <WidgetsIcon />
  } else if (technology_list.indexOf(title) >= 0) {
    return <LaptopMacIcon />
  } else if (sandbox_list.indexOf(title) >= 0) {
    return <BugReportIcon />
  } else if (innovation_list.indexOf(title) >= 0) {
    return <DeveloperBoardIcon />
  } else {
    return <StyleIcon />
  }
}

export default TypeIcon
