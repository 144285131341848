import React, { useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme: any) => ({
  hidden: {
    height: '1px',
    width: '1px',
    overflow: 'hidden',
  },
  show: {
    width: 'auto',
    height: 'auto',
    margin: 0,
    padding: 0,
  },
  loading: {
    display: 'block',
    margin: '0 auto',
    marginBottom: '20px',
  },
  center: {
    position: 'relative' as 'relative',
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
interface PhotoLoaderProps {
  onLoad: any
  src: string
  imgClass: any
  setLoader: boolean
  alt: string
}

const PhotoLoader: React.FC<PhotoLoaderProps> = ({
  onLoad,
  src,
  imgClass,
  setLoader,
  alt,
}) => {
  const [load, setLoad] = useState(false)
  // @ts-ignore
  const classes = useStyles()
  //   const { totalLoadImg, dispatch } = useContext(LoadingContext)
  return (
    <React.Fragment>
      <div className={!load ? classes.hidden : classes.show}>
        {/* This gets shown when the full res image is finally loaded */}
        <img
          src={src}
          alt={alt}
          onLoad={() => {
            setLoad(true)
            onLoad()
          }}
          onError={() => {
            onLoad()
          }}
          className={imgClass}
        />
      </div>
      {setLoader && !load ? (
        <div className={classes.center}>
          <CircularProgress
            className={classes.loading}
            size={40}
            color="primary"
          />
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default PhotoLoader
