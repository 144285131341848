//File  List Context Declaration
import React, { useReducer } from 'react'

const initState = { tagList: [], dispatch: null }

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    //if reducer action addition then sum up the counter and add
    case 'add':
      //eslint-disable-next-line
      const value_temp = {
        value: action.tag,
      }
      return { ...state, tagList: [...state.tagList, value_temp] }

    // if action is removal, then find the id and remove it
    case 'remove':
      // dummy object to remove the specified id
      //eslint-disable-next-line
      const tagList = Object.assign([], state.tagList)
      tagList.splice(action.id, 1)
      return {
        tagList: [...tagList],
      }

    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const TagContext = React.createContext(initState)
//Provider Init
function TagProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <TagContext.Provider value={{ tagList: state.tagList, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </TagContext.Provider>
  )
}
//Export conext and provider
export { TagContext, TagProvider }
