import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { getInstanceLayout } from '../../../Utils/getInstanceLayout'
import { _i } from '../../../Utils/lang/i18n'
import { styles } from './styles'
import { mergeClassNames } from '../../../Utils/textUtils'

interface Props {
  onClick: any
  classes: any
}

const InnoButton: React.FC<Props> = ({ classes, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      size="medium"
      style={{
        fontFamily: 'InnoArial',
      }}
      className={getInstanceLayout({
        heb: classes.HEB_custom_button,
        softtek: classes.button,
        abanca: classes.ABANCA_custom_button,
        caixabank: classes.caixaBank_custom_button,
      })}>
      <AddIcon
        className={mergeClassNames(classes.leftIcon, classes.iconSmall)}
      />
      {process.env.REACT_APP_SERVICE === 'softtekautomation'
        ? _i('Nueva Automatización')
        : _i('Nueva Idea')}
    </Button>
  )
}
export default withStyles(styles)(InnoButton)
