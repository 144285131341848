import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  challengeBox: {
    width: '100%',
    height: '200px',
    borderRadius: '10px',
    objectFit: 'cover' as 'cover',
  },
  icon: {
    fontSize: '30px',
    marginTop: '10px'
  }
}))
