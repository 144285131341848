//File  List Context Declaration
import React, { useReducer } from 'react'

const initState = { activeDialog: false, dispatch: null }

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    //if reducer action addition then sum up the counter and add
    case 'update':
      return { ...state, activeDialog: action.status }

    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const ActiveDialogContext = React.createContext(initState)
//Provider Init
function ActiveDialogProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <ActiveDialogContext.Provider
      value={{ activeDialog: state.activeDialog, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </ActiveDialogContext.Provider>
  )
}
//Export conext and provider
export { ActiveDialogContext, ActiveDialogProvider }
