import { makeStyles } from '@material-ui/core/styles'
import palette from '../../../../theme/palette'

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
  },
  searchBar: {
    marginRight: '8px',
    ['@media (min-width: 959px) and (max-width:988px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '255px',
    },
  },
  navBar: {
    backgroundColor: theme.palette.background.default,
    color: '#000',
    height: '70px',
  },
  fullHeight: {
    height: '100%',
  },

  dialogBox: {
    width: '840px',
  },
  tienda: {
    height: '30px',
    width: 'auto',
    marginRight: '20px',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  evolution: {
    backgroundColor: '#ECEDF1',
    color: `${palette.text.secondary}`,
    marginRight: '18px',
  },
  backToTop: {
    color: theme.palette.primary.main,
  },
}))
