import { FieldProps } from 'formik'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { useStyles } from './styles'

const InputField = ({
  placeholder,
  field,

  ...props
}: FieldProps & any) => {
  // const [input, handleInput] = useState('')

  // @ts-ignore
  const classes = useStyles()
  return (
    <div style={{ marginBottom: '12px', width: '100%' }}>
      <InputBase
        {...field}
        {...props}
        placeholder={placeholder}
        className={classes.input}
        label={placeholder}
      />
    </div>
  )
}
export default InputField
