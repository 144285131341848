//Cover List Context Declaration
import React, { useReducer } from 'react'
import { VectorFields } from '../../../../generated/graphql'

const initState = {
  vectorFields: {
    responsable: '',
    departamento: '',
    area: '',
    manual: '',
    frecuencia: '',
    volumetrias: null,
    volumetriasUM: '',
    duracion: null,
    duracionUM: '',
    picosOperativos: false,
    informacionPicos: '',
    aspectos: [''],
    consistencia: '',
    reglas: '',
    datosEstandarizados: '',
    datosLegibles: '',
    procedimientoHabitual: '',
    cambiosProceso: '',
    cambioSistema: '',
    NoAplicaciones: null,
  } as VectorFields,
  dispatch: null,
}

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        // merge both objects using rest operator
        vectorFields: {
          ...state.vectorFields,
          ...action.newFields,
        },
      }
    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const VectorQuestionsContext = React.createContext(initState)
//Provider Init
function VectorQuestionsProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <VectorQuestionsContext.Provider
      value={{
        vectorFields: state.vectorFields,
        dispatch,
      }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </VectorQuestionsContext.Provider>
  )
}
//Export conext and provider
export { VectorQuestionsContext, VectorQuestionsProvider }
