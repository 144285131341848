import React, { useEffect, useState } from 'react'
import { Link as BrowserLink } from 'react-router-dom'
// Material ui
import Grid from '@material-ui/core/Grid'
import { Title } from '../../../../components/Shared'
import { Link, Collapse, Typography, Hidden } from '@material-ui/core'
import { useChallengesQuery, useMeQuery } from '../../../../generated/graphql'
import Loading from '../../../../components/Loading'
import { useStyles } from './styles'
import { concatenateSASOrNot } from '../../../../Utils'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { getPlaceholder } from '../../../../Utils/placeholder'
interface Props {}

const Challenges: React.FC<Props> = () => {
  const {
    data: { challenges },
    loading: challengeLoading,
  } = useChallengesQuery()
  const [showChallenges, setShowChallenges] = useState(true)
  const { data, loading } = useMeQuery()
  const [cover, setCover] = useState('')
  const [title, setTitle] = useState('')
  //  @ts-ignore
  const classes = useStyles()
  const handleToggleShow = e => {
    setShowChallenges(flag => !flag)
  }
  useEffect(() => {
    if (data.me && challenges && challenges.length > 0) {
      // the cover is the last element of the challenge list
      const coverTemp = concatenateSASOrNot(
        data.me.sas.token,
        challenges[0].cover,
        'small'
      )
      setCover(coverTemp)
      setTitle(challenges[0].name)
    }
  }, [data, challenges])

  if (loading || challengeLoading) {
    return <Loading />
  }
  if (!challenges || challenges.length === 0) {
    return <></>
  }
  return (
    <Grid
      container
      direction="column"
      style={{ padding: '0px 12px 12px 12px' }}>
      <Grid container justify="space-between">
        <Link
          component={BrowserLink}
          to={`/challenges`}
          style={{ textDecoration: 'none' }}>
          <Title
            style={{
              marginBottom: '16px',
              marginTop: '8px',
              fontFamily: 'InnoArial',
            }}>
            Challenges
          </Title>
        </Link>
        <Hidden smDown>
          {showChallenges ? (
            <ExpandLessIcon
              className={classes.icon}
              onClick={handleToggleShow}
            />
          ) : (
            <KeyboardArrowDownIcon
              className={classes.icon}
              onClick={handleToggleShow}
            />
          )}
        </Hidden>
      </Grid>
      <Collapse in={showChallenges}>
        <Link component={BrowserLink} to={`/challenges`}>
          <img
            src={cover ? cover : `${getPlaceholder()}`}
            className={classes.challengeBox}
          />
        </Link>
        <Typography variant="body1" style={{ fontFamily: 'InnoArial' }}>
          {title}
        </Typography>
      </Collapse>
    </Grid>
  )
}

export default Challenges
