import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
    input: {
        backgroundColor: '#ECEDF1',
        border: 'none',
        borderRadius: '12px',
        padding: '5px 7px',
        fontSize: '14px',
        color: '#727272',
        marginBottom: '12px',
        marginTop: '22px'
    }
}))
