import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'

export const Title: React.FC<{ style?: React.CSSProperties }> = ({
  children,
  style = {},
}) => {
  // @ts-ignore
  const classes = useStyles()
  return (
    <Typography style={{ ...style }} className={classes.title} variant="h3">
      {children}
    </Typography>
  )
}

export const Subtitle: React.FC = ({ children }) => {
  // @ts-ignore
  const classes = useStyles()
  return (
    <Typography className={classes.subtitle} variant="subtitle1">
      {children}
    </Typography>
  )
}

export const SubtitleCaixaBank: React.FC<{ style?: React.CSSProperties }> = ({
  children,
  style = {},
}) => {
  // @ts-ignore
  const classes = useStyles()
  return (
    <Typography
      className={classes.subtitleCaixaBank}
      variant="subtitle1"
      style={{ ...style }}>
      {children}
    </Typography>
  )
}
