import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
interface Props {

}
const Loading: React.FC<Props> = () => (
  <div
    style={{
      marginTop: '160px',
    }}>
    <Grid container justify="center">
      <CircularProgress />
    </Grid>
  </div>
)

export default Loading
