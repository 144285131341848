import React, { useReducer } from 'react'

const initState = { description: '', dispatch: null }
const reducer = (state, action) => {
  //Maybe the switch is not necessary
  switch (action.type) {
    case 'update':
      return { ...state, description: action.description }
    default:
      return { ...state }
  }
}

const DescriptionContext = React.createContext(initState)
function DescriptionProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <DescriptionContext.Provider
      value={{ description: state.description, dispatch }}>
      {children}
    </DescriptionContext.Provider>
  )
}
export { DescriptionContext, DescriptionProvider }
