import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { MainLayout } from './layouts'

import { AuthWrapper } from './authentication'
import Loading from './components/Loading'
const Home = React.lazy(() => import('./views/Home'))
const Tag = React.lazy(() => import('./views/Tag'))
const ReplicableIdeas = React.lazy(() => import('./views/ReplicableIdeas'))
const Type = React.lazy(() => import('./views/Type'))
const NotFound = React.lazy(() => import('./views/NotFound'))
const NewUser = React.lazy(() => import('./views/NewUser'))
const ServerError = React.lazy(() => import('./views/ServerError'))
const Idea = React.lazy(() => import('./views/Idea'))
const User = React.lazy(() => import('./views/User'))
const Admin = React.lazy(() => import('./views/Admin'))
const LogOut = React.lazy(() => import('./views/LogOut'))
const NotAllowed = React.lazy(() => import('./views/NotAllowed'))
const Login = React.lazy(() => import('./views/Login'))
const ChallengeList = React.lazy(() => import('./views/ChallengeList'))
const Evolution = React.lazy(() => import('./views/Evolution'))
const Challenge = React.lazy(() => import('./views/Challenge'))
const PasswordChange = React.lazy(() => import('./views/PasswordChange'))
const MailVerification = React.lazy(() => import('./views/MailVerification'))
const ChallengeEvolution = React.lazy(() =>
  import('./views/ChallengeEvolution')
)

const Routes = () => (
  <React.Suspense fallback={<Loading />}>
    <Switch>
      <Route component={NewUser} path="/new-user" />
      <Route component={ServerError} path="/server-error" />
      <Route component={LogOut} path="/logout" />
      <Route component={NotAllowed} path="/not-allowed" />
      <Route component={Login} path="/login" />
      <Route component={PasswordChange} path="/password-change" />
      <Route component={MailVerification} path="/verification" />
      <Route
        path="/"
        component={() => (
          <AuthWrapper>
            <Switch>
              <MainLayout component={Home} exact path="/" />
              <MainLayout component={Tag} exact path="/tag/:id" />
              <MainLayout
                component={ReplicableIdeas}
                exact
                path="/replicable/:id"
              />
              <MainLayout component={Type} exact path="/type/:id" />
              <MainLayout component={Idea} exact path="/idea/:id" />
              <MainLayout
                component={ChallengeEvolution}
                exact
                path="/challenge-evolution/:id"
              />
              <MainLayout component={Challenge} exact path="/challenge/:id" />
              <MainLayout component={User} exact path="/user/:id" />
              <MainLayout component={Admin} exact path="/adminsettings" />
              <MainLayout component={ChallengeList} exact path="/challenges" />
              <MainLayout component={Evolution} exact path="/evolution" />
              <Route component={NotFound} exact path="/not-found" />
              <Redirect to="/not-found" />
            </Switch>
          </AuthWrapper>
        )}
      />
    </Switch>
  </React.Suspense>
)

export default Routes
