import React, { useEffect } from 'react'
import { useStyles } from './styles'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
interface Props {
  message: string
}

const Congratulations: React.FC<Props> = ({ message }) => {
  const [open, setOpen] = React.useState(false)

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return
    // }
    setOpen(false)
    // console.log('closed')
  }
  useEffect(() => {
    if (message) {
      setOpen(true)
      // console.log('yeah!')
    }
  }, [message])
  // @ts-ignore
  const classes = useStyles()
  if (message) {
    return (
      <Snackbar
        open={open}
        transitionDuration={{ enter: 500, exit: 10 }}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Alert
          onClose={handleClose}
          severity="success"
          className={classes.alert}>
          {message}
        </Alert>
      </Snackbar>
    )
  } else {
    return null
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}
export default Congratulations
