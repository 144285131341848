import React, { useEffect, useState, useContext } from 'react'

import {
  InstantSearch,
  SearchBox,
  Pagination,
  RefinementList,
  Highlight,
  connectHits,
  connectStateResults,
  Configure,
  PoweredBy,
} from 'react-instantsearch-dom'
import './styles.css'
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Grid,
  InputAdornment,
} from '@material-ui/core'
import Input from '@material-ui/core/Input'
import { makeStyles, Theme } from '@material-ui/core/styles'
import UserPlaceHolder from '../../UserPlaceholder/index'
import searchClient from '../../../algolia'
import useRouter from 'use-react-router'
import { getInstanceLayout } from '../../../Utils/getInstanceLayout'
import { _i } from '../../../Utils/lang/i18n'
import { UserlistContext } from '../../../views/NewChallenge/components/JudgeSelect/UserlistContext'
import {
  SearchUserDialogProvider,
  SearchUserDialogContext,
} from './SearchUsersContext'
import msalInstance from '../../../authentication/azureMsal'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    position: 'absolute' as 'absolute',
    maxHeight: 600,
    minHeight: 100,
    minWidth: 300,
    maxWidth: 400,
    letterSpacing: 0,
    borderRadius: '12px',
    marginTop: '10px',
  },
  hit: {
    minWidth: 200,
    display: 'flex',
    marginBottom: '8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#eee',
    },
  },
}))

interface Props {}
const SearchUsers: React.FC = () => {
  return (
    <SearchUserDialogProvider>
      <SearchUsersContent />
    </SearchUserDialogProvider>
  )
}
const Content = connectStateResults(
  ({ searchState, searchResults, children, open }) => {
    // @ts-ignore
    const classes = useStyles()
    if (!open) {
      return null
    }
    if (!searchState || !searchState.query) {
      return null
    }
    return (
      <Paper className={classes.root} style={{ zIndex: 2, minWidth: '350px' }}>
        {children}
      </Paper>
    )
  }
)

type ClassType = 'Idea' | 'Tag' | 'User'
// Search bar component
const SearchUsersContent: React.FC<Props> = () => {
  //  use a fuckin stupid context just to open the shit out of this search bar
  const { dispatch, open } = useContext(SearchUserDialogContext)
  const handleOpen = () => {
    dispatch({ type: 'set', open: true })
  }
  const handleClose = () => {
    dispatch({ type: 'set', open: false })
  }

  const [valueSearch, setvalueSearch] = useState('')
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div onKeyDown={handleOpen} style={{ width: '100%' }}>
        <InstantSearch
          searchClient={searchClient}
          indexName={getInstanceLayout({
            heb: 'InnotekHEBIndex',
            softtek: 'InnotekSofttekIndex',
            sandbox: 'InnotekSandboxIndex',
            osde: 'InnotekOSDEIndex',
            softtekbrazil: 'InnotekSofttekBrazilIndex',
            abanca: 'InnotekABANCAIndex',
            cambiahealth: 'InnotekCambiaHealthIndex',
            socofar: 'InnotekSocofarIndex',
            dow: 'InnotekDowIndex',
            vector: 'InnotekVectorIndex',
            metlife: 'InnotekMetlifeIndex',
            lala: 'InnotekLalaIndex',
            chubb: 'InnotekChubbIndex',
            caixabank: 'InnotekCaixaBankIndex',
            softtekautomation: 'InnotekSofttekAutomationIndex',
            benavides: 'InnotekBenavidesIndex',
            vegcelerate: 'InnotekVegcelerateIndex',
          })}>
          <Configure hitsPerPage={4} />
          <SearchBox
            translations={{
              placeholder: _i('Buscar Usuarios'),
            }}
            onChange={e => {
              const { value } = e.target
              setvalueSearch(value)
            }}
          />
          <Content open={open}>
            <CustomHits value={valueSearch} />
          </Content>
        </InstantSearch>
      </div>
    </ClickAwayListener>
  )
}

const Hits = ({ hits, value }) => {
  const [fitleredHits, setFilteredHits] = useState([])
  const { dispatch: openDispatch } = useContext(SearchUserDialogContext)
  //set userlistcontext
  const { dispatch } = useContext(UserlistContext)
  // always filter the list depending an the hits
  useEffect(() => {
    const fitleredList = hits.filter(hit => hit.class === 'User')
    setFilteredHits(fitleredList)
  }, [hits])
  const classes = useStyles()
  //add user to the userlist
  const addUser = hit => {
    dispatch({ type: 'add', user: { name: hit.name, id: hit.id } })
    //close dialog
    openDispatch({ type: 'set', open: false })
    // @ts-ignore
    Array.from(document.querySelectorAll('.ais-SearchBox-reset'))[1].click()
  }
  // if there are no hits
  if (fitleredHits.length === 0) {
    const user = { class: 'User', id: value, name: value, objectID: value }
    return (
      <div
        style={{ padding: '2px' }}
        tabIndex={0}
        className={`${classes.hit} hit`}
        onClick={() => {
          addUser(user)
        }}>
        <UserPlaceHolder username={user.name} size="small" />
        <Grid>
          <Typography
            variant="subtitle1"
            style={{
              lineHeight: '16px',
              marginTop: '0px',
              marginLeft: '8px',
            }}>
            <Button>{user.name}</Button>
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              fontSize: '10px',
              color: 'rgb(102, 120, 138)',
              lineHeight: '16px',
              marginTop: '5px',
              marginLeft: '8px',
            }}>
            {_i('Usuario no registrado')}
          </Typography>
        </Grid>
      </div>
    )
  }
  // return the complete user list
  return (
    <>
      {fitleredHits.map(hit => (
        <div
          style={{ padding: '2px' }}
          tabIndex={0}
          className={`${classes.hit} hit`}
          onClick={() => {
            addUser(hit)
          }}>
          <UserPlaceHolder username={hit.name} size="small" />
          <Grid>
            <Typography
              variant="subtitle1"
              style={{
                lineHeight: '16px',
                marginTop: '0px',
                marginLeft: '8px',
              }}>
              <Highlight attribute="name" hit={hit} />
            </Typography>
            <Typography
              variant="subtitle2"
              style={{
                fontSize: '10px',
                color: 'rgb(102, 120, 138)',
                lineHeight: '16px',
                marginTop: '5px',
                marginLeft: '8px',
              }}>
              <Highlight attribute="id" hit={hit} />
            </Typography>
          </Grid>
        </div>
      ))}
    </>
  )
}

const CustomHits = connectHits(Hits)
export default SearchUsers
