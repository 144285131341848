import Fab from '@material-ui/core/Fab'
import React, { useContext } from 'react'
import styled from 'styled-components'
import CloseRounded from '@material-ui/icons/CloseRounded'
import { makeStyles } from '@material-ui/styles'
import { FileContext } from '../DropBox/DropBoxContext'
import { CoverContext } from '../CoverDrop/CoverDropContext'
const BoxWrapper = styled.div`
  position: relative;
  display: table;
`

interface CustomBadgeProps {
  children: any
  i: number
  method: string
}
// Adding Styles
const useStyles = makeStyles(() => ({
  fab: {
    backgroundColor: '#f6f6f6',
    padding: '2px',
    width: '17px',
    height: '17px',
    minHeight: '17px',
    boxShadow: '0px 3px 6px -4px',
    zIndex: 3,

    position: 'absolute' as 'absolute',
    right: -6,
    top: -6,
  },
  icon: {
    color: '#727272',
    fontSize: '15px',
    padding: '2px',
  },
}))

// IMPORTANT: The custom badge require the method in order to activate the correct dispatch,

export const CustomBadge: React.FC<CustomBadgeProps> = ({
  children,
  i,
  method,
}) => {
  // @ts-ignore
  const classes = useStyles()
  const { dispatch: dispatchFile } = useContext(FileContext)
  const { dispatch: dispatchCover } = useContext(CoverContext)
  const execute_dispatch = () => {
    //If cover Drop is calling the function then, use its dispatch
    if (method == 'CoverDrop') {
      dispatchCover({ type: 'remove' })
    } else if (method == 'DropBox') {
      dispatchFile({ type: 'remove', id: i })
    }
  }
  return (
    <BoxWrapper>
      <Fab
        color="default"
        className={classes.fab}
        onClick={() => {
          execute_dispatch()
        }}>
        <CloseRounded className={classes.icon} />
      </Fab>
      {children}
    </BoxWrapper>
  )
}
