// all users added to the judge select list are passed through this context
// important note, algolia SearchUsers component communicate with this context.
import React, { useReducer } from 'react'
export interface User {
  id: string
  name: string
}
interface UserlistController {
  userlist: User[]
  judgeType: 'open' | 'judge'
  dispatch: any
}
//set typescript action for the controller
interface ActionController {
  type: 'add' | 'remove' | 'setJudgeType'
  user: User
  judgeType?: 'open' | 'judge'
}
const initState: UserlistController = {
  judgeType: 'open',
  userlist: [],
  dispatch: null,
}

//create reducer for state management
const reducer = (state, action: ActionController) => {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        userlist: [...state.userlist, action.user],
      }
    case 'remove':
      let userListTemp: User[] = Object.assign([], state.userlist)
      for (let i = userListTemp.length - 1; i >= 0; i--) {
        if (userListTemp[i].id === action.user.id) {
          userListTemp.splice(i, 1)
        }
      }
      //reset all to prevent duplicate images
      return {
        ...state,
        userlist: [...userListTemp],
      }
    case 'setJudgeType':
      return {
        ...state,
        judgeType: action.judgeType
      }
    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const UserlistContext = React.createContext<UserlistController>(initState)
//Provider Init
const UserlistProvider: React.FC = ({ children }) => {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <UserlistContext.Provider value={{ userlist: state.userlist, judgeType: state.judgeType, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </UserlistContext.Provider>
  )
}
//Export conext and provider
export { UserlistContext, UserlistProvider }
