import React, { useState, useContext, useEffect } from 'react'
import {
  Typography,
  Divider,
  Collapse,
  IconButton,
  Box,
  Grid,
  DialogContent,
  Dialog,
  DialogTitle,
  Fab,
  Chip,
} from '@material-ui/core'
import Moment from 'react-moment'
import { Link as BrowserLink } from 'react-router-dom'
import CloseRounded from '@material-ui/icons/CloseRounded'
import { Idea, useIdeaQuery } from '../../../../generated/graphql'
import { _i } from '../../../../Utils/lang/i18n'
import Carousel from '../../../../views/Idea/components/Details/Carousel'
import Details from '../../../../views/Idea/components/Details/'
import { IdeaContext } from '../../../../views/Idea/controller'
import { camelize } from '../../../../Utils/textUtils'
import { useStyles } from './styles'
interface Props {
  open: boolean
  fullScreen: boolean
  ideaId: string
  setOpen: any
}

export const ModalIdea: React.FC<Props> = props => {
  const classes = useStyles()

  const { fullScreen, open, ideaId, setOpen } = props
  const {
    data: { idea },
    loading,
  } = useIdeaQuery({ variables: { ideaId } })
  const handleClose = () => {
    setOpen(false)
  }
  const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="sm"
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title">
      {idea !== undefined && (
        <>
          <DialogTitle id="scroll-dialog-title">
            <Fab color="default" className={classes.fab} onClick={handleClose}>
              <CloseRounded className={classes.icon} />
            </Fab>
          </DialogTitle>
          <DialogContent id="scroll-dialog-title">
            <Grid container direction="column">
              <Carousel attachments={idea.attachments} ideaCover={idea.cover} />
              <Grid
                item
                style={{
                  padding: '6px 16px',
                  position: 'relative',
                  width: '100%',
                }}>
                <Typography variant="h3" className={classes.name}>
                  {camelize(idea.name)}
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.shortDescription}>
                  {idea.shortDescription}
                </Typography>

                <Typography
                  dangerouslySetInnerHTML={{ __html: idea.description }}
                  variant="body1"
                />

                <div className={classes.tagBox}>
                  {idea.tags.map((tag: string, key: number) => (
                    <BrowserLink
                      to={`/tag/${tag}`}
                      key={key}
                      style={{ textDecoration: 'none' }}>
                      <Chip
                        key={key}
                        variant="outlined"
                        size="small"
                        label={tag}
                        className={classes.tag}
                      />
                    </BrowserLink>
                  ))}
                  {idea.stage === 1 ? (
                    <Chip
                      key="stage1"
                      variant="outlined"
                      size="small"
                      label={_i('Pickup')}
                      className={classes.evolution}
                      style={{ backgroundColor: '#2D93F8' }}
                    />
                  ) : idea.stage === 2 ? (
                    <Chip
                      key="stage2"
                      variant="outlined"
                      size="small"
                      label={_i('Acceleration')}
                      className={classes.evolution}
                      style={{ backgroundColor: '#00d69e' }}
                    />
                  ) : (
                    idea.stage === 3 && (
                      <Chip
                        key="stage3"
                        variant="outlined"
                        size="small"
                        label={_i('Adoption')}
                        className={classes.evolution}
                      />
                    )
                  )}
                </div>
                {console.log(idea.stage)}

                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" style={{ color: '#6d6d6d' }}>
                    <Moment format="LLLL">{idea.creationDate}</Moment>
                  </Typography>
                  {idea.type && process.env.REACT_APP_SERVICE !== 'abanca' ? (
                    <BrowserLink
                      to={`/type/${idea.type}`}
                      key={`s1`}
                      style={{ textDecoration: 'none' }}>
                      <Typography
                        className={classes.type}
                        variant="subtitle2"
                        style={{ color: '#4d4d4d', marginRight: '18px' }}>
                        {capitalize(idea.type)}
                      </Typography>
                    </BrowserLink>
                  ) : (
                    <div />
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
