//File  List Context Declaration
import React, { useReducer } from 'react'

const initState = { fileList: [], dispatch: null }

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    //if reducer action addition then sum up the counter and add
    case 'add':
      // eslint-disable-next-line
      let file_temp: any
      if (action.init) {
        file_temp = {
          file: action.file,
          init: true,
        }
      } else {
        file_temp = {
          file: action.file,
          init: false,
        }
      }
      return { ...state, fileList: [...state.fileList, file_temp] }

    // if action is removal, then find the id and remove it
    case 'remove':
      // dummy object to remove the specified id
      // eslint-disable-next-line
      const fileList = Object.assign([], state.fileList)
      fileList.splice(action.id, 1)
      return {
        fileList: [...fileList],
      }

    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const FileContext = React.createContext(initState)
//Provider Init
function FileProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <FileContext.Provider value={{ fileList: state.fileList, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </FileContext.Provider>
  )
}
//Export conext and provider
export { FileContext, FileProvider }
