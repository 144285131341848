import * as sw from 'stopword'

/**
 * Clean string from accents
 * @param {string} str
 */
const accentsTidy = function(str) {
  let r = str.toLowerCase()
  //                        r = r.replace(new RegExp("\\s", 'g'),"");
  r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a')
  r = r.replace(new RegExp('æ', 'g'), 'ae')
  r = r.replace(new RegExp('ç', 'g'), 'c')
  r = r.replace(new RegExp('[èéêë]', 'g'), 'e')
  r = r.replace(new RegExp('[ìíîï]', 'g'), 'i')
  r = r.replace(new RegExp('ñ', 'g'), 'n')
  r = r.replace(new RegExp('[òóôõö]', 'g'), 'o')
  r = r.replace(new RegExp('œ', 'g'), 'oe')
  r = r.replace(new RegExp('[ùúûü]', 'g'), 'u')
  r = r.replace(new RegExp('[ýÿ]', 'g'), 'y')
  //r = r.replace(new RegExp("\\W", 'g'),"");
  return r
}

/**
 * Clean from stopwords and snake case
 * @param {string} str
 */
function cleanAndSnake(str: string): string {
  const noSpecialChars = str.replace(/[^\w ]/g, '')
  const oldString = accentsTidy(noSpecialChars).split(' ')
  let newString: string[] = sw.removeStopwords(oldString, sw.en)
  newString = sw.removeStopwords(oldString, sw.es)
  newString = sw.removeStopwords(oldString, sw.br)
  return newString.join('_')
}

/**
 * Create Idea ID cleanng with aboce functions
 * @param ideaName
 */
function createIdeaId(ideaName: string): string {
  const cleanId = cleanAndSnake(ideaName)
  const newStr = cleanId.substring(0, 20)
  const today = new Date()
  return `${newStr}_${today.getDate()}_${today.getMonth() +
    1}_${today.getFullYear()}_${today.getMilliseconds()}`
}

export default createIdeaId
