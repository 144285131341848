import React, { useContext, useEffect, useRef } from 'react'

// Material ui
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import NotificationsIcon from '@material-ui/icons/Notifications'
// Material icons
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CommentIcon from '@material-ui/icons/Comment'
import ReactionIcon from '@material-ui/icons/ThumbUp'
import FollowIcon from '@material-ui/icons/Add'
import UserNotifIcon from '@material-ui/icons/Person'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import StarIcon from '@material-ui/icons/Star'
import GavelIcon from '@material-ui/icons/Gavel'

//styles
import useStyles from './styles'

//moment
import moment from 'moment'

// ClassName
import * as classNames from 'classnames'
import {
  NotificationType,
  useMeQuery,
  useMarkNotifsAsReadMutation,
  NotificationTarget,
  ClassEnum,
} from '../../../../generated/graphql'
import Loading from '../../../../components/Loading'
import useRouter from 'use-react-router'
import { _i, getLang } from '../../../../Utils/lang/i18n'
import palette from '../../../../theme/palette'
import CallSplitIcon from '@material-ui/icons/CallSplit'
import LinkIcon from '@material-ui/icons/Link'
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import { getNotificationMessage } from '../../../../Utils'
import FlagIcon from '@material-ui/icons/Flag'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { NotificationContext } from './NotificationContext'

export const notificationIconMap = {
  [`${NotificationType.Comment}`]: {
    icon: <CommentIcon />,
  },
  [`${NotificationType.TagComment}`]: {
    icon: <CommentIcon />,
  },
  [`${NotificationType.Reaction}`]: {
    icon: <FavoriteIcon />,
  },
  [`${NotificationType.Follow}`]: {
    icon: <FollowIcon />,
  },
  [`${NotificationType.Evolution}`]: {
    icon: <CallSplitIcon />,
  },
  [`${NotificationType.Linked}`]: {
    icon: <LinkIcon />,
  },
  [`${NotificationType.Challengeevolution}`]: {
    icon: <CallSplitIcon />,
  },
  [`${NotificationType.Flagged}`]: {
    icon: <FlagIcon />,
  },
  [`${NotificationType.Medal}`]: {
    icon: <EmojiEventsIcon />,
  },
  [`${NotificationType.Reply}`]: {
    icon: <CommentIcon />,
  },
  [`${NotificationType.TagIdea}`]: {
    icon: <LoyaltyIcon />,
  },
  [`${NotificationType.UserPost}`]: {
    icon: <UserNotifIcon />,
  },
  [`${NotificationType.Featured}`]: {
    icon: <StarIcon />,
  },
  [`${NotificationType.UserJudge}`]: {
    icon: <GavelIcon />,
  },
  [`${NotificationType.AdminLinked}`]: {
    icon: <LinkIcon />,
  },
  [`${NotificationType.LikeReaction}`]: {
    icon: <ReactionIcon />,
  },
}

const Notifications: React.FC = () => {
  const { data, loading } = useMeQuery()

  // @ts-ignore
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const markAsRead = useMarkNotifsAsReadMutation()
  const { history } = useRouter()
  const {
    state: { notificationList, markSubscriptionNotification },
    dispatch: notificationDispatch,
  } = useContext(NotificationContext)
  useEffect(() => {
    return () => {
      setOpen(false)
    }
  }, [])
  const count = React.useMemo(() => {
    // console.log(data)
    // console.log(isMounted.current ? 'mounted' : 'not mounted')
    if (!loading && data.me) {
      if (notificationList.length === 0) {
        for (let i = 0; i < data.me.notifications.length; i++) {
          const notification = data.me.notifications[i]
          notificationDispatch({ type: 'add', notification })
        }
      }

      let _count = 0
      data.me.notifications.map(n => (_count += n.read ? 0 : 1))
      return _count
    } else {
      return 0
    }
  }, [data, loading])

  // React.useEffect(() => {
  //   if (open && count > 0) {
  //     markAsRead()
  //   }
  // }, [count, open])

  const anchorEl = React.useRef(null)
  // function handleToggle() {
  //   if (count > 0 && open) {
  //     markAsRead()
  //   }
  //   setOpen(!open)
  // }

  // // Esta funcion no hace nada por alguna razon...
  // function handleClose() {
  //   // if (count > 0 && open) {
  //   //   markAsRead()
  //   // }
  //   // setOpen(false)
  // }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen)
    if (count > 0 && open) {
      markAsRead()
    }
  }

  function handleClose(event: React.MouseEvent<EventTarget>) {
    if (
      anchorEl.current &&
      anchorEl.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
    if (count > 0) {
      markAsRead()
    }
  }

  function goToPage(target: NotificationTarget) {
    let route = ``
    if (target.class === ClassEnum.Idea) {
      route = `/idea/${target.id}`
    } else if (target.class === ClassEnum.User) {
      route = `/user/${target.id}`
    } else if (target.class === ClassEnum.Tag) {
      route = `/tag/${target.id}`
    } else if (target.class === ClassEnum.Challenge) {
      route = `/challenge/${target.id}`
    } else if (target.class === ClassEnum.Challengeevolution) {
      route = `/challenge-evolution/${target.id}`
    } else if (target.class === ClassEnum.Evolution) {
      route = `/evolution`
    } else {
      route = '/'
    }
    // @ts-ignore
    if (target.notificationSubscription) {
      route = target.id
    }
    setOpen(false)
    //
    window.location.replace(route)
  }

  return (
    <>
      <IconButton
        buttonRef={anchorEl}
        className={classes.notification}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit">
        <Badge
          badgeContent={count + Number(markSubscriptionNotification)}
          variant="dot"
          color="primary"
          style={{ fontFamily: 'InnoArial' }}>
          <NotificationsIcon style={{ color: `${palette.text.secondary}` }} />
        </Badge>
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl.current}
        style={{ zIndex: 1000 }}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow
              {...TransitionProps}
              style={{
                right: 40,
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              {loading ? (
                <Loading />
              ) : (
                <Paper className={classes.paper}>
                  <>
                    <List component="div">
                      {notificationList.map((notif, i) => (
                        <React.Fragment key={`notif-${i}`}>
                          <ListItem
                            className={classNames(classes.listItem, {
                              [`${classes.activeListItem}`]: !notif.read,
                            })}
                            onClick={() => goToPage(notif.target)}>
                            <ListItemIcon className={classes.listItemIcon}>
                              {notificationIconMap[notif.type].icon}
                            </ListItemIcon>
                            <ListItemText
                              classes={{
                                secondary: classes.listItemTextSecondary,
                              }}
                              primary={getNotificationMessage({
                                type: notif.type,
                                who: notif.who.name,
                                target: notif.target.name,
                              })}
                              secondary={moment(new Date(notif.date))
                                .locale(getLang())
                                .fromNow()}
                            />
                            <ArrowForwardIosIcon
                              className={classes.arrowForward}
                            />
                          </ListItem>
                          {i !== notificationList.length - 1 && (
                            <Divider variant="inset" component="li" />
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                    {notificationList.length === 0 && (
                      <Grid
                        container
                        style={{ height: '100%', width: '100%' }}
                        justify="center"
                        alignContent="center">
                        <Typography variant="body2">
                          {_i('No tienes notificaciones')}
                        </Typography>
                      </Grid>
                    )}
                  </>
                </Paper>
              )}
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  )
}

export default Notifications
