//File  List Context Declaration
import React, { useReducer } from 'react'

const initState = { shortDescription: '', dispatch: null }

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    //if reducer action addition then sum up the counter and add
    case 'update':
      return { ...state, shortDescription: action.shortDescription }

    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const ShortDescriptionContext = React.createContext(initState)
//Provider Init
function ShortDescriptionProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <ShortDescriptionContext.Provider
      value={{ shortDescription: state.shortDescription, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </ShortDescriptionContext.Provider>
  )
}
//Export conext and provider
export { ShortDescriptionContext, ShortDescriptionProvider }
