import { FieldProps } from 'formik'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { Select } from '@material-ui/core'
import { useIdeaTypesQuery } from '../../../../generated/graphql'
import Loading from '../../../Loading'
import { _i } from '../../../../Utils/lang/i18n'
const styles = () => ({
  input: {
    backgroundColor: '#ECEDF1',
    border: 'none',
    borderRadius: '12px',
    padding: '5px 7px',
    fontSize: '14px',
    color: '#727272',
    width: '100%',
  },
})

const InputField = ({
  placeholder,
  field,

  ...props
}: FieldProps & any) => {
  // const [input, handleInput] = useState('')
  const { data, loading } = useIdeaTypesQuery()

  if (loading) {
    return <Loading />
  }
  const { classes } = props

  const ideaTypes = data.appSettings ? data.appSettings.ideaTypes || [] : []

  return (
    <div style={{ marginBottom: '12px' }}>
      <Select
        {...field}
        native
        placeholder={placeholder}
        input={
          <InputBase placeholder={placeholder} className={classes.input} />
        }>
        <option disabled value={''}>
          {process.env.REACT_APP_SERVICE === 'softtekautomation'
            ? _i('Source')
            : _i('Tipo de idea')}
        </option>
        {ideaTypes.map(option => (
          <option key={option.id} value={option.name}>
            {option.name}
          </option>
        ))}
      </Select>
    </div>
  )
}
export default withStyles(styles)(InputField)
