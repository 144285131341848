const wordList = [
  'por',
  'la',
  'de',
  'el',
  'un',
  'una',
  'a',
  'y',
  'al',
  'tu',
  'para',
  'al',
]

/**
 * Camelize Word taking common spanish words
 * @param {string} message
 */
export const camelize = message => {
  let firstWord = true
  const camelWord = message.split(' ').map(word => {
    if (!word) return

    if (word.toLowerCase() === 'heb') return word.toUpperCase()
    if (word.toLowerCase() === 'sas') return word.toUpperCase()
    if (word.toLowerCase() === 'qr') return word.toUpperCase()
    if (word.toLowerCase() === 'cat') return word.toUpperCase()
    if (
      (wordList.indexOf(word.toLowerCase()) >= 0 || word.length == 2) &&
      !firstWord
    )
      return word.toLowerCase()
    firstWord = false
    return word[0].toUpperCase() + word.slice(1).toLowerCase()
  })
  return camelWord.join(' ')
}

/**
 * Create a brief description from a text
 * @param {string} message
 * @param {number} maxLength
 */
export const brief = (message: string, maxLength: number): string => {
  return message.length > maxLength
    ? `${message.slice(0, maxLength)}...`
    : message
}

export const mergeClassNames = (...classes) => classes.join(' ')
