import { FieldProps } from 'formik'
import React, { useState, useEffect, useContext } from 'react'
import { ShortDescriptionContext } from './ShortDescriptionContext'
import InputBase from '@material-ui/core/InputBase'
import { _i } from '../../../../Utils/lang/i18n'
export const ShortDescriptionField = ({
  placeholder,
  field,

  ...props
}: FieldProps & any) => {
  const { shortDescription, dispatch } = useContext(ShortDescriptionContext)
  // const [input, handleInput] = useState('')
  const [warning, handleWarning] = useState(false)
  const maxLength = 140

  const [keyDown, setKey] = useState(false)
  let flag = false
  //Use Effect to show warning

  useEffect(() => {
    if (shortDescription.length == maxLength) {
      handleWarning(true)
    } else {
      handleWarning(false)
    }
  }, [shortDescription])
  // const [input, handleInput] = useState('')
  return (
    <div>
      {/* Regular Text Field with Hooks */}

      <InputBase
        {...field}
        {...props}
        style={{
          backgroundColor: '#ECEDF1',
          border: 'none',
          borderRadius: '12px',
          padding: '4px 12px',
          fontSize: '14px',
          color: '#727272',
          // width: '300px',
          width: '100%',
        }}
        label={placeholder}
        value={shortDescription}
        placeholder={_i('Danos una descripción breve...')}
        id="shortDescription"
        onBlur={e => {
          if (flag) {
            var x = document.getElementById('shortDescription')
            x.focus()
          }
        }}
        onKeyDown={e => {
          const regex = /[a-zA-Z]/g
          const found = e.key.match(regex)
          if (found !== null)
            if (found.length === 1) {
              const newString = shortDescription + e.key
              dispatch({ type: 'update', shortDescription: newString })
              setKey(true)
              flag = true
            }
        }}
        onKeyUp={e => {
          flag = false
          setKey(false)
        }}
        onChange={e => {
          if (!keyDown) {
            //Limit amount of characters
            if (e.target.value.length <= maxLength) {
              dispatch({ type: 'update', shortDescription: e.target.value })
            }
          }
        }}
      />
      <div
        style={{
          color: warning ? '#ef4021' : '#727272',
          fontSize: '11px',
          fontFamily: 'Roboto',
          marginLeft: '6px',
          marginBottom: '10px',
        }}>
        {shortDescription.length} / {maxLength}
      </div>
    </div>
  )
}
