import React, { useEffect } from 'react'
import './App.css'
import { ApolloProvider as ApolloProviderHooks } from 'react-apollo-hooks'
import { ApolloProvider } from 'react-apollo'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import client from './apollo'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routes from './Routes'
import theme from './theme'
import { getLang, setLang } from './Utils/lang/i18n'
import { langTypes } from './Utils/lang/lcMsgs'
import { Helmet } from 'react-helmet'
// @ts-ignore
import inspiraIco from './static/inspira.ico'
// @ts-ignore
import faviconIco from './static/favicon.ico'
// @ts-ignore
import rerobotIco from './static/rerobot.ico'
// @ts-ignore
import caixabankIco from './static/CaixAMagic.ico'

import { getInstanceLayout } from './Utils/getInstanceLayout'
// Browser history
const browserHistory = createBrowserHistory()

const App: React.FC = () => {
  //determine language using lang utils
  useEffect(() => {
    //localStorage.removeItem('lang');
    const lang: langTypes = getLang()
    //after obtaining the lang, set the language in local storage
    setLang(lang)
  }, [])
  return (
    <>
      <Helmet>
        <title>
          {getInstanceLayout({
            softtek: 'Innotek',
            heb: 'Inspira HEB',
            abanca: 'Rerobot',
            caixabank: 'CaixAMagic',
            vegcelerate: 'VEGcelerate',
          })}
        </title>
        <link
          rel="shortcut icon"
          href={getInstanceLayout({
            softtek: caixabankIco,
            heb: inspiraIco,
            abanca: rerobotIco,
            caixabank: caixabankIco,
          })}
        />
      </Helmet>
      <ApolloProviderHooks client={client}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <Router history={browserHistory}>
              <>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Routes />
              </>
            </Router>
          </ThemeProvider>
        </ApolloProvider>
      </ApolloProviderHooks>
    </>
  )
}

export default App
