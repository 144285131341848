// all users added to the judge select list are passed through this context
// important note, algolia SearchUsers component communicate with this context.
import React, { useReducer } from 'react'
interface SearchUserDialogController {
  open: boolean
  dispatch: any
}
//set typescript action for the controller
interface ActionController {
  type: 'set'
  open: boolean
}
const initState: SearchUserDialogController = {
  open: false,
  dispatch: null,
}

//create reducer for state management
const reducer = (state, action: ActionController) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        open: action.open,
      }

    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const SearchUserDialogContext = React.createContext<SearchUserDialogController>(
  initState
)
//Provider Init
const SearchUserDialogProvider: React.FC = ({ children }) => {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <SearchUserDialogContext.Provider value={{ open: state.open, dispatch }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </SearchUserDialogContext.Provider>
  )
}
//Export conext and provider
export { SearchUserDialogContext, SearchUserDialogProvider }
