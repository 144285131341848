import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { FilePreview } from './FilePreview'
import { FileContext } from './DropBoxContext'
import DeleteRounded from '@material-ui/icons/DeleteRounded'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded'
import ExpandLessRounded from '@material-ui/icons/ExpandLessRounded'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { _i } from '../../../../Utils/lang/i18n'
const FileTag = styled.div`
  font-family: 'Segoe UI', sans-serif;
  margin: 0;
`

const DropBoxOutline = styled.div`
  background: #ecedf1;
  color: #727272;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  padding: 12px;

  font-size: 14px;
  transition: all 0.5s;
  position: relative;
  &:hover {
    // cursor: pointer;
    // border: 1px dashed #727272 !important;
    // transition: all 0.5s;
  }
`
const AddFileBox = styled.div`
  margin-left: -4px;
  &:hover {
    cursor: pointer;
  }
`

interface DropbBoxProps {
  initState: any[]
}
export const DropBox: React.FC<DropbBoxProps> = ({ initState }) => {
  const { fileList, dispatch } = useContext(FileContext)
  const [details, handleDetails] = useState(false)
  useEffect(() => {
    if (initState) {
      initState.map(attachment => {
        dispatch({
          type: 'add',
          file: {
            name: attachment.name,
            type: attachment.type,
            url: attachment.url,
          },
          init: true,
        })
      })
    }
  }, [])

  const handleDrop = e => {
    e.stopPropagation()
    e.preventDefault()
    let files = e.dataTransfer.files
    for (let i = 0; i < files.length; i++) {
      let file = files[i]

      let file_temp = {
        name: file.name,
        size: file.size,
        type: file.type,
        file: file,
      }
      dispatch({ type: 'add', file: file_temp, init: false })
      // setFileList([...fileList, file_temp])
      // setFileListProp([...fileListProp, file_temp])
    }
  }
  //Handle File Picking through the file browser
  const handlePick = e => {
    e.stopPropagation()
    e.preventDefault()
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      let file = files[i]

      let file_temp = {
        name: file.name,
        size: file.size,
        type: file.type,
        file: file,
      }
      dispatch({ type: 'add', file: file_temp, init: false })
      // setFileList([...fileList, file_temp])
      // setFileListProp([...fileListProp, file_temp])
    }
  }

  const onDragOver = e => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleDelete = (i: any) => {
    dispatch({ type: 'remove', id: i })
    // fileList.splice(i, 1)
    // setFileList([...fileList])
    // setFileListProp([...fileListProp])
  }

  return (
    <div style={{ width: '100%' }}>
      {/*set up the file Provider*/}
      <DropBoxOutline
        onDragOver={e => {
          onDragOver(e)
        }}
        onDrop={e => {
          handleDrop(e)
        }}>
        <FileTag>
          <p style={{ marginLeft: '8px' }}>
            {process.env.REACT_APP_SERVICE === 'softtekautomation'
              ? _i('Repository Docs')
              : _i('Archivos')}
            :
          </p>
          <p style={{ fontSize: '12px', marginTop: '0px', marginLeft: '8px' }}>
            {_i(
              'Suelta tus archivos aquí. Serán subidos de manera automática...'
            )}
          </p>
        </FileTag>
        <div
          style={{
            position: 'absolute',
            bottom: '5px',
            width: '90%',
            display: 'flex',
            overflowY: 'scroll',
            paddingTop: '14px',
            alignItems: 'center',
          }}>
          {/* Special case for file addition */}
          <AddFileBox
            onClick={() => {
              document
                .getElementById('custom-traditional-input-dropbox')
                .click()
            }}>
            <FilePreview fileName={'.addfile'} i={0} />
          </AddFileBox>

          {fileList &&
            fileList.map((item, i) => (
              <FilePreview fileName={item.file.name} i={i} key={i} />
            ))}
        </div>
      </DropBoxOutline>
      <p
        style={{
          fontFamily: 'Roboto',
          fontSize: '12px',
          color: '#2972e8',
          marginLeft: '10px',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleDetails(!details)
        }}>
        {_i('Detalles de Archivos')}
        {details ? (
          <ExpandLessRounded
            style={{ fontSize: '20px', verticalAlign: 'middle' }}
          />
        ) : (
          <ExpandMoreRounded
            style={{ fontSize: '20px', verticalAlign: 'middle' }}
          />
        )}
      </p>
      <Collapse in={details}>
        <div
          style={{
            padding: '12px',
            maxHeight: '200px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}>
          {details
            ? fileList &&
              fileList.map((item, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '2px',
                      alignItems: 'center',
                      width: '400px',
                      height: '58px',
                    }}>
                    <Box fontFamily="Roboto" fontSize="12px" m={1}>
                      {item.file.name.slice(0)}
                      {item.file.name.length >= 50 ? '...' : null}
                    </Box>
                    <IconButton
                      onClick={() => {
                        handleDelete(i)
                      }}>
                      <DeleteRounded />
                    </IconButton>
                  </div>
                  <Divider />
                </React.Fragment>
              ))
            : null}
        </div>
      </Collapse>
      <input
        id="custom-traditional-input-dropbox"
        hidden
        type="file"
        onChange={e => {
          handlePick(e)
        }}
      />
    </div>
  )
}
