import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'
import CheckIcon from '@material-ui/icons/Check'
import { notificationIconMap } from '../../layouts/MainLayout/TopBar/Notifications'
import { getNotificationMessage } from '../../Utils'
import { withMobileDialog } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import { TransitionProps } from '@material-ui/core/transitions'
import theme from '../../theme'
interface FCMNotification {
  body: string
  click_action: string
  icon: string
  title: string
  type: string
  who: string
  target: string
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 600,
  },
  snackbar: {},
  text: {
    fontFamily: 'InnoArial',
    padding: '6px 12px',
    boxShadow: '0px 0px 7px -3px rgba(112,112,112,0.88)',
    backgroundColor: 'white',
    transition: '0.4s all',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
}))
function GrowTransition(props: TransitionProps) {
  return <Grow {...props} />
}

const NotificationSnack: React.FC<{
  notification: FCMNotification
  onClose: any
}> = ({ notification, onClose }) => {
  const [open, setOpen] = useState(true)
  const handleClose = e => {
    setOpen(false)
  }
  // clean url from the click action and force a refresh
  const cleanUrl = (url: string): string => {
    return url.match(/innotek.ai\/.*/)[0].replace('innotek.ai', '')
  }
  const goToNotif = e => {
    e.preventDefault()
    // console.log(notification)
    window.location.replace(cleanUrl(notification.click_action))
  }
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, [30000])
    }
    if (!open) {
      // set timeout to nullifiyng component

      setTimeout(() => {
        onClose()
      }, [1000])
    }
  }, [open])
  // @ts-ignore
  const classes = useStyles()
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      key={`notification`}
      open={open}
      TransitionComponent={GrowTransition}
      className={classes.snackbar}
      onClose={handleClose}>
      <Alert
        severity="info"
        icon={notificationIconMap[notification.type].icon}
        className={classes.text}
        onClick={goToNotif}>
        {getNotificationMessage({ ...notification })}
      </Alert>
    </Snackbar>
  )
}

export default NotificationSnack
