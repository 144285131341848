import { getInstanceLayout } from './getInstanceLayout'
import { _i } from './lang/i18n'
import { NotificationType, Notification } from '../generated/graphql'

export const PAGINATION_SIZE = 6

export const concatenateSASOrNot = (
  sas: string,
  url: string,
  type: 'small' | 'preview' | 'attachment'
) => {
  if (!url) {
    return null
  }
  if (process.env.REACT_APP_SERVICE === 'softtek') {
    return `${url}?${sas}`
  }
  if (url.includes('https://innotekfilestore.blob.core.windows.net')) {
    if (type === 'small') {
      return `${url}-300?${sas}`
    } else if (type === 'preview') {
      return `${url}-200?${sas}`
    } else {
      return `${url}?${sas}`
    }
  } else {
    return url
  }
}

export const generateId = (original: string): string => {
  const x = original.substr(0, original.indexOf('@'))
  return x
}

/**
 * Get challenge status color depending on instance
 * @param {string} status
 */
export const getStatusColor = (status: string): string => {
  let color = '#fff'
  if (status.toLowerCase() === 'pending') {
    color = getInstanceLayout({
      softtek: '#2D93F8',
      heb: '#8d6e63',
      sandbox: '#2D93F8',
    })
  } else if (status.toLowerCase() === 'open') {
    color = getInstanceLayout({
      softtek: '#00d69e',
      heb: '#757575',
      sandbox: '#00d69e',
    })
  } else if (status.toLowerCase() === 'closed') {
    color = getInstanceLayout({
      softtek: '#E4c500',
      heb: '#fbc02d',
      sandbox: '#E4c500',
    })
  }
  return color
}

/**
 * obtain string depending on the notification message
 * @param {ype,who, target} NotificationObject
 */
export const getNotificationMessage = ({ type, who, target }): string => {
  if (type === NotificationType.Comment) {
    return `${who} ${_i('comentó en tu idea')} ${target}`
  } else if (type === NotificationType.TagComment) {
    return `${who} ${_i('te etiquetó en un comentario:')} ${target}`
  } else if (type === NotificationType.Follow) {
    return `${who} ${_i('comenzó a seguirte')}`
  } else if (type === NotificationType.Reaction) {
    return `${who} ${_i('reaccionó a tu idea')} ${target}`
  } else if (type === NotificationType.Evolution) {
    return `${_i('Tu idea')} ${target} ${_i('ha evolucionado')}`
  } else if (type === NotificationType.Linked) {
    return `${_i('Tu idea')} ${target} ${_i('ha sido enlazada al reto')} ${who}`
  } else if (type === NotificationType.Challengeevolution) {
    return `${_i('Tu idea')} ${target} ${_i(
      'ha evolucionado en el reto'
    )} ${who}`
  } else if (type === NotificationType.Reply) {
    return `${who} ${_i('respondió tu mensaje  en la idea')} ${target}`
  } else if (type === NotificationType.Flagged) {
    return `${_i('La idea')} ${target} ${_i(
      'ha sido reportada como contenido inapropiado'
    )}`
  } else if (type === NotificationType.UserPost) {
    return `${who} ${_i('creó una nueva idea!')}`
  } else if (type === NotificationType.UserJudge) {
    return `${_i('Has sido designado como juez en el Challenge')} "${who}"`
  } else if (type === NotificationType.AdminLinked) {
    return `${_i('La idea')} "${target}" ${_i(
      'ha sido enlazada al reto'
    )} "${who}"`
  } else if (type === NotificationType.LikeReaction) {
    return `${who} ${_i('reaccionó a tu idea')} ${target}`
  } else if (type === NotificationType.Featured) {
    return `${'Tu idea'} ${target} ${_i('ha sido destacada!')}`
  } else if (type === NotificationType.TagIdea) {
    return `${_i('¡Nueva idea personalizada para ti!')}`
  } else {
    return ''
  }
}

/**
 * Perform a Title Case in a String
 * @param {string} msg
 */
const toProperCase = function(msg: string) {
  return msg.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

/**
 * app service to collection
 * @param {string} instance
 */
export const appServiceToFB = (instance: string): string => {
  if (instance === 'heb') return 'HEB'
  if (instance === 'softtekbrazil') return 'SofttekBrazil'
  if (instance === 'softtekautomation') return 'SofttekAutomation'
  if (instance === 'caixabank') return 'CaixaBank'
  return toProperCase(instance)
}
