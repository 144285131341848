//Cover List Context Declaration
import React, { useReducer } from 'react'
import { DowFields } from '../../../../generated/graphql'

const initState = {
  dowFields: {
    LabTechnician: '',
    InitialDate: null,
    FinalDate: null,
    MonthAndYear: null,
    ActivityType: '',
    ProjectId: 0,
    ProjectActivity: '',
    DedicatedTime: 0,
    TotalWorkedHours: 0,
    DedicateTime: 0,
    ProjectTitle: '',
    BusinessSegment: '',
    BusinessPriority: '',
    TechComplex: '',
    Status: '',
    ClosureDate: null,
    TypeProject: '',
    LabProjectLeader: '',
    TSYD: '',
    CRM: 0,
    NPI: 0,
    DelayReasons: '',
    GeneralComments: '',
    Code: 0,
    RegistrationDate: null,
    EstimatedProjectDuration: 0,
    TotalProjectDuration: 0,
  } as DowFields,
  dispatch: null,
}

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        // merge both objects using rest operator
        dowFields: {
          ...state.dowFields,
          ...action.newFields,
        },
      }
    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const DOWQuestionsContext = React.createContext(initState)
//Provider Init
function DOWQuestionsProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <DOWQuestionsContext.Provider
      value={{
        dowFields: state.dowFields,
        dispatch,
      }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </DOWQuestionsContext.Provider>
  )
}
//Export conext and provider
export { DOWQuestionsContext, DOWQuestionsProvider }
