import React from 'react'
import styled from 'styled-components'
import MovieCreationRounded from '@material-ui/icons/MovieCreationRounded'
import GraphicEqRounded from '@material-ui/icons/GraphicEqRounded'
import FileCopyRounded from '@material-ui/icons/FileCopyRounded'
import CodeRounded from '@material-ui/icons/CodeRounded'
import DescriptionRounded from '@material-ui/icons/DescriptionRounded'
import ChromeReaderModeRounded from '@material-ui/icons/ChromeReaderModeRounded'
import LibraryAddRounded from '@material-ui/icons/LibraryAddRounded'
import LandscapeRounded from '@material-ui/icons/LandscapeRounded'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    color: 'white',
    fontSize: '34px',
    alignItems: 'center',
    margin: '0 auto',
    position: 'relative' as 'relative',
    top: '10px',
  },
}))

const FileBox = styled.div<{ margin: boolean }>`
  border-radius: 6px;
  background: #3387eb;
  -webkit-box-shadow: 0px 0px 42px -26px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 42px -26px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 42px -26px rgba(0, 0, 0, 0.75);
  height: 55px;
  width: 55px;
  margin-left: ${props => (props.margin ? '16px' : '0px')};
  margin-bottom: ${props => (props.margin ? '12px' : '0px')};
  min-width: 55px;
  min-height: 55px;
`

interface Props {
  fileName: string
  margin?: boolean
}

const FilePreviewIcon: React.FC<Props> = ({ fileName, margin = true }) => {
  // @ts-ignore
  const classes = useStyles()
  //detect file extension
  const filetype_img = /\.(jpg|jpeg|gif|png|tiff|tif)+$/i.exec(fileName)
  const filetype_vid = /\.(mp4|m4a|m4v|f4v|f4a|m4b|m4r|f4b|mov3gp|3gp2|wmv|asf|webm|flv|avi|wmv)+$/i.exec(
    fileName
  )
  const filetype_audio = /\.(wav|aiff|mp3|aac|ogg|wma|flac)+$/i.exec(fileName)
  const filetype_code = /\.(js|java|cpp|ino|py|cs|html|css)+$/i.exec(fileName)
  const filetype_doc = /\.(doc|docx)+$/i.exec(fileName)
  const filetype_read = /\.(pdf)+$/i.exec(fileName)
  //special add format for input format
  const filetype_add = /\.(addfile)+$/i.exec(fileName)

  if (filetype_img != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#2c75ea' }}>
        <LandscapeRounded className={classes.root} />
      </FileBox>
    )
  } else if (filetype_vid != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#7332d3' }}>
        <MovieCreationRounded className={classes.root} />
      </FileBox>
    )
  } else if (filetype_audio != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#31b21a' }}>
        <GraphicEqRounded className={classes.root} />
      </FileBox>
    )
  } else if (filetype_code != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#d8b229' }}>
        <CodeRounded className={classes.root} />
      </FileBox>
    )
  } else if (filetype_doc != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#29b8d8' }}>
        <DescriptionRounded className={classes.root} />
      </FileBox>
    )
  } else if (filetype_read != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#db5723' }}>
        <ChromeReaderModeRounded className={classes.root} />
      </FileBox>
    )
  } else if (filetype_add != null) {
    return (
      <FileBox margin={margin} style={{ backgroundColor: '#727272' }}>
        <LibraryAddRounded className={classes.root} />
      </FileBox>
    )
  }
  return (
    <FileBox margin={margin} style={{ backgroundColor: '#cbd11b' }}>
      <FileCopyRounded className={classes.root} />
    </FileBox>
  )
}

export default FilePreviewIcon
