import React, { useState, useEffect } from 'react'
import {
  Challenge,
  useChallengesQuery,
  Idea,
  LinkIdeaComponent,
  useMeQuery,
  AdditionalQuestion,
  AdditionalQuestionType,
  AdditionalQuestionAnswers,
  AnswerInput,
} from '../../../../generated/graphql'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Loading from '../../../../components/Loading'
import IconButton from '@material-ui/core/IconButton'
import LinkIcon from '@material-ui/icons/Link'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  CircularProgress,
  ListItemText,
  Select,
  InputBase,
  MenuItem,
  withMobileDialog,
  ListItemIcon,
} from '@material-ui/core'
import BallotIcon from '@material-ui/icons/Ballot'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import { useStyles } from './styles'
import { concatenateSASOrNot } from '../../../../Utils'
import { _i } from '../../../../Utils/lang/i18n'
import UnlinkIdea from './UnlinkIdea'
import InputField from '../../../../components/NewIdea/components/InputField/InputField'
import Input from '../../../../components/Input'
interface ContextState {
  currentStep: 'query' | 'question' | 'additionalQuestions'
  selected: Challenge | null
}

const initialState: ContextState = {
  currentStep: 'query',
  selected: null,
}

interface ContextInterface {
  setState: React.Dispatch<React.SetStateAction<ContextState>>
  state: ContextState
  handleClose: () => void
  idea: Idea
  handleSubmit: () => void
}

const initialContextState: ContextInterface = {
  setState: null,
  state: initialState,
  handleClose: null,
  idea: null,
  handleSubmit: null,
}

const LinkIdeaContext = React.createContext<ContextInterface>(
  initialContextState
)

interface Props {
  idea: Idea
  fullScreen: boolean
  openIdea: boolean
  handleSubmit: () => void //React.FormEvent<HTMLFormElement>
  handleCancel: () => void
}

const LinkIdea: React.FC<Props> = ({
  idea,
  fullScreen,
  openIdea,
  handleSubmit,
  handleCancel,
}) => {
  const [state, setState] = React.useState<ContextState>(initialState)
  const [open, setOpen] = React.useState(openIdea)

  if (idea.challenge) {
    return <UnlinkIdea idea={idea} />
  }

  const handleClose = () => {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
      handleCancel()
      setState({ currentStep: 'query', selected: null })
    }
  }
  // @ts-ignore
  const classes = useStyles()
  return (
    <LinkIdeaContext.Provider
      value={{ state, setState, handleClose, idea, handleSubmit }}>
      {true && !openIdea ? (
        <MenuItem
          aria-label="link idea to challenge"
          onClick={() => {
            setOpen(true)
          }}
          className={classes.menuItem}>
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          {_i('Enlazar a un reto')}
        </MenuItem>
      ) : (
        !openIdea && (
          <Tooltip title={_i('Enlazar a un reto')}>
            <IconButton
              aria-label="link idea to challenge"
              onClick={() => {
                setOpen(true)
              }}>
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )
      )}
      <Dialog
        open={open}
        maxWidth="sm"
        fullScreen={fullScreen}
        fullWidth
        //onClose={handleClose}
        aria-labelledby="draggable-dialog-title">
        {state.currentStep === 'query' && <QueryAndSelectChallenge />}
        {state.currentStep === 'question' && <Questions />}
        {state.currentStep === 'additionalQuestions' && <AdditionalQuestions />}
      </Dialog>
    </LinkIdeaContext.Provider>
  )
}

const QueryAndSelectChallenge = () => {
  const { handleClose, setState, idea } = React.useContext(LinkIdeaContext)
  const [selected, setSelected] = React.useState<Challenge>(null)
  const { data: userData, loading: userLoading } = useMeQuery()
  const { data, loading } = useChallengesQuery()
  // @ts-ignore
  const classes = useStyles()

  if (loading || userLoading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    )
  }
  if (!data.challenges) {
    return (
      <React.Fragment>
        <LinkOffIcon className={classes.linkOff} />
        <Typography variant="subtitle1" className={classes.typowarning}>
          {_i('No hay retos disponibles')}
        </Typography>
      </React.Fragment>
    )
  }
  return (
    <>
      {data.challenges.length !== 0 && (
        <Typography
          variant="h4"
          style={{ fontFamily: 'InnoArial', padding: '12px 24px' }}
          id="draggable-dialog-title">
          {_i('Selecciona un Reto')}
        </Typography>
      )}

      <DialogContent>
        <DialogContentText component={'div'}>
          <List>
            {data.challenges.map(challenge => (
              <ListItem
                button
                selected={selected ? selected.id === challenge.id : false}
                onClick={() => {
                  if (new Date(challenge.period.dueDate) >= new Date()) {
                    // @ts-ignore
                    setSelected(challenge)
                  }
                }}
                key={challenge.id}>
                <div className={classes.challengeBox}>
                  <div className={classes.coverInfo}>
                    {challenge.cover ? (
                      <img
                        src={concatenateSASOrNot(
                          userData.me.sas.token,
                          challenge.cover,
                          'small'
                        )}
                        className={classes.cover}
                      />
                    ) : (
                      <BallotIcon className={classes.iconChallenge} />
                    )}
                  </div>
                  <div className={classes.challengeInfo}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        color:
                          new Date(challenge.period.dueDate) < new Date()
                            ? '#4d4d4d'
                            : 'black',
                      }}>
                      {challenge.name}
                      {new Date(challenge.period.dueDate) < new Date() &&
                        ' (Finalizado)'}
                    </Typography>
                    <Typography variant="body1" style={{ color: '#adadad' }}>
                      {challenge.shortDescription ? (
                        challenge.shortDescription.length > 100 ? (
                          challenge.shortDescription.slice(0, 100) + '...'
                        ) : (
                          challenge.shortDescription
                        )
                      ) : (
                        <em>{_i('No hay una descripción disponible')}</em>
                      )}
                    </Typography>
                  </div>
                </div>
              </ListItem>
            ))}
            {data.challenges.length === 0 && (
              <React.Fragment>
                <LinkOffIcon className={classes.linkOff} />
                <Typography variant="subtitle1" className={classes.typowarning}>
                  {_i('No hay retos disponibles')}
                </Typography>
              </React.Fragment>
            )}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{ fontFamily: 'InnoArial', borderRadius: '10px' }}>
          {_i('Cancelar')}
        </Button>
        {/* } */}
        <Button
          style={{ fontFamily: 'InnoArial' }}
          disabled={!selected}
          onClick={() =>
            setState(prev => ({ ...prev, currentStep: 'question', selected }))
          }
          variant="outlined"
          color="primary">
          {_i('Siguiente')}
        </Button>
      </DialogActions>
    </>
  )
}

const Questions = () => {
  const { state, handleClose, setState, idea, handleSubmit } = React.useContext(
    LinkIdeaContext
  )
  // check if there is no question to answer, in order to declare a next step
  const nextStep =
    state.selected.additionalQuestions &&
    state.selected.additionalQuestions.length > 0
  // @ts-ignore
  const classes = useStyles()
  return (
    <LinkIdeaComponent onCompleted={() => location.reload()}>
      {(mutation, { loading }) => {
        const linkIdea = () => {
          console.log('linkIdea')
          mutation({
            variables: {
              challengeId: state.selected.id,
              ideaId: idea.id,
              additionalQuestions: {
                answers: [],
              },
            },
          })
        }
        if (loading) {
          return (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )
        }
        return (
          <>
            <Typography
              variant="h4"
              style={{ fontFamily: 'InnoArial', padding: '12px 24px' }}
              id="draggable-dialog-title">
              {_i('Revisa si tu idea obedece los siguientes requerimientos')}
            </Typography>
            <DialogContent>
              <DialogContentText component={'div'}>
                <List>
                  {state.selected.ideaQuestions.map(c => (
                    <ListItem
                      key={c.label}
                      style={{
                        fontSize: '1.2em',
                        fontFamily: 'InnoArial',
                      }}>{`- ${c.label}`}</ListItem>
                  ))}
                </List>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                style={{ fontFamily: 'InnoArial', borderRadius: '10px' }}>
                {_i('Cancelar')}
              </Button>
              <Button
                variant={nextStep ? 'outlined' : 'contained'}
                onClick={() => {
                  console.log(nextStep)
                  if (nextStep) {
                    setState(prev => ({
                      ...prev,
                      currentStep: 'additionalQuestions',
                    }))
                  } else {
                    linkIdea()
                  }
                }}
                style={{ fontFamily: 'InnoArial' }}
                color="primary">
                {nextStep ? _i('Siguiente') : _i('Enlazar')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </LinkIdeaComponent>
  )
}
const AdditionalQuestions = () => {
  const { state, handleClose, setState, idea } = React.useContext(
    LinkIdeaContext
  )
  const [answers, setAnswers] = useState<any[]>([])
  // initialize answers with empty strings
  useEffect(() => {
    if (state.selected.additionalQuestions) {
      setAnswers(Array(state.selected.additionalQuestions.length).fill(''))
    }
  }, [state])

  // add answer to the global state
  const handleAddAnswer = (index: number, newAnswer: any) => {
    setAnswers(list =>
      list.reduce((accum, answer, i) => {
        if (i === index) {
          accum.push(newAnswer)
        } else {
          accum.push(answer)
        }
        return accum
      }, [])
    )
  }
  // @ts-ignore
  const classes = useStyles()
  return (
    <LinkIdeaComponent onCompleted={() => location.reload()}>
      {(mutation, { loading }) => {
        const linkIdea = () => {
          mutation({
            variables: {
              challengeId: state.selected.id,
              ideaId: idea.id,
              additionalQuestions: {
                answers: answers.map((answer, key) => {
                  return {
                    id: `${key}`,
                    answer,
                  } as AnswerInput
                }),
              },
            },
          })
        }
        if (loading) {
          return (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )
        }
        return (
          <>
            <Typography
              variant="h4"
              style={{ fontFamily: 'InnoArial', padding: '12px 24px' }}
              id="draggable-dialog-title">
              {_i('Preguntas Adicionales')}
            </Typography>
            <DialogContent>
              <DialogContentText component={'div'}>
                <List>
                  {state.selected.additionalQuestions.map((question, key) => (
                    <AdditionalQuestionItem
                      key={key}
                      i={key}
                      question={question}
                      handleAddAnswer={handleAddAnswer}
                    />
                  ))}
                </List>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                style={{ fontFamily: 'InnoArial', borderRadius: '10px' }}>
                {_i('Cancelar')}
              </Button>
              <Button
                variant="contained"
                onClick={linkIdea}
                style={{ fontFamily: 'InnoArial' }}
                color="primary">
                {_i('Enlazar')}
              </Button>
            </DialogActions>
          </>
        )
      }}
    </LinkIdeaComponent>
  )
}

interface AQIProps {
  question: AdditionalQuestion
  i: number
  handleAddAnswer: any
}
const AdditionalQuestionItem: React.FC<AQIProps> = ({
  question,
  i,
  handleAddAnswer,
}) => {
  const [answer, setAnswer] = useState<string | number>(
    question.selectList[0] || ''
  )
  // get text depending on qquestion type to determine the action
  const getSecondaryText = (type: AdditionalQuestionType): string => {
    if (type === AdditionalQuestionType.Number) {
      return ''
    } else if (type === AdditionalQuestionType.Text) {
      return _i('Introduce tu respuesta en el siguiente campo')
    } else {
      return _i('Elige una opción')
    }
  }
  useEffect(() => {
    if (!question) return
    if (question.type === AdditionalQuestionType.Number) {
      setAnswer(0)
    }
  }, [question])
  const handleAnswer = e => {
    e.preventDefault()
    setAnswer(e.target.value)
    handleAddAnswer(i, e.target.value)
  }

  // @ts-ignore
  const classes = useStyles()
  return (
    <>
      <ListItem style={{ fontSize: '1.2em' }}>
        <ListItemText
          primary={question.text}
          secondary={getSecondaryText(question.type)}
        />
      </ListItem>
      <ListItem style={{ fontSize: '1.2em' }}>
        {question.type === AdditionalQuestionType.Text && (
          <InputField
            value={answer}
            onChange={handleAnswer}
            type="text"
            style={{ borderRadius: '10px', padding: '5px 12px', width: '100%' }}
            placeholder={_i('Agregar requerimiento...')}
          />
        )}
        {question.type === AdditionalQuestionType.Number && (
          <Input
            type="number"
            name="Máxima Puntuación"
            value={answer}
            onChange={handleAnswer}
            inputProps={{
              input: 'number',
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        )}
        {question.type === AdditionalQuestionType.Select && (
          <Select
            value={answer}
            onChange={handleAnswer}
            style={{ padding: '5px 12px', borderRadius: '10px', marginLeft: 0 }}
            placeholder={_i('Selecciona una Respuesta')}
            input={
              <InputBase
                placeholder={_i('Selecciona una Respuesta')}
                className={classes.input}
              />
            }>
            {question.selectList &&
              question.selectList.map((selectText, i) => (
                <MenuItem key={i} value={selectText}>
                  {selectText}
                </MenuItem>
              ))}
          </Select>
        )}
      </ListItem>
    </>
  )
}
export default withMobileDialog({ breakpoint: 'sm' })(LinkIdea)
