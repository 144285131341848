export const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage:
      'linear-gradient(68deg,#2D93F8 0%, #309BF9 10%, #27D1E5 35%, #12E596 54%, #06F70E 80%, #ECFC05 100%)',
  },
  HEB_custom_button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage: 'linear-gradient(68deg,#dc291e 0%, #ea6d65 100%)',
  },
  ABANCA_custom_button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage:
      'linear-gradient(90deg, rgba(8,133,201,1) 0%, rgba(4,170,203,1) 85%)',
  },
  caixaBank_custom_button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage: 'linear-gradient(68deg,#CFC6B4 0%, #CFC6B4 0%)',
  },

  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  loginButton: {
    display: 'block',
    margin: '10px auto',
    width: '220px',
    fontFamily: 'InnoArial',
    padding: '10px 10px',
    marginBottom: '20px',
    boxShadow: 'none',
  },
})
