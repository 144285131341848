import React, { useContext } from 'react'
import { DescriptionContext } from './CKEditorContext'
interface CKProps {
  initState: string
  validation: (e: boolean, texto: string) => void
  api: () => void
}
const CKEditorComponent: React.FC<CKProps> = ({
  initState,
  validation,
  api,
}) => {
  const { dispatch } = useContext(DescriptionContext)

  if (typeof window === 'undefined') {
    return (
      <div>
        <p>Loading...</p>
      </div>
    )
  } else {
    // eslint-disable-next-line
    const CKEditor = require('@ckeditor/ckeditor5-react')
    // eslint-disable-next-line
    const ClassicEditor = require('@ckeditor/ckeditor5-build-classic')

    return (
      <CKEditor
        editor={ClassicEditor}
        data={initState}
        onInit={() => {
          // You can store the "editor" and use when it is needed.
          dispatch({ type: 'update', description: initState })
          //OVerride ck editor styles
          // const editor_window = document.getElementsByClassName(
          //   'ck ck-editor__main'
          // )
          // editor_window[0].setAttribute(
          //   'style',
          //   'max-height:140px;overflow:scroll;min-height:120px;'
          // )
          const ck_window = document.getElementsByClassName(
            'ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline'
          )
          ck_window[0].setAttribute(
            'style',
            'max-height:140px;overflow:scroll;min-height:120px;'
          )
        }}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'Link',
            '|',
            'mediaEmbed',
          ],
          disableNativeSpellChecker: true,
          removePlugins: ['Scayt'],
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        onBlur={api}
        onChange={(event, editor) => {
          if (
            process.env.REACT_APP_SERVICE === 'heb' ||
            process.env.REACT_APP_SERVICE === 'softtek'
          )
            if (editor.getData().length > 0) {
              validation(true, editor.getData())
            } else {
              validation(false, '')
            }
          const descriptionData = editor.getData()
          dispatch({ type: 'update', description: descriptionData })
          const ck_window = document.getElementsByClassName(
            'ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline'
          )
          ck_window[0].setAttribute('style', 'min-height:120px;')
        }}
      />
    )
  }
}
export default CKEditorComponent
