import { Configuration, PublicClientApplication } from '@azure/msal-browser'

const msalConfig: Configuration = {
  auth: {
    clientId: '9fcc2673-7247-4f9b-8730-6fde3de2fb2a',
    authority:
      'https://login.microsoftonline.com/5df31d35-3ba9-481e-a3c8-ff9be3ee783b/',
    redirectUri: process.env.REACT_APP_REPLY_URL,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
}

const msalInstance = new PublicClientApplication(msalConfig)
const initialize = async () => {
  await msalInstance.initialize()
  console.log(process.env.REACT_APP_REPLY_URL)
}
initialize()
export default msalInstance
