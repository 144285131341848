import React from 'react'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './styles'

interface Props {
  onClick: any
  classes: any
}

const LoginButton: React.FC<Props> = ({ classes, onClick }) => {
  return (
    <Button
      onClick={onClick}
      color="primary"
      variant="contained"
      className={classes.button}>
      Acceder
    </Button>
  )
}

export default withStyles(styles)(LoginButton)
