import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    width: '44px',
    height: '44px',
    borderRadius: '30px',
    backgroundColor: '#ECEDF1',
    objectFit: 'cover' as 'cover',
  },
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    fontSize: '16px',
    padding: '10px 16px',
  },
}))
