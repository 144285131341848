import React from 'react'
import { Avatar } from '@material-ui/core'
import { useStyles } from './styles'
//every idea component is here
interface Props {
  username: string
}
const UserPlaceholder = ({ username, ...props }: Props & any) => {
  // generate pseudorandom color for each user letter
  const generateColor = (user: string) => {
    if (user.toLowerCase()[0].charCodeAt(0) % 11 === 0) {
      return '#f44336'
    } else if (user.toLowerCase()[0].charCodeAt(0) % 10 === 0) {
      return '#c51162'
    } else if (user.toLowerCase()[0].charCodeAt(0) % 7 === 0) {
      return '#c51162'
    } else if (user.toLowerCase()[0].charCodeAt(0) % 5 === 0) {
      return '#82b1ff'
    } else if (user.toLowerCase()[0].charCodeAt(0) % 4 === 0) {
      return '#303f9f'
    } else if (user.toLowerCase()[0].charCodeAt(0) % 3 === 0) {
      return '#1976d2'
    } else if (user.toLowerCase()[0].charCodeAt(0) % 2 === 0) {
      return '#00796b'
    } else {
      return '#5d4037'
    }
  }
  // @ts-ignore
  const classes = useStyles()
  return (
    <Avatar {...props} style={{ backgroundColor: generateColor(username) }}>
      {username[0]}
    </Avatar>
  )
}

export default UserPlaceholder
