import { FieldProps } from 'formik'
import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
const styles = () => ({
  input: {
    backgroundColor: '#ECEDF1',
    border: 'none',
    borderRadius: '12px',
    padding: '5px 7px',
    fontSize: '14px',
    color: '#727272',
    width: '100%',
  },
})

const InputField = ({
  placeholder,
  field,

  ...props
}: FieldProps & any) => {
  // const [input, handleInput] = useState('')
  const { classes } = props
  const [keyDown, setKey] = useState(false)
  let flag = false
  return (
    <div style={{ marginBottom: '12px', width: '100%' }}>
      <InputBase
        {...field}
        {...props}
        placeholder={placeholder}
        className={classes.input}
        label={placeholder}
        id="name"
        onBlur={e => {
          if (flag) {
            var x = document.getElementById('name')
            x.focus()
          }
        }}
        onKeyDown={e => {
          const regex = /[a-zA-Z]/g
          const found = e.key.match(regex)
          if (found !== null)
            if (found.length === 1) {
              //const newString = shortDescription + e.key
              // dispatch({ type: 'update', shortDescription: newString })
              setKey(true)
              flag = true
            }
        }}
        onKeyUp={e => {
          flag = false
          setKey(false)
        }}
      />
    </div>
  )
}
export default withStyles(styles)(InputField)
