import React, { useContext, useRef } from 'react'
import { useMSAuth } from '.'
import { useFBAuth } from '.'
import useAuthCode from './hooks/useAuthCode'
import { messaging } from '../firebase'
import { useUpdateNotificationTokenMutation } from '../generated/graphql'
import NotificationSnack from '../components/NotificationSnack'
import useRouter from 'use-react-router'
import { Grid, CircularProgress } from '@material-ui/core'
import {
  NotificationProvider,
  NotificationContext,
} from '../layouts/MainLayout/TopBar/Notifications/NotificationContext'

interface FCMNotification {
  body: string
  click_action: string
  icon: string
  title: string
  type: string
  who: string
  target: string
}

const AuthWrapperContent: React.FC = ({ children }) => {
  const { history } = useRouter()
  const authStateMS = useMSAuth()
  const authStateFB = useFBAuth()
  const mutation = useUpdateNotificationTokenMutation()
  const isMounted = useRef(null)
  const [notification, setNotification] = React.useState<FCMNotification>(null)
  const {
    state: { notificationList },
    dispatch: notificationDispatch,
  } = useContext(NotificationContext)
  useAuthCode()
  // clean url to obtain target id
  const cleanUrl = (url: string): string => {
    return url.match(/innotek.ai\/.*/)[0].replace('innotek.ai', '')
  }
  // This effect is to listen for notifications or to set the notif token
  React.useEffect(() => {
    isMounted.current = true
    if (authStateMS === 'not_logged' && authStateFB === 'not_logged') {
      history.push('/login')
    }
    let susbcribe = () => {}
    if (messaging) {
      susbcribe = messaging.onMessage(obj => {
        // console.log(obj.notification)
        // console.log(obj.data)
        //  set reverse flag in true to add  on the start of the list
        notificationDispatch({
          type: 'add',
          notification: {
            date: new Date(),
            who: {
              id: obj.data.who,
              name: obj.data.who,
            },
            read: false,
            type: obj.data.type,
            target: {
              //  we introduce the notitication subscription to pass the whole link to the gotoPage () function
              //  in the notification button component
              // @ts-ignore
              notificationSubscription: true,
              id: cleanUrl(obj.notification.click_action),
              name: obj.data.target,
            },
          },
          reverse: true,
        })
        //  also create a mark a s read artificial variable
        //this variable is created by the foreign notification context
        notificationDispatch({
          type: 'set_read',
          markSubscriptionNotification: true,
        })
        setNotification({ ...obj.notification, ...obj.data } as FCMNotification)
      })
    }
    async function ask() {
      try {
        await messaging.requestPermission()
        const token = await messaging.getToken()
        mutation({ variables: { reset: false, token } })

        return token
      } catch (error) {
        //do nothing
      }
    }
    if (
      (authStateMS === 'logged' || authStateFB === 'logged') &&
      messaging &&
      isMounted.current
    ) {
      ask()
    }
    return function() {
      susbcribe()
      isMounted.current = false
    }
  }, [authStateMS, authStateFB])

  if (authStateMS === 'logged' || authStateFB === 'logged') {
    return (
      <>
        {children}
        {notification && (
          <NotificationSnack
            notification={notification}
            onClose={() => setNotification(null)}
          />
        )}
      </>
    )
  } else if (
    authStateMS === 'initial' ||
    authStateMS === 'loading' ||
    authStateFB === 'initial' ||
    authStateFB === 'loading'
  ) {
    return (
      <div
        style={{
          marginTop: '40px',
        }}>
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      </div>
    )
  } else {
    return <p>Esperando inicio de Sesión</p>
  }
}

const AuthWrapper = ({ children }) => {
  return (
    <AuthWrapperContent>
      <NotificationProvider>{children}</NotificationProvider>
    </AuthWrapperContent>
  )
}
export default AuthWrapper
