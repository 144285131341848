import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  grid: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'white',
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  root_grid: {
    padding: '16px',
    boxShadow: '0px 0px 18px -3px rgba(112,112,112,0.88)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '1450px',
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '4px',
    },
  },
}))
