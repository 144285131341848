import React from 'react'
import { Notification } from '../../../../generated/graphql'

interface Action {
  type: Actions
  reverse?: boolean
  notification?: Notification
  markSubscriptionNotification?: boolean
}

type Actions = 'add' | 'set_read' | 'reset'

export interface State {
  notificationList: Notification[]
  markSubscriptionNotification: boolean
}

let reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'add':
      if (action.reverse) {
        return {
          ...state,
          notificationList: [action.notification, ...state.notificationList],
        }
      } else {
        return {
          ...state,
          notificationList: [...state.notificationList, action.notification],
        }
      }

    case 'set_read':
      return {
        ...state,
        markSubscriptionNotification: action.markSubscriptionNotification,
      }
    case 'reset':
      return { ...state, notificationList: [] }
    default:
      return { ...state }
  }
}

interface Context {
  state: State
  dispatch: React.Dispatch<Action>
}

const initialState: State = {
  notificationList: [],
  markSubscriptionNotification: false,
}

const NotificationContext = React.createContext<Context>({
  state: initialState,
  dispatch: null,
})
function NotificationProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </NotificationContext.Provider>
  )
}

export { NotificationContext, NotificationProvider }
