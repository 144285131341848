import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    position: 'relative' as 'relative',
  },
  navBar: {
    backgroundColor: theme.palette.background.default,
    color: '#000',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  },
  fullHeight: {
    height: '100%',
  },
  content: {
    marginTop: '70px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '100px',
    },
  },
}))
