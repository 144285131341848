import React from 'react'

// Material ui
import Grid from '@material-ui/core/Grid'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Challenges from './Challenges'
import TrendingTags from './TrendingTags'
import TrendingIdeaTypes from './TrendingIdeaTypes'

const useStyles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: '#ECEDF1',
    flexWrap: 'nowrap' as 'nowrap',
    padding: '16px 6px',
  },
  side: {
    maxWidth: '350px',

    width: '24%',
    position: 'fixed' as 'fixed',
    maxHeight: '100vh',
    overflowY: 'scroll' as 'scroll',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      position: 'relative' as 'relative',
      width: '100%',
      maxWidth: 'inherit',
    },
  },
}))

interface Props {}

const Sidebar: React.FC<Props> = () => {
  // @ts-ignore
  const classes = useStyles()
  return (
    <Grid container direction="column" spacing={0} className={classes.side}>
      <Grid container>
        {/* challenges card */}
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{ paddingBottom: 0 }}>
          <Challenges />
        </Grid>
        {/* tranding tags card */}
        <Grid item xs={12} className={classes.root}>
          <TrendingTags />
        </Grid>
        {/* trending idea types */}
        <Grid item xs={12} className={classes.root} style={{ paddingTop: 0 }}>
          <TrendingIdeaTypes />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Sidebar
