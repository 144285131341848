//obtain instance values depending on the instance
//simple function to derive styles depedning on the actual page
export interface layoutOpt {
  heb: any
  softtek: any
  sandbox?: any
  osde?: any
  softtekbrazil?: any
  abanca?: any
  cambiahealth?: any
  socofar?: any
  dow?: any
  vector?: any
  metlife?: any
  lala?: any
  chubb?: any
  caixabank?: any
  softtekautomation?: any
  benavides?: any
  vegcelerate?: any
}
export const getInstanceLayout = (layoutOptions: layoutOpt) => {
  return layoutOptions[process.env.REACT_APP_SERVICE] || layoutOptions.softtek
}
