export const uploadFile = async (files, ideaId) => {
  //create form
  const formData = new FormData()
  formData.append('ideaId', ideaId) //append idea id
  for (let i = 0; i < files.length; i++) {
    let file: File = files[i].file.file
    formData.append('files', file) //append files
  }

  //Wait for response
  const attachmentResponse = await fetch(process.env.REACT_APP_UPLOAD_URL, {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .catch(() => {
      // do nothing
    })
  return attachmentResponse
}
