import { langTypes, lcmsgs } from './lcMsgs'

export const _i = (message: string): string => {
  var langConfig = localStorage.getItem('lang')
  if (
    langConfig !== 'es' &&
    (process.env.REACT_APP_SERVICE === 'heb' ||
      process.env.REACT_APP_SERVICE === 'vector' ||
      process.env.REACT_APP_SERVICE === 'vegcelerate')
  ) {
    let lang: langTypes = 'es'
    langConfig = localStorage.getItem(lang)
  }
  if (!langConfig || !lcmsgs[message]) {
    return message
  }
  //Second step, obtain the message
  return lcmsgs[message][langConfig] || ''
}

export const getLang = (): langTypes => {
  let lang: langTypes = 'en'
  //try to get from storage the internationalization setting
  const langConfig: any = localStorage.getItem('lang')

  if (!langConfig) {
    //if local cookie is not found, then check browser information

    const languageBrowser =
      // @ts-ignore
      window.navigator.userLanguage || window.navigator.language
    if (languageBrowser && languageBrowser.length >= 2) {
      //if first letter determine that we are using spanish, then use spanish
      //if not, use english
      lang = languageBrowser.slice(0, 2)
      if (lang !== 'es' && lang !== 'en' && lang !== 'pt') {
        lang = 'en'
      }
    }
    //change o spanish if heb instance is active
    if (
      !languageBrowser &&
      (process.env.REACT_APP_SERVICE === 'heb' ||
        process.env.REACT_APP_SERVICE === 'vector' ||
        process.env.REACT_APP_SERVICE === 'vegcelerate')
    ) {
      lang = 'es'
    }
    return lang
  } else if (
    process.env.REACT_APP_SERVICE === 'heb' ||
    process.env.REACT_APP_SERVICE === 'vector' ||
    process.env.REACT_APP_SERVICE === 'vegcelerate'
  )
    lang = 'es'

  return langConfig
}
//change lang with the cookie
export const setLang = (lang: langTypes): void => {
  localStorage.setItem('lang', lang)
}
