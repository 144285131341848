const Departamentos = [
  '',
  'Contabilidad',
  'Recursos humanos',
  'Operaciones',
  'Comercial',
]
const Manual = ['', 'Al completo', 'Bastante', 'Poco', 'Nada']
const Frecuancia = [
  '',
  'Continuo',
  'Multidiario',
  'Diario',
  'Semanal',
  'Mensual',
  'Anual',
]
const VolumetriasUM = ['Día', 'Mes', 'Año']
const DuracionUM = ['Segundos', 'Minutos', 'Horas']
const Consistencia = ['', 'Si', 'No']
const Reglas = ['', 'Si', 'No', 'Basadas en la experiencia del usuario']
const DatosEstandarizados = [
  '',
  'Si',
  'Si, pero es necesaria una transformación simple',
  'Si, pero es necesaria una transformación grande ',
  'No',
]
const DatosLegibles = [
  '',
  'Fácil (Excel estructurados,aplicación, sistema web)',
  'Media (PDF, texto desestructurado)',
  'Difícil (Imagen,PDF no nativo)',
  'Crítico (Escritura manuscrita, sonido)',
]
const ProcedimientoHabitual = ['', '>80%', '60-80%', '<60%']
const CambiosProceso = [
  '',
  'No',
  'Si, a medio plazo (anualmente)',
  'Si, de manera frecuente',
]
const CambioSistema = [
  '',
  'No',
  'Si, a medio plazo (anualmente)',
  'Si, de manera frecuente',
]

export default {
  Departamentos,
  Manual,
  Frecuancia,
  VolumetriasUM,
  DuracionUM,
  Consistencia,
  Reglas,
  DatosEstandarizados,
  DatosLegibles,
  ProcedimientoHabitual,
  CambiosProceso,
  CambioSistema,
}
