import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: theme.shape.borderRadius,
    minWidth: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },
  img: {
    height: 400,
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%',
    objectFit: 'cover' as 'cover',
    borderRadius: `10px 10px 0px 0px`,
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
  },
  imgen: {
    marginLeft: '25%',
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '50%',
    objectFit: 'cover' as 'cover',
  },
  stepper: {
    backgroundColor: theme.palette.background.paper,
  },
  imagePlaceHolder: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    height: 400,
    width: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
