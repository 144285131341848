import React, { useState, useContext, useEffect } from 'react'
import {
  Grid,
  Typography,
  Select,
  InputBase,
  MenuItem,
  InputAdornment,
  Collapse,
  Button,
} from '@material-ui/core'
import InputField from '../InputField/InputField'
import { _i } from '../../../../Utils/lang/i18n'
import { useStyles, useStylesSubComponent } from './styles'
import { SofttekAutoQuestionsContext } from './context'
interface Props {
  validation: boolean
  setValidation: (value: any) => any
}
const SofttekAutoQuestions: React.FC<Props> = ({
  validation,
  setValidation,
}) => {
  const {
    softtekAutoFields: { service, platform, repositoryCode, licenseRequired },
    dispatch: softtekautoDispatch,
  } = useContext(SofttekAutoQuestionsContext)

  useEffect(() => {
    if (service !== '' && platform !== '' && repositoryCode !== '') {
      setValidation(true)
    } else {
      setValidation(false)
    }
  }, [service, platform, repositoryCode, validation])
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <SofttekAutoFormComponent
          value={service}
          onChange={e =>
            softtekautoDispatch({
              type: 'set',
              newFields: { service: e.target.value },
            })
          }
          error={_i('Add the service')}
          sendAnwser={validation}
          placeholder={_i('Service')}
          type="text"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SofttekAutoFormComponent
          value={platform}
          onChange={e =>
            softtekautoDispatch({
              type: 'set',
              newFields: { platform: e.target.value },
            })
          }
          error={_i('Add the platform used')}
          sendAnwser={validation}
          placeholder={_i('Platform Used')}
          type="text"
        />
      </Grid>

      <Grid item xs={12}>
        <SofttekAutoFormComponent
          value={repositoryCode}
          onChange={e =>
            softtekautoDispatch({
              type: 'set',
              newFields: { repositoryCode: e.target.value },
            })
          }
          error={_i('Add the Repository Code (URL)')}
          placeholder={_i('Repository Code (URL)')}
          sendAnwser={validation}
          type="text"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <SofttekAutoFormComponent
          value={licenseRequired}
          onChange={e => {
            softtekautoDispatch({
              type: 'set',
              newFields: { licenseRequired: !licenseRequired },
            })
          }}
          label={_i('License Required?')}
          placeholder={[_i('YES'), _i('NO')]}
          type="switch"
        />
      </Grid>
    </Grid>
  )
}

/**
 * Component to change input or switches depending on the incoming variable
 * the type string changes between text and switch
 */
interface SofttekAutoFormComponentProps {
  value: any
  onChange: (e: any) => any
  label: string
  type: 'switch' | 'text'
  placeholder: string
  helperText: string
  sendAnwser: boolean
  error: string
}
const SofttekAutoFormComponent: React.FC<SofttekAutoFormComponentProps &
  any> = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  sendAnwser,
  error,
  ...props
}) => {
  const classes = useStylesSubComponent()
  if (type === 'text') {
    return (
      <>
        <InputField
          value={value}
          onChange={e => {
            onChange(e)
          }}
          //type="text"
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            width: '97%',
            //maxWidth: 408,
          }}
          placeholder={placeholder}
          {...props}
        />
        {sendAnwser && value === '' ? (
          <p
            style={{
              color: 'red',
              fontSize: '10px',
              fontFamily: 'Roboto',
            }}>
            {error}
          </p>
        ) : null}
      </>
    )
  } else {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <div style={{ paddingLeft: 10 }}>
          <Grid container alignItems="center">
            <Button
              onClick={onChange}
              variant={!value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.firstOptButton}>
              {placeholder[1]}
            </Button>
            <Button
              onClick={onChange}
              variant={value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.secondOptButton}>
              {placeholder[0]}
            </Button>
          </Grid>
        </div>
      </>
    )
  }
}

export default SofttekAutoQuestions
