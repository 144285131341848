import React from 'react'
import TopBar from './TopBar'
import Hidden from '@material-ui/core/Hidden'
import FabButton from './FabButton'
import { useStyles } from './styles'

interface NavBarProps {}

const DesktopNavBar: React.FC<NavBarProps> = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {/* Top Bar */}
      <TopBar />
      {/* Content */}
      <div className={classes.content}>{children}</div>
      {/* Create Idea Fab */}
      <Hidden mdUp>
        <div
          style={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 1000,
          }}>
          <FabButton />
        </div>
      </Hidden>
    </div>
  )
}

export default DesktopNavBar
