import React from 'react'
import useReactRouter from 'use-react-router'
import { useQuery } from 'react-apollo-hooks'
import gql from 'graphql-tag'

const query = gql`
  query AuthState {
    authState @client
  }
`
const useAuthCode = () => {
  const { data } = useQuery(query)
  const { history } = useReactRouter()
  const [state, setState] = React.useState(200)
  console.log(data)
  React.useEffect(() => {
    if (data.authState === 418) {
      setState(418)
      history.push('/new-user')
    } else if (data.authState === 400) {
      setState(400)
    } else if (data.authState === 401) {
      history.push('/not-allowed')
    } else if (data.authState === 402) {
      setState(402)

      // Router.push('/refresh')
    } else if (data.authState === 500) {
      history.push('/server-error')
    }
  }, [data, history])

  return state
}

export default useAuthCode
