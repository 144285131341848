import React, { useState, useContext, useEffect } from 'react'
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  InputBase,
  MenuItem,
  InputAdornment,
  Collapse,
  Divider,
  Button,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InputField from '../InputField/InputField'
import { _i } from '../../../../Utils/lang/i18n'
import { useStyles, useStylesSubComponent } from './styles'
import { SearchUsers } from '../../../Search'
import CachedIcon from '@material-ui/icons/Cached'
import { VectorQuestionsContext } from './context'
import LocalCafeIcon from '@material-ui/icons/LocalCafe'
import SearchUserList from '../../../User/SearchUserList'
import DatePicker from '../../../DatePicker'
import { UserlistContext } from '../../../../views/NewChallenge/components/JudgeSelect/UserlistContext'
import { User } from '../../../../generated/graphql'
import Info from './vectorAnswer'
import options from '../../../../theme/typography'

interface Props {}

const VectorQuestions: React.FC<Props> = ({}) => {
  const {
    vectorFields: {
      responsable,
      departamento,
      area,
      manual,
      frecuencia,
      volumetrias,
      volumetriasUM,
      duracion,
      duracionUM,
      picosOperativos,
      informacionPicos,
      aspectos,
      consistencia,
      reglas,
      datosEstandarizados,
      datosLegibles,
      procedimientoHabitual,
      cambiosProceso,
      cambioSistema,
      NoAplicaciones,
    },
    dispatch: vectorDispatch,
  } = useContext(VectorQuestionsContext)
  const {
    Departamentos,
    Manual,
    Frecuancia,
    VolumetriasUM,
    DuracionUM,
    Consistencia,
    Reglas,
    DatosEstandarizados,
    DatosLegibles,
    ProcedimientoHabitual,
    CambiosProceso,
    CambioSistema,
  } = Info
  const [aspectosArray, setAspectosArray] = useState([])
  const [checked, setChecked] = useState([false, false, false])
  useEffect(() => {
    if (aspectos.length > 0) {
      const checkedA = checked
      aspectos.forEach(a => {
        console.log(a)
        if (a === 'Eficiencia operativa') checkedA[0] = true
        else if (a === 'Cumplimiento') checkedA[1] = true
        else if (a === 'Impacto directo en cliente') checkedA[2] = true
        setChecked(checkedA)
      })
      setAspectosArray(aspectos)
    }
  }, [aspectos])
  const handleChange = e => {
    const checkedA = checked
    if (e.target.checked) {
      if (e.target.value === 'Eficiencia operativa') checkedA[0] = true
      else if (e.target.value === 'Cumplimiento') checkedA[1] = true
      else if (e.target.value === 'Impacto directo en cliente')
        checkedA[2] = true
      setChecked(checkedA)
      aspectosArray.push(e.target.value)
    } else {
      if (e.target.value === 'Eficiencia operativa') checkedA[0] = false
      else if (e.target.value === 'Cumplimiento') checkedA[1] = false
      else if (e.target.value === 'Impacto directo en cliente')
        checkedA[2] = false
      setChecked(checkedA)
      const i = aspectosArray.indexOf(e.target.value)
      aspectosArray.splice(i, 1)
    }
    vectorDispatch({
      type: 'set',
      newFields: { aspectos: aspectosArray },
    })
  }
  const { dispatch: userListDispatch, userlist } = useContext(UserlistContext)
  const handleDelete = (user: User) => {
    userListDispatch({ type: 'remove', user })
  }
  const classes = useStyles()
  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <VectorFormComponent
            value={responsable}
            onChange={e =>
              vectorDispatch({
                type: 'set',
                newFields: { responsable: e.target.value },
              })
            }
            label={_i('Responsables')}
            placeholder={_i('Responsables')}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '3px', paddingRight: 9 }}>
          <VectorFormComponent
            value={departamento}
            onChange={e => {
              e.preventDefault()
              vectorDispatch({
                type: 'set',
                newFields: { departamento: e.target.value },
              })
            }}
            label={_i('Departamento')}
            placeholder={_i('Departamento')}
            array={Departamentos}
            type="select"
          />
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: '3px', paddingRight: 9 }}>
          <VectorFormComponent
            value={area}
            onChange={e =>
              vectorDispatch({
                type: 'set',
                newFields: { area: e.target.value },
              })
            }
            label={_i('Área')}
            placeholder={_i('Área')}
            type="text"
          />
        </Grid>
      </Grid>
      {/* Pregunta de Ejecución del proceso */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="subtitle1" className={classes.text}>
            {_i('Ejecución del proceso')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={manual}
                  onChange={e => {
                    e.preventDefault()
                    vectorDispatch({
                      type: 'set',
                      newFields: { manual: e.target.value },
                    })
                  }}
                  label={_i('¿Cómo de manual y repetitivo es el proceso?')}
                  placeholder={_i(
                    '¿Cómo de manual y repetitivo es el proceso?'
                  )}
                  array={Manual}
                  type="select"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={frecuencia}
                  onChange={e => {
                    e.preventDefault()
                    vectorDispatch({
                      type: 'set',
                      newFields: { frecuencia: e.target.value },
                    })
                  }}
                  label={_i('¿Con qué frecuencia se ejecuta el proceso?')}
                  placeholder={_i('¿Con qué frecuencia se ejecuta el proceso?')}
                  array={Frecuancia}
                  type="select"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={volumetrias}
                  onChange={e =>
                    vectorDispatch({
                      type: 'set',
                      newFields: { volumetrias: e.target.value },
                    })
                  }
                  label={_i('¿Cuál es el nº de ejecuciones del proceso?')}
                  placeholder={_i('¿Cuál es el no de ejecuciones del proceso?')}
                  type="number"
                  endAdornment={
                    <Select
                      value={volumetriasUM}
                      onChange={e => {
                        e.preventDefault()
                        vectorDispatch({
                          type: 'set',
                          newFields: { volumetriasUM: e.target.value },
                        })
                      }}>
                      {VolumetriasUM.map((option, key) => (
                        <MenuItem key={key} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={duracion}
                  onChange={e =>
                    vectorDispatch({
                      type: 'set',
                      newFields: { duracion: e.target.value },
                    })
                  }
                  label={_i('¿Cuánto dura cada ejecución del proceso?')}
                  placeholder={_i('¿Cuánto dura cada ejecución del proceso?')}
                  type="number"
                  endAdornment={
                    <Select
                      value={duracionUM}
                      onChange={e => {
                        e.preventDefault()
                        vectorDispatch({
                          type: 'set',
                          newFields: { duracionUM: e.target.value },
                        })
                      }}>
                      {DuracionUM.map((option, key) => (
                        <MenuItem key={key} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <VectorFormComponent
                value={picosOperativos}
                onChange={e =>
                  vectorDispatch({
                    type: 'set',
                    newFields: { picosOperativos: !picosOperativos },
                  })
                }
                label={_i('¿Existen picos en la operativa?')}
                placeholder={['Sí', 'No']}
                type="switch"
              />
              <Collapse in={picosOperativos}>
                <VectorFormComponent
                  value={informacionPicos}
                  onChange={e =>
                    vectorDispatch({
                      type: 'set',
                      newFields: { informacionPicos: e.target.value },
                    })
                  }
                  label={_i('Aporte información sobre estos picos')}
                  placeholder={_i('Aporte información sobre estos picos')}
                  type="text"
                />
              </Collapse>

              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.textSelect}>
                  {_i('¿A cuál de estos aspectos afecta el proceso?')}
                </FormLabel>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    checked={checked[0]}
                    value="Eficiencia operativa"
                    control={
                      <Checkbox color="primary" onChange={handleChange} />
                    }
                    label="Eficiencia operativa"
                  />
                  <FormControlLabel
                    checked={checked[1]}
                    value="Cumplimiento"
                    control={
                      <Checkbox color="primary" onChange={handleChange} />
                    }
                    label="Cumplimiento"
                  />
                  <FormControlLabel
                    checked={checked[2]}
                    value="Impacto directo en cliente"
                    control={
                      <Checkbox color="primary" onChange={handleChange} />
                    }
                    label="Impacto directo en cliente"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Pregunta sobre Gestión de datos' */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header">
          <Typography variant="subtitle1" className={classes.text}>
            {_i('Gestión de datos')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={consistencia}
                  onChange={e => {
                    e.preventDefault()
                    vectorDispatch({
                      type: 'set',
                      newFields: { consistencia: e.target.value },
                    })
                  }}
                  label={_i(
                    'Los datos de entrada, ¿se obtienen siempre del mismo origen y de la misma manera?'
                  )}
                  placeholder={_i(
                    'Los datos de entrada, ¿se obtienen siempre del mismo origen y de la misma manera?'
                  )}
                  array={Consistencia}
                  type="select"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={reglas}
                  onChange={e => {
                    e.preventDefault()
                    vectorDispatch({
                      type: 'set',
                      newFields: { reglas: e.target.value },
                    })
                  }}
                  label={_i('¿Contiene reglas claras, sencillas y concisas?')}
                  placeholder={_i(
                    '¿Contiene reglas claras, sencillas y concisas?'
                  )}
                  array={Reglas}
                  type="select"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={datosEstandarizados}
                  onChange={e => {
                    e.preventDefault()
                    vectorDispatch({
                      type: 'set',
                      newFields: { datosEstandarizados: e.target.value },
                    })
                  }}
                  label={_i(
                    'Los datos de entrada, ¿tienen siempre el mismo formato? ¿o es necesario transformarlos según el caso?'
                  )}
                  placeholder={_i(
                    'Los datos de entrada, ¿tienen siempre el mismo formato? ¿o es necesario transformarlos según el caso?'
                  )}
                  array={DatosEstandarizados}
                  type="select"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ padding: '3px', paddingRight: 9 }}>
                <VectorFormComponent
                  value={datosLegibles}
                  onChange={e => {
                    e.preventDefault()
                    vectorDispatch({
                      type: 'set',
                      newFields: { datosLegibles: e.target.value },
                    })
                  }}
                  label={_i(
                    '¿Cuál es la dificultad para leer u obtener los datos?'
                  )}
                  placeholder={_i(
                    '¿Cuál es la dificultad para leer u obtener los datos?'
                  )}
                  array={DatosLegibles}
                  type="select"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <VectorFormComponent
                value={procedimientoHabitual}
                onChange={e => {
                  e.preventDefault()
                  vectorDispatch({
                    type: 'set',
                    newFields: { procedimientoHabitual: e.target.value },
                  })
                }}
                label={_i(
                  '¿Cuántas operaciones siguen el procedimiento habitual?'
                )}
                placeholder={_i(
                  '¿Cuántas operaciones siguen el procedimiento habitual?'
                )}
                array={ProcedimientoHabitual}
                type="select"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Pregunta sobre el Entorno */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header">
          <Typography variant="subtitle1" className={classes.text}>
            {_i('Entorno')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: '3px', paddingRight: 9 }}>
              <VectorFormComponent
                value={cambiosProceso}
                onChange={e => {
                  e.preventDefault()
                  vectorDispatch({
                    type: 'set',
                    newFields: { cambiosProceso: e.target.value },
                  })
                }}
                label={_i(
                  '¿Se prevén cambios en las tareas que se realizan en el proceso?'
                )}
                placeholder={_i(
                  '¿Se prevén cambios en las tareas que se realizan en el proceso?'
                )}
                array={CambiosProceso}
                type="select"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{ padding: '3px', paddingRight: 9 }}>
              <VectorFormComponent
                value={cambioSistema}
                onChange={e => {
                  e.preventDefault()
                  vectorDispatch({
                    type: 'set',
                    newFields: { cambioSistema: e.target.value },
                  })
                }}
                label={_i(
                  '¿Se prevén cambios en las aplicaciones o documentos que se utilizan en el proceso?'
                )}
                placeholder={_i(
                  '¿Se prevén cambios en las aplicaciones o documentos que se utilizan en el proceso?'
                )}
                array={CambioSistema}
                type="select"
              />
            </Grid>
            <Grid item xs={12}>
              <VectorFormComponent
                value={NoAplicaciones}
                onChange={e =>
                  vectorDispatch({
                    type: 'set',
                    newFields: { NoAplicaciones: e.target.value },
                  })
                }
                label={_i(
                  '¿Nº de aplicaciones / transacciones intervinientes en el proceso?'
                )}
                placeholder={_i(
                  '¿Nº de aplicaciones / transacciones intervinientes en el proceso?'
                )}
                type="number"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

/**
 * Component to change input or switches depending on the incoming variable
 * the type string changes between text and switch
 */
interface VectorFormComponentProps {
  value: any
  onChange: (e: any) => any
  label: string
  type: 'number' | 'switch' | 'text' | 'select'
  placeholder: string
  helperText: string
  array: []
}
const VectorFormComponent: React.FC<VectorFormComponentProps & any> = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  array,
  ...props
}) => {
  const classes = useStylesSubComponent()
  if (type === 'number') {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <InputField
          value={value}
          onChange={e => {
            // Only Answer with numbers
            if (/^\d*$/.test(e.target.value)) {
              onChange(e)
            }
          }}
          type="text"
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            maxWidth: 408,
          }}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else if (type === 'text') {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <InputField
          value={value}
          onChange={e => {
            onChange(e)
          }}
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            maxWidth: 408,
          }}
          placeholder={placeholder}
          {...props}
        />
      </>
    )
  } else if (type === 'select') {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <Select
          {...props}
          value={value}
          onChange={e => {
            onChange(e)
          }}
          style={{
            borderRadius: 12,
            padding: '5px 12px',
            height: 39,
            maxWidth: 408,
          }}
          placeholder={placeholder}
          native
          input={
            <InputBase
              placeholder={placeholder}
              className={classes.selectInput}
            />
          }>
          {array.map((option, key) => (
            <option
              disabled={option === '' ? true : false}
              key={key}
              value={option}>
              {option}
            </option>
          ))}
        </Select>
      </>
    )
  } else {
    return (
      <>
        <Typography variant="subtitle1" className={classes.text}>
          {label}
        </Typography>
        <div style={{ paddingLeft: 10 }}>
          <Grid container alignItems="center">
            <Button
              onClick={onChange}
              variant={!value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.firstOptButton}>
              {placeholder[1]}
            </Button>
            <Button
              onClick={onChange}
              variant={value ? 'contained' : 'outlined'}
              color="primary"
              className={classes.secondOptButton}>
              {placeholder[0]}
            </Button>
          </Grid>
        </div>
      </>
    )
  }
}

export default VectorQuestions
