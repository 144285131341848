export default {
  text: {
    borderRadius: '18px',
    fontFamily: 'InnoArial',
    boxShadow: 'none',
  },
  outlined: {
    borderRadius: '18px',
    fontFamily: 'InnoArial',
    boxShadow: 'none',
  },
  contained: {
    borderRadius: '18px',
    fontFamily: 'InnoArial',
  },
}
