import * as React from 'react'
import { Chip } from '@material-ui/core'
import { useState, useContext, useEffect } from 'react'
import { TagContext } from './TagInputContext'
import InputBase from '@material-ui/core/InputBase'
import { _i } from '../../../../Utils/lang/i18n'

interface Props {
  initState: any[]
}
const TagInput: React.FC<Props> = ({ initState }) => {
  const { tagList, dispatch } = useContext(TagContext)
  useEffect(() => {
    if (initState) {
      initState.map(tag => {
        dispatch({
          type: 'add',
          tag: tag,
        })
      })
    }
  }, [])

  const [tag, setTag] = useState('')
  const [keyDown, setKey] = useState(false)
  let flag = false

  const handleSubmit = e => {
    e.preventDefault()
    let tag_temp = tag.trim()
    //check if tag is empty
    if (!tag || tag_temp == '') return
    //check if tag already exists
    let tag_found = false
    tagList.map(tag => {
      if (tag.value.toLowerCase() === tag_temp.toLowerCase()) {
        tag_found = true
        return
      }
    })
    if (tag_found) return
    //I hate this but it was the only way tbh
    //
    // _setTagList([...tagListProp, tag_temp])
    // // setTagListProp([...tagListProp, tag_temp])
    // setTagList([...tagList, tag_temp])
    // remove commas
    dispatch({ type: 'add', tag: tag_temp.replace(',', '') })
    setTag('')
  }
  const handleChange = e => {
    if (!keyDown) {
      e.preventDefault()
      setTag(e.target.value)
    }
  }

  const handleDelete = (i: any) => {
    dispatch({ type: 'remove', id: i })
    // tagListProp.splice(i, 1)
    // setTagList([...tagList])
    // _setTagList([...tagListProp])
  }

  return (
    <div>
      <div
        className="tag-container"
        style={{
          background: '#ecedf1',
          color: '#727272',
          borderRadius: '12px',
          padding: '3px 12px',
          fontSize: '14px',
          // width: '401px',
          width: '100%',
          marginBottom: '4px',
        }}>
        {tagList &&
          tagList.map((tag_item, i) => (
            <Chip
              style={{ margin: '1px', fontSize: '14px', lineHeight: '16px' }}
              key={i}
              label={tag_item.value}
              onDelete={() => {
                handleDelete(i)
              }}
              color="primary"
            />
          ))}
        <InputBase
          style={{ margin: '3px', fontSize: '14px', padding: '3px 0px' }}
          placeholder={_i('Ingresa Etiquetas...')}
          onChange={handleChange}
          id="input"
          onBlur={e => {
            if (flag) {
              var x = document.getElementById('input')
              x.focus()
            }
          }}
          onKeyDown={e => {
            const regex = /[a-zA-Z]/g
            const found = e.key.match(regex)
            if (found !== null)
              if (found.length === 1) {
                const newString = tag + e.key
                setTag(newString)
                setKey(true)
                flag = true
              }
          }}
          onKeyUp={e => {
            if (e.key === 'Enter' || e.key === ',' || e.key === ':') {
              e.preventDefault()
              handleSubmit(e)
            }
            flag = false
            setKey(false)
          }}
          value={tag}
        />
      </div>
      <div
        style={{
          color: '#727272',
          fontSize: '11px',
          fontFamily: 'Roboto',
          marginLeft: '6px',
          marginBottom: '10px',
        }}>
        {_i("Separa tus etiquetas usando coma o 'Enter'")}
      </div>
    </div>
  )
}

export default TagInput
