import { HttpLink, InMemoryCache, ApolloClient } from 'apollo-boost'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('idToken')
  const accessToken = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
      accessToken: accessToken ? accessToken : '',
    },
  }
})

const resolvers = {
  Query: {
    // do nothing
  },
  Mutation: {
    setMe: (_, { user }, { cache }) => {
      cache.writeData({
        data: {
          user: {
            ...user,
          },
        },
      })
      return null
    },
    setLoggedIn: (_, { state }, { cache }) => {
      cache.writeData({
        data: {
          loggedIn: state,
        },
      })
      return null
    },
    setAuthState: (_, { state }, { cache }) => {
      cache.writeData({
        data: {
          authState: state,
        },
      })
      return null
    },
  },
}

const cache = new InMemoryCache().restore({
  // do nothing
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(graphQLErrors)
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions }) => {
      if (extensions.code === '418') {
        cache.writeData({
          data: { authState: 418 },
        })
      } else if (extensions.code === '400') {
        cache.writeData({
          data: { authState: 400 },
        })
      } else if (extensions.code === '401') {
        cache.writeData({
          data: { authState: 401 },
        })
      } else if (extensions.code === '402') {
        cache.writeData({
          data: { authState: 402 },
        })
      } else {
        // do nothing
      }
      // eslint-disable-next-line
      return
    })
  } else {
    if (networkError) {
      cache.writeData({
        data: { authState: 500 },
      })
    }
    return
  }
})

const _link = ApolloLink.from([authLink, errorLink])

const link = new HttpLink({
  // eslint-disable-next-line
  uri: process.env.REACT_APP_GRAPHQL,
})

const client = new ApolloClient({
  link: _link.concat(link),
  resolvers: resolvers,

  cache: cache,
})

cache.writeData({
  data: {
    authState: 100,
    loggedIn: false,
    sasLocal: {
      __typename: 'SAS',
      token: '',
      expiresBy: 0,
    },
  },
})

export default client
