//Cover List Context Declaration
import React, { useReducer } from 'react'
import { AbancaFields } from '../../../../generated/graphql'

const initState = {
  abancaFields: {
    periodicidad: 'semanal',
    tiempoDedicado: false,
    volumenEstimado: null,
    tiempoTarea: null,
    tiempoTareaVolumen: null,
    riesgoOperacion: false,
    ahorroCostes: false,
    fechaMax: null,
    peticionCER: false,
  } as AbancaFields,
  dispatch: null,
}

//create reducer for state management
const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        // merge both objects using rest operator
        abancaFields: {
          ...state.abancaFields,
          ...action.newFields,
        },
      }
    //default, do nothing
    default:
      return { ...state }
  }
}

//create context with initial conditions
const ABANCAQuestionsContext = React.createContext(initState)
//Provider Init
function ABANCAQuestionsProvider({ children }) {
  //use Reducer
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <ABANCAQuestionsContext.Provider
      value={{
        abancaFields: state.abancaFields,
        dispatch,
      }}>
      {/* Provider Wrapper, everything inside will be in further context */}
      {children}
    </ABANCAQuestionsContext.Provider>
  )
}
//Export conext and provider
export { ABANCAQuestionsContext, ABANCAQuestionsProvider }
