import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  text: {
    padding: '4px 0px',
    fontFamily: 'InnoArial',
    fontSize: '14px',
    marginTop: 8,
  },
  typography: {
    color: '#727272',
    marginBottom: '16px',
  },
  selectInput: {
    backgroundColor: '#ECEDF1',
    border: 'none',
    borderRadius: '12px',
    padding: '5px 7px',
    fontSize: '14px',
    color: '#727272',
    width: '100%',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))

export const useStylesSubComponent = makeStyles((theme: Theme) => ({
  text: {
    padding: '4px 0px',
    fontSize: '14px',
    marginTop: 8,
  },
  firstOptButton: {
    borderRadius: ' 10px 0px 0px  10px',
    textTransform: 'capitalize' as 'capitalize',
    boxShadow: 'none',
    margin: 0,
  },
  secondOptButton: {
    borderRadius: ' 0px 10px 10px  0px',
    textTransform: 'capitalize' as 'capitalize',
    boxShadow: 'none',
    margin: 0,
  },
}))
