import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    width: '25px',
    height: '25px',
    borderRadius: '25px',
  },
  fab: {
    backgroundColor: '#ECEDF1',
    padding: '2px',
    width: '25px',
    height: '25px',
    minHeight: '25px',
    float: 'right' as 'right',
    boxShadow: 'none',
  },
  icon: {
    color: '#6C6D6F',
    fontSize: '20px',
    padding: '2px',
  },
  mainContainer: {
    overflowX: 'hidden' as 'hidden',
  },
  button: {
    margin: theme.spacing(1),
    color: 'white',
    float: 'right' as 'right',
    backgroundImage:
      'linear-gradient(68deg,#2D93F8 0%, #309BF9 10%, #27D1E5 35%, #12E596 54%, #06F70E 80%, #ECFC05 100%)',
  },
  HEB_custom_button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage: 'linear-gradient(68deg,#dc291e 0%, #ea6d65 100%)',
  },
  ABANCA_custom_button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage:
      'linear-gradient(90deg, rgba(8,133,201,1) 0%, rgba(4,170,203,1) 85%)',
  },
  caixaBank_custom_button: {
    margin: theme.spacing(1),
    color: 'white',
    backgroundImage: 'linear-gradient(68deg,#CFC6B4 0%, #CFC6B4 100%)',
  },
  formBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      width: '840px',
    },
    justifyContent: 'space-between',
  },
  mobileSideGrid: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  buttonCustom: {
    margin: theme.spacing(1),
    float: 'right' as 'right',
  },
}))
