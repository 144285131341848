import jwtDecode from 'jwt-decode'
import React from 'react'
import Authentication from '../Authentication'
import { useMutation } from 'react-apollo-hooks'
import gql from 'graphql-tag'
import useRouter from 'use-react-router'
import { O_APPEND } from 'constants'

export type AuthState = 'loading' | 'not_logged' | 'logged' | 'initial'

const mut = gql`
  mutation SetLoggedIn($state: Boolean) {
    setLoggedIn(state: $state) @client
  }
`
const _auth = new Authentication()

function useMSAuth() {
  const mutation = useMutation(mut)
  const [state, setState] = React.useState<AuthState>('initial')
  const { history } = useRouter()

  React.useEffect(() => {
    const loginFlow = async () => {
      const idToken =
        process.env.REACT_APP_SERVICE === 'caixabank'
          ? _auth.getIdTokenCaixa()
          : _auth.getIdToken()
      let status
      let exp
      console.log('Caixa status')
      try {
        console.log('idToken', idToken.substring(0, 10))
        const decodedToken = jwtDecode(idToken)
        console.log('MAIL: ', decodedToken.preferred_username)
        console.log('ISS: ', decodedToken.iss)

        // const accesstoken = await _auth.acquireUserTokenSilent()
        // console.log(accesstoken)
        exp = new Date(decodedToken.exp * 1000)
        const now = new Date()
        status = exp.getTime() - now.getTime() > 0

        // const email = decodedToken.preferred_username as string
        // // @ts-ignore
        // const domain = process.env.REACT_APP_ALLOWED_DOMAIN // eslint-disable-line
        // if (!email.includes(domain)) {
        //   // Not secure
        //   history.push('/not-allowed')
        // }
      } catch (e) {
        // console.log('id token crashed')
        console.log('error:', e)
        console.log('idToken', idToken)
        status = false
      }

      // const login = async () => {
      //   const response = await _auth.login()
      //   if (!response) {
      //     return null
      //   } else {
      //     return 200
      //   }
      // }

      if (!status) {
        setState('not_logged')
        // const s = await login()
        // if (s === 200) {
        //   setState('logged')
        //   mutation({ variables: { state: true } })
        // }
        //history.push('/login')
      } else {
        localStorage.setItem('idToken', idToken)
        localStorage.setItem('login', 'microsoft')
        setState('logged')
        //_auth.setAccessToken()
        mutation({ variables: { state: true } })
      }
    }
    loginFlow()
  }, [mutation])

  return state
}

export default useMSAuth
